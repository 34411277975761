import React, { useEffect, useState } from 'react';
import './errorMessage.scss';
import { Link } from 'react-router-dom';
import I18n from '../../../i18n';

const ErrorMessage = (props) => {

    const [message, setMessage] = useState('');

    useEffect(()=> {

        switch(props.code) {

            case 'error-login':
                setMessage(<React.Fragment><p>Credenciales incorrectas. Vuelve a intentarlo o <Link to="/auth/recovery/step/1">restablece tu contraseña.</Link></p></React.Fragment>)
                break;
            case 'error-recovery':
                setMessage(<React.Fragment><p>Ha ocurrido un error, inténtelo de nuevo y si el problema persiste contacte con soporte.</p></React.Fragment>)
                break;
            case 'register-repeat-email':
                setMessage(<React.Fragment><p>El email ya está en uso.</p></React.Fragment>)
                break;
            case 409:
                setMessage(<p>{I18n.t(`status.code.${props.code}`)}</p>)
                break;
            default:
                setMessage('')

        }

    },[props.code]);

    return (
        <React.Fragment>
            {props.active && <div className="error-message-box verticalPad">
                {message}
            </div>}
        </React.Fragment>
    );

}

export default ErrorMessage;