import _ from 'lodash';

import React from 'react';

import { Container, Image, Segment, Statistic } from 'semantic-ui-react';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import StatsModel from '../../../../../../data/models/stats/stats';

import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import I18n from '../../../../../../i18n';

class TeachableReducedDiagnosisConversionFunnel extends React.Component {

    constructor(props) {

        super(props);

        this.state = {

            stats: {}

        };

    }

    componentDidMount() {

        this.updateStats();

    }

    updateStats = (startDate, endDate) => {

        let startDateString = startDate ? startDate.getDate() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getFullYear() : '';
        let endDateString = endDate ? endDate.getDate() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getFullYear() : '';

        StatsModel.readReducedDiagnosis(startDateString, endDateString).then(result => {

            this.setState({ stats: result.data });

            am4core.useTheme(am4themes_animated);
            let chart = am4core.create("chartdiv", am4charts.XYChart3D);
            chart.hiddenState.properties.opacity = 0;

            chart.data = [{
                name: "Flexoextensiones",
                value: result.data.usersEndFlexoExtensionCourse,
                color: chart.colors.next()
            }, {
                name: "Lateralizaciones",
                value: result.data.usersEndLateralizationCourse,
                color: chart.colors.next()
            }, {
                name: "Trenes musculares",
                value: result.data.usersEndMuscleTrainCourse,
                color: chart.colors.next()
            }, {
                name: "Rutina final",
                value: result.data.usersReducedCourseEnd,
                color: chart.colors.next()
            }];
        
            let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "name";
            categoryAxis.renderer.inversed = true;

            let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
            valueAxis.title.text = "Analizados";

            let series = chart.series.push(new am4charts.ColumnSeries3D());
            series.name = "Autodiagnósticos";
            series.columns.template.tooltipText = "{valueX} {name} analizados";
            series.columns.template.propertyFields.fill = "color";
            series.dataFields.valueX = "value";
            series.dataFields.categoryY = "name";
            series.columns.template.column3D.stroke = am4core.color("#fff");
            series.columns.template.column3D.strokeOpacity = 0.2;

        });

    }



    onChangeDateRange = (event, data) => {

        this.updateStats(_.get(data, 'value[0]'), _.get(data, 'value[1]'));

    }

    render() {

        return (
            <Segment className="statSegment" raised>
                <Container fluid>
                    <SemanticDatepicker locale="es-ES" onChange={ this.onChangeDateRange } type="range" clearable format="DD/MM/YYYY" datePickerOnly />
                </Container>
                <Statistic>
                    <Statistic.Value>
                        <Image className='statistic-logo' src='assets/img/logo_mobile.svg' inline />&nbsp;
                        { this.state.stats.usersRegister }
                    </Statistic.Value>
                    <Statistic.Label>{I18n.t('stats.studentsRegistered')}</Statistic.Label>
                </Statistic>
                <div id="chartdiv"></div>
            </Segment>
        );

    }

}

export default TeachableReducedDiagnosisConversionFunnel;