import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form } from 'semantic-ui-react';
import Logo from '../../../assets/img/logo_secondary.svg';
import LogoMobile from '../../../assets/img/logo_mobile.svg';
import ErrorMessage from '../../../components/errorMessage';
import CustomButton from '../../../components/form/button';
import CustomInput from '../../../components/form/input';
import { loginUser } from '../../../../redux-store/auth';
import { closeLoader, openLoader } from '../../../../redux-store/loader';
import './login.scss';

import I18n from '../../../../i18n';

class Login extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            hasError: false,
            codeError: -1
        };

    }

    onLogin = async credentials => {

        try {

            this.props.openLoader()
            await this.props.loginUser({ email: credentials.email, password: credentials.password });

            this.props.history.push('/home/notifications');

        } catch (codeError) {

            this.setState({
                hasError: true,
                codeError: 'error-login'
            });

        } finally {

            this.props.closeLoader()
        }

    };

    render() {

        return (

            <div className="full-wrapper">
                <div className="wrapper-login">
                    <div className="box">
                        <div className="logo" style={{paddingBottom: '40px'}}>
                            <img src={Logo} alt="logo" />
                        </div>
                        <div className="logo-mobile" style={{paddingBottom: '40px'}}>
                            <img src={LogoMobile} alt="logo" />
                        </div>
                        <div className="rrss-title">
                            <p></p>
                        </div>
                        <div className="rrss-subtitle">
                            <p></p>
                        </div>
                        <Form name="loginForm" onSubmit={this.props.handleSubmit(this.onLogin)} noValidate>
                            <ErrorMessage code={this.state.codeError} active={this.state.hasError} />
                            <div className="verticalPad">
                                <Field
                                    component={ CustomInput }
                                    placeholder={ I18n.t('auth.emailPlaceHolder') }
                                    name="email"
                                    fieldClasses="y-input default"
                                    label={ I18n.t('auth.email') } />
                            </div>
                            <div className="topPad forgot-password">
                                <Field
                                    component={ CustomInput }
                                    placeholder={ "" }
                                    name="password"
                                    type="password"
                                    fieldClasses="y-input default"
                                    label={ I18n.t('auth.password') } />
                            </div>
                            <div className="verticalPad descriptions second flex-center-column">
                                <CustomButton type="submit" classes="default upper" text={ I18n.t('auth.login') } ></CustomButton>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>

        );

    }

}

const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.email)) {

        errors.email = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.password)) {

        errors.password = I18n.t('validations.required');

    }

    if (!_.isEmpty(formValues.email) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {

        errors.email = I18n.t('validations.emailInvalid');

    }

    return errors;

};

export default reduxForm({
    form: 'loginForm',
    touchOnBlur: true,
    touchOnChange: false,
    validate,
    initialValues: {
        email: '',
        password: '',
    }
})(connect(null, { loginUser, closeLoader, openLoader })(Login));