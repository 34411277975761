import React from 'react';
import { Radio, Form } from 'semantic-ui-react';
import _ from 'lodash';
import I18n from '../../../../i18n';

class InputRadio extends React.Component {
            
    onChange(value) {
        
        let index = _.findIndex(this.props.options, option => option.value === value);

        let result = this.props.options[index].value;

        this.props.input.onChange(result);

    }

    render() {

        return (
            this.props.options.map((option, index) => {

                return (
                    <Form.Field className={ this.props.fieldClasses } key={ index }>
                        <div className="inline field">
                            <Radio
                                label={ I18n.t(`practice.${option.value}`)}
                                value={ option.value }
                                onClick={ (e, v) => { this.onChange(option.value) } }
                                checked={ this.props.input.value.indexOf(option.value) > -1 }
                            />
                        </div>
                    </Form.Field>
                );

            })
        );

    }

};

export default InputRadio;