import React from 'react';
import _ from 'lodash';
import { Checkbox, Form, Label } from 'semantic-ui-react';

class CheckboxList extends React.Component {

    onChange(value, checked) {

        let result = [ ...this.props.input.value ];

        if (!checked) {

            result.splice(result.indexOf(value), 1);

        } else {

            result.push(value);

        }

        this.props.input.onChange(result);

    }

    render() {

        return (

            <React.Fragment>
                { (this.props.options || []).map( (option, index) => (
                    <Form.Field className={ this.props.fieldClasses } key={ index }>
                        <div className="inline field">
                            <Checkbox
                                label={ option.label }
                                value={ option._id }
                                onClick={ (e, v) => { this.onChange(option._id, v.checked) } }
                                checked={ _.findIndex(this.props.input.value, item => item === option._id ) > -1 }
                                disabled={ option.disabled ||  false }
                            />
                        </div>
                    </Form.Field>
                ))}
                {this.props.meta.touched && this.props.meta.invalid && <Label basic color={ this.props.colorLabel || 'red' } pointing>{ this.props.meta.error }</Label>}
            </React.Fragment>
        );

    }

};

export default CheckboxList;