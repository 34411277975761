// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form, Modal} from 'semantic-ui-react';

// Components
import CustomButton from '../form/button';
import CustomInput from '../form/input';
import CustomDatePicker from '../form/datepicker';

// Redux
import { closeDiscountModal } from '../../../redux-store/discountModal';

// Config
import { required } from '../../../config/validations';

// Locales
import I18n from '../../../i18n';

// Styles
import './modal.scss';

class DiscountStudioModal extends React.Component {

    onSubmit = data => this.props.message.submitCallback(data);

    render() {

        const { open, message, closeDiscountModal } = this.props;

        return open && (
            <Modal open={ open } className="discount-modal" size="tiny">
                <Modal.Header style={{ color: '#fff' }}>{ message.headerTitle || '' }</Modal.Header>
                <Form noValidate onSubmit={ this.props.handleSubmit(this.onSubmit) }>
                    <Modal.Content>
                        <Form.Field>
                            <Field
                                component={ CustomInput }
                                placeholder={ I18n.t('practice.discount') }
                                name="discount"
                                label={ I18n.t('practice.discount') }
                                validate={ [required] }
                                restrictions={ [{ numeric: true, canBeFloat: false }] }
                            />
                        </Form.Field>
                        <Form.Field>
                            <Field
                                component={ CustomDatePicker }
                                name="discountExpiration"
                                label={ I18n.t('practice.expirationDiscount') }
                                formatDate={'DD/MM/YYYY'}
                            />
                        </Form.Field>
                    </Modal.Content>
                    <div className="actions-btns">
                        <CustomButton classes="default small no-shadow upper invert" text={ I18n.t('actions.cancel') } type="button" onClick={ closeDiscountModal }/>
                        <CustomButton classes="default small no-shadow upper invert" type="submit" text={ I18n.t('actions.accept') } />
                    </div>
                </Form>
            </Modal>
        );

    }

}

const mapStateToProps = state => _.cloneDeep(state.discountStudioModal);

export default reduxForm({
    form: 'discountStudioModalForm',
    touchOnBlur: true,
    touchOnChange: true,
    enableReinitialize: true,
    initialValues: {
        discount: '',
        discountExpiration: ''
    }
})(connect(mapStateToProps, { closeDiscountModal })(DiscountStudioModal));