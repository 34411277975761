import _ from 'lodash';
import React, { Component } from 'react'
import { Checkbox } from 'semantic-ui-react';

class DefaultCheckBox extends Component {

    render() {

        return (
            <Checkbox
                className={ _.get(this.props, 'classes', '') } 
                label={ this.props.label }
                toggle={this.props.toggle || false}
                checked={ _.get(this.props,'input.value', false) }
                onChange={ (e, v) => { this.props.input.onChange(v.checked) } }
        />
        )
    }
}

export default DefaultCheckBox