// Third party libraries
import _ from 'lodash';
import { connect } from 'react-redux';
import React from 'react';
import { Segment, Icon, Header, Button, Table, Select, Input, Form } from 'semantic-ui-react';

// Models
import SessionModel from "../../../../../data/models/sessions";

// Redux
import { openLoader, closeLoader } from "../../../../../redux-store/loader";
import { openModal , closeModal } from '../../../../../redux-store/modal';
import { getSequences } from '../../../../../redux-store/sequence';

// History
import History from '../../../../../history';

// Locales
import I18n from '../../../../../i18n';

// Styles
import '../../sequence.scss';

class SequenceList extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            column: null,
            direction: null,
            categories: [],
            categoryOptions: [],
            filterCategory: 'all',
            filterTags: '',
            practiceLevel: [{
                key: 1,
                text: "Principiante",
                value: '01'
            }, {
                key: 2,
                text: "Avanzado",
                value: '00'
            }, {
                key: 3,
                text: "Con soporte",
                value: 'CS'
            }],
            practices: []
        }

    }

    componentDidMount() {

        this.onGetData();

    }

    componentDidUpdate() {

        if (this.props.sequences && !_.isEqual(this.props.sequences, this.state.practices)) {

            this.setState({ practices: this.props.sequences });

        }

    }

    onFilterByCategory = filterCategory => this.setState({ filterCategory });

    onFilterByTags = filterTags => this.setState({ filterTags });

    onGetData = async () => {

        try {

            this.props.openLoader();

            const categories = await SessionModel.getSequenceCategories();
            this.props.getSequences();

            const categoryOptions = [{
                key: 1,
                text: 'TODAS',
                value: 'all'
            }];

            _.each(categories.data, ({ _id, label }) => categoryOptions.push({
                key: _id,
                text: label,
                value: _id
            }));

            this.setState({ categories: categories.data, categoryOptions });

		} catch (error) {

            console.error("Error onGetCategories", error);

		} finally {

            this.props.closeLoader();

		}

    }

    handleSort = (clickedColumn) => () => {

        const { column, practices, direction } = this.state

        if (column !== clickedColumn) {

            this.setState({
                column: clickedColumn,
                practices: _.sortBy(practices, [clickedColumn]),
                direction: 'ascending'
            });

        } else {

            this.setState({
                practices: practices.reverse(),
                direction: direction === 'ascending' ? 'descending' : 'ascending'
            });

        }

    }

    onAddPractice = () => History.push('/home/sequence/new');

    onEditPractice = practice => History.push(`/home/sequence/${ practice._id }`);

    onPublish = async practice => {

        this.props.openLoader();

        try {

            await SessionModel.publishSequence(practice._id);
            await this.props.getSequences();

        } catch (error) {

            console.error('Error:', error);

        } finally {

            this.props.closeLoader();

        }

    }

    onRemovePractice = practiceId => {

        const message = {
            title: I18n.t('practice.removePractice'),
            buttons: [{
                text: I18n.t('actions.accept'),
                callback: async () => {

                    this.removePractice(practiceId);
                    this.props.closeModal();

                }
            }, {
                text: I18n.t('actions.cancel'),
                callback: this.props.closeModal
            }]
        };

        this.props.openModal(message);

    }

    removePractice = async practiceId => {

        try {

            this.props.openLoader();
            await SessionModel.deleteSequence(practiceId);

        } catch (error) {

            console.error('Error: ', error);

        } finally {

            this.props.closeLoader();
            this.onGetData();

        }

    }

    highLightPractice = practiceId => {

        const message = {
            title: I18n.t('practice.switchToMain'),
            buttons: [{
                text: I18n.t('actions.accept'),
                callback: () => {

                    this.onHighLightPractice(practiceId);
                    this.props.closeModal();

                }
            }, {
                text: I18n.t('actions.cancel'),
                callback: this.props.closeModal
            }]
        };

        this.props.openModal(message);

    }

    onHighLightPractice = async practiceId =>  {

        try {

            this.props.openLoader();
            await SessionModel.setMainSequence(practiceId);

        } catch (error) {

            console.error('Error', error);

        } finally {

            this.props.closeLoader();
            this.onGetData();

        }

    }

    onClonePractice = practiceId => {

        const message = {
            title: I18n.t('practice.alternateSequence'),
            buttons: [{
                text: I18n.t('actions.accept'),
                callback: async () => {

                    this.clonePractice(practiceId);
                    this.props.closeModal()

                }
            }, {
                text: I18n.t('actions.cancel'),
                callback: this.props.closeModal
            }]
        };

        this.props.openModal(message);

    }

    clonePractice = async practiceId => {

        try {

            this.props.openLoader();
            await SessionModel.cloneSequence(practiceId);

        } catch (error) {

            console.error('Error', error);

        } finally {

            this.props.closeLoader();
            this.onGetData();

        }

    }

    filterByCategoryOrTags = () => {

        const { filterCategory, filterTags, practices } = this.state;
        let filteredPractices = _.cloneDeep(practices);
        if (filterCategory !== 'all') {

            filteredPractices = _.filter(practices, item => item.categories.indexOf(filterCategory)>-1);

        }

        if (filterTags !== '') {

            filteredPractices = _.filter(practices, item => item.tags.indexOf(filterTags)>-1 || item.title.ES.toLowerCase().indexOf(filterTags.toLowerCase())>-1 );

        }

        return filteredPractices;

    }

    renderPracticeList = () => {

        const { column, direction } = this.state;

        return (
            <Table celled selectable sortable >
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell collapsing>{ I18n.t('practice.thumb') }</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('title.ES') } sorted={ column === 'title.ES' ? direction : null }>{ I18n.t('practice.title') }</Table.HeaderCell>
                        <Table.HeaderCell collapsing>{ I18n.t('practice.level') }</Table.HeaderCell>
                        <Table.HeaderCell collapsing>{ I18n.t('practice.duration') }</Table.HeaderCell>
                        <Table.HeaderCell>{ I18n.t('actions.clone') }</Table.HeaderCell>
                        <Table.HeaderCell>{ I18n.t('practice.highlighted') }</Table.HeaderCell>
                        <Table.HeaderCell>{ I18n.t('practice.publish') }</Table.HeaderCell>
                        <Table.HeaderCell>{ I18n.t('actions.edit') }</Table.HeaderCell>
                        <Table.HeaderCell>{ I18n.t('actions.remove') }</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    { (this.filterByCategoryOrTags() || []).map(practice => (
                        <Table.Row key={practice._id}>
                             <Table.Cell collapsing>
                                <img src={practice.thumbUrl} alt="" style={{width: '150px'}}/>
                            </Table.Cell>
                            <Table.Cell>{practice.title['ES']}</Table.Cell>
                            <Table.Cell>{ I18n.t(`practice.#${practice.level}`) }</Table.Cell>
                            <Table.Cell>{ I18n.t(`practice.#${practice.duration}`) }</Table.Cell>
                            <Table.Cell textAlign='center'><Icon className="pointer" name="clone" size="large" onClick={ () => this.onClonePractice(practice._id) }></Icon></Table.Cell>
                            <Table.Cell textAlign='center'><Icon className={ "pointer " + (practice.main ? 'highlight' : '') } name="star" size="large" onClick={ () => this.highLightPractice(practice._id)}></Icon></Table.Cell>
                            <Table.Cell textAlign='center'><Icon className="pointer" name={ practice.published ? 'eye' : 'eye slash' } size="large" onClick={ () => this.onPublish(practice) }></Icon></Table.Cell>
                            <Table.Cell textAlign='center'><Icon className="pointer" name="edit" size="large" onClick={ () => this.onEditPractice(practice) }></Icon></Table.Cell>
                            <Table.Cell textAlign='center'><Icon className="pointer" name="eraser" size="large" onClick={ () => this.onRemovePractice(practice._id) }></Icon></Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
                <Table.Footer fullWidth>
                    <Table.Row>
                        <Table.HeaderCell colSpan='9'>
                            <Button type='button' floated='right' primary onClick={this.onAddPractice}>{I18n.t('practice.addAlternativePractice')}</Button>
                            <div style={{ clear: "both" }}></div>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        );

    }

    render() {

        const { categoryOptions, filterCategory, filterTags } = this.state;

        return (
            <Segment>
                <Header as='h3'>{ I18n.t('practice.practiceList') }</Header>
                <Form>
                    <Form.Group inline>
                        <Form.Field>
                            <label>Filtrar por categoría</label>
                            <Select value={ filterCategory } onChange={ (e, { value }) => this.onFilterByCategory(value) } options={ categoryOptions } />
                        </Form.Field>
                        <Form.Field>
                            <label>Filtrar por nombre o tag</label>
                            <Input value={ filterTags } onChange={ (e, { value }) => this.onFilterByTags(value) } />
                        </Form.Field>
                    </Form.Group>
                </Form>
                { this.renderPracticeList() }
            </Segment>
        );

    }

}

const mapStateToProps = state => _.cloneDeep(state.sequence);

export default connect(mapStateToProps, { closeLoader, closeModal, getSequences, openModal, openLoader })(SequenceList);