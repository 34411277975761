import React from 'react';
import { Tab } from 'semantic-ui-react';

import UserActions from './components/actions';
import UserGeneralInfo from './components/general';
import UserPayments from './components/payments';
import UserMatrix from './components/matrix';
import UserDiagnosis from './components/diagnosis';
import UserLicenses from './components/licenses';
import UserSessions from './components/sessions';
import UserAccesss from './components/access';
import UserProducts from './components/products';
import UserRoles from './components/roles';
import Assignments from './components/assignments';

import './info.scss';

const panes = [
    { menuItem: 'General', render: (props) => <UserGeneralInfo {...props} /> },
    { menuItem: 'Accesos', render: (props) => <UserAccesss {...props} /> },
    { menuItem: 'Pagos', render: (props) => <UserPayments {...props} /> },
    { menuItem: 'Mensajes', render: (props) => <UserActions {...props} /> },
    { menuItem: 'Matriz', render: (props) => <UserMatrix {...props} /> },
    { menuItem: 'Diagnóstico', render: (props) => <UserDiagnosis {...props} /> },
    { menuItem: 'Licencias', render: (props) => <UserLicenses {...props} /> },
    { menuItem: 'Sesiones', render: (props) => <UserSessions {...props} /> },
    { menuItem: 'Productos', render: (props) => <UserProducts {...props} /> },
    { menuItem: 'Roles', render: (props) => <UserRoles {...props} /> },
    { menuItem: 'Asignaciones', render: (props) => <Assignments {...props} /> }
    

];

//TRICK Para forzar un render cada vez que haya cambios en el props url userid añado un key al componente tab con el valor del userid
function userInfoTabs(props) {
    
    return (
        <Tab key={props.match.params.userId} history={props.history} userid={props.match.params.userId} menu={{ secondary: true, pointing: true, className: "wrapped-tabs" }} panes={ panes } renderActiveOnly={ true } defaultActiveIndex={ 0 } />
    )
}

export default userInfoTabs;