import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Button, Form, Header, Segment } from 'semantic-ui-react'
import I18n from '../../../../../../../../i18n'
import CheckboxList from '../../../../../../../components/form/checkBoxList'
import DefaultCheckBox from '../../../../../../../components/form/defaultCheckbox'
import SecurityModel from '../../../../../../../../data/models/security/security'
import Select from '../../../../../../../components/form/select'
import _ from 'lodash'

class UserRoleForm extends Component {
   
    onSubmit = async submittedData => {

        let tempSubmittedData = _.cloneDeep(submittedData);

        const { userid, closeModal, openModal, openLoader,  closeLoader } = this.props;

        if (tempSubmittedData.pretorianUserId && tempSubmittedData.pretorianUserId === 'noPretorian') {

            tempSubmittedData.pretorianUserId = "";

        }

        let message = {
            headerTitle : "Error",
            title : I18n.t('messages.modal.commonError'),
            buttons : [
                
                {
                    text : I18n.t('actions.accept'),
                    callback : () => closeModal()
                
                }

            ]

        };

        try {

            openLoader();
            await SecurityModel.updateRoles(userid, tempSubmittedData);
            
        } catch (error) {

            closeLoader();
            openModal(message);
            
        } finally {

            closeLoader();

        }
        
    }

    render() {

        const { options, pretorians, reduxFormState } = this.props;

        return (
            <Segment style={{minHeight: '100vh'}}>
                <Form name="userRoleForm" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <Header as='h3'>{ I18n.t('user.info.roles') }</Header>
                    <Segment>
                        <Field
                            component={ CheckboxList }
                            options={ options }
                            name="role"
                        />
                    </Segment>
                    <Segment>
                        <Form.Group >
                            <Field width={12}
                                component={ DefaultCheckBox }
                                name="advisorVerified"
                                label={ I18n.t('user.info.advisorVerified') }
                            />
                        </Form.Group>
                        { (reduxFormState.role && reduxFormState.role.indexOf('pretorian') === -1) && 

                            <Form.Group>
                                <Field
                                    component={ Select }
                                    options={ pretorians }
                                    name="pretorianUserId"
                                    label={ I18n.t('user.info.addPretorian') }
                                />
                            </Form.Group>
                        }
                    </Segment>
                    <Button floated="right" type='submit' primary>{ I18n.t('actions.save') }</Button>
                    <div style={{ clear: "both" }}></div>
                </Form>
            </Segment>
        )
    }
}

UserRoleForm = connect(state => {

    const reduxFormState = _.cloneDeep(_.get(state, 'form.userRoleForm.values', {}));

    return {
        reduxFormState
    };

})(UserRoleForm);

export default reduxForm({
    form: 'userRoleForm',
    touchOnBlur: true,
    touchOnChange: true,
    enableReinitialize: true
})(connect(null, {})(UserRoleForm));
