import React, { Component } from 'react'
import { connect } from 'react-redux'
import SecurityModel from '../../../../../../data/models/security/security'
import I18n from '../../../../../../i18n'
import { openLoader, closeLoader } from '../../../../../../redux-store/loader'
import { closeModal, openModal } from '../../../../../../redux-store/modal'
import UserRoleForm from './components/Form'
import _ from 'lodash'

class UserRoles extends Component {
    constructor(props) {
        super(props)

        this.state = {
            userInfo: {},
            loaded: false,
            pretorians: [],
            roleList: [

                {
                    
                    label: "Admin",
                    _id: 'admin',
                    disabled: true

                },
                {
                    
                    label: "Advisor",
                    _id: 'advisor',
                    disabled: true

                },
                {
                    
                    label: "Pretoriano",
                    _id: 'pretorian'

                },
                {
                    
                    label: "Pretor",
                    _id: 'pretor'

                }
            ]
        }

    }

    componentDidMount() {
        
        this.onGetUserInfo();

    }

    onGetUserInfo = async () => {

        const { userid, closeModal, openModal, openLoader,  closeLoader } = this.props;

        let message = {
            headerTitle : "Error",
            title : I18n.t('messages.modal.commonError'),
            buttons : [
                
                {
                    text : I18n.t('actions.accept'),
                    callback : () => closeModal()
                
                }

            ]

        };

        try {
           
            openLoader();

            const { data }  = await SecurityModel.getUserInfo(userid);
            const users = await SecurityModel.getUsers();
            const pretorians = _.chain(users.data).filter(user => user.role && user.role.indexOf('pretorian')>-1).map(pretorianUser => {

                return {
                    value: pretorianUser._id,
                    text: pretorianUser.name && pretorianUser.surname ? pretorianUser.name + " " + pretorianUser.surname : I18n.t('user.info.noName'),
                    key: pretorianUser._id
                }
            }).value();

            pretorians.push({
                key: 1,
                value: 'noPretorian',
                text: I18n.t('user.info.noPretorian')
            });

            if (data && users.data) {

                if (!data.pretorianUserId) {

                    data.pretorianUserId =  undefined;
                    
                }

                this.setState({ loaded: true , pretorians, userInfo: { pretorianUserId: _.get(data, 'pretorianUserId', 'noPretorian') , role: data.role || [], advisorVerified: _.get(data, 'advisorVerified', false)} });

            }
            
        } catch (error) {

            closeLoader();
            openModal(message);

        } finally {

            closeLoader();

        }

    }

    render() {

        const { userInfo, roleList, loaded, pretorians } = this.state;

        return (
            <>
                {loaded && <UserRoleForm pretorians={ pretorians } options={ roleList } initialValues={ userInfo } { ...this.props }/>}
            </>
        )
    }
}

export default connect(null, { openLoader, closeLoader, openModal, closeModal })(UserRoles)