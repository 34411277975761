// Third party libraries
import _ from 'lodash';
import { connect } from 'react-redux';
import React from 'react';
import { Segment, Header, Table, Button, Icon, Form, Input, Checkbox } from 'semantic-ui-react';

// Models
import SessionModel from '../../../../../data/models/sessions';

// Redux
import { openLoader, closeLoader } from '../../../../../redux-store/loader';
import { openModal , closeModal } from '../../../../../redux-store/modal';

// Locales
import I18n from '../../../../../i18n';

class SequenceCategory extends React.Component {

	constructor (props) {

		super (props);

		this.state = {
			column: null,
			direction: null,
			categories: [],
			activeForm: false,
			selectCategory: {
				_id: null,
				label: '',
			}
        };

	}

	componentDidMount() {

        this.onGetCategories();

	}

	onGetCategories = async () => {

		try {

			this.props.openLoader();
			const response = await SessionModel.getSequenceCategories();
            this.setState({ categories: response.data });

		} catch (error) {

            console.error('error onGetCategories', error);

		} finally {

            this.props.closeLoader();

        }

	};

	onRemoveCategory = categoryId => {

        const message = {
            title: I18n.t('practice.removeCategory'),
            buttons: [{
                text: I18n.t('actions.accept'),
                callback: async () => {

                    this.removeCategory(categoryId);
                    this.props.closeModal();

                }
            }, {
                text: I18n.t('actions.cancel'),
                callback: this.props.closeModal
            }]
        };

        this.props.openModal(message);

	}

	removeCategory = async categoryId => {

		try {

			this.props.openLoader();
			await SessionModel.deleteSequenceCategory(categoryId);

		} catch (error) {

            console.error('removeCategory: ko', error);

		} finally {

			this.props.closeLoader();
            this.onGetCategories();

		}

	};

	onAddCategory = () => this.setState({ activeForm: true });

	onEditCategory = category => this.setState({ activeForm: true, selectCategory: category });

	handleSort = clickedColumn => () => {

		const { column, categories, direction } = this.state;

		if (column !== clickedColumn) {

			this.setState({
				column: clickedColumn,
				categories: _.sortBy(categories, [clickedColumn]),
				direction: 'ascending',
			});

		} else {

            this.setState({
                categories: categories.reverse(),
                direction: direction === 'ascending' ? 'descending' : 'ascending',
            });

        }

	};

	onReset = () => {

		this.setState({
			selectCategory: { _id: null, label: '', comingSoonDisabled: false },
			activeForm: false,
        });

	};

	onChangeValue = (label, comingSoonDisabled) => {

		const category = { ...this.state.selectCategory };
		category.label = label;
        category.comingSoonDisabled = comingSoonDisabled;
        this.setState({ selectCategory: category });

	};

	handleSubmit = async () => {

        try {

            this.props.openLoader();

            if (this.state.selectCategory._id) {

                await SessionModel.editSequenceCategory(this.state.selectCategory._id, { label: this.state.selectCategory.label, comingSoonDisabled: this.state.selectCategory.comingSoonDisabled });

            } else {

				await SessionModel.createSequenceCategory({ label: this.state.selectCategory.label, comingSoonDisabled: this.state.selectCategory.comingSoonDisabled });

            }

        } catch (error) {

            console.error('Error creating/editing sequence category', error);

        } finally {

            this.props.closeLoader();
            this.onReset();
            this.onGetCategories();

        }

	};

	renderList = () => {

		const { column, direction, activeForm } = this.state;

		return (
			<Table celled selectable sortable>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell onClick={ this.handleSort('label') } sorted={ column === 'label' ? direction : null }>{ I18n.t('practice.name') }</Table.HeaderCell>
						<Table.HeaderCell>{ I18n.t('actions.edit') }</Table.HeaderCell>
						<Table.HeaderCell>{ I18n.t('actions.remove') }</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{ (this.state.categories || []).map(category => (
						<Table.Row key={ category._id }>
							<Table.Cell>{ category.label }</Table.Cell>
							<Table.Cell style={{ textAlign: 'center' }}>
								<Icon name='edit' size='large' onClick={ () => this.onEditCategory(category) }></Icon>
							</Table.Cell>
							<Table.Cell style={{ textAlign: 'center' }}>
								<Icon name='eraser' size='large' onClick={ () => this.onRemoveCategory(category._id) }></Icon>
							</Table.Cell>
						</Table.Row>
					)) }
				</Table.Body>
				<Table.Footer fullWidth>
					<Table.Row>
						<Table.HeaderCell colSpan='6'>
							<Button disabled={ activeForm } type='button' floated='right' primary onClick={ this.onAddCategory }>
								{ I18n.t('practice.addCategory') }
							</Button>
							<div style={{ clear: 'both' }}></div>
						</Table.HeaderCell>
					</Table.Row>
				</Table.Footer>
			</Table>
        );

	};

	render() {

		const { activeForm, selectCategory } = this.state;

		return (
			<React.Fragment>
				<Segment>
					<Header as='h3'>{ I18n.t('practice.practiceList') }</Header>
					{ this.renderList() }
				</Segment>
				{ activeForm && (
					<Segment>
						<Form noValidate onSubmit={ this.handleSubmit }>
							<Form.Field>
								<label>{ I18n.t('practice.name') }</label>
								<Input
									name='label'
									placeholder={ I18n.t('practice.name') }
									value={ selectCategory.label }
									onChange={ (e, { value }) => this.onChangeValue(value, selectCategory.comingSoonDisabled) }
								/>
							</Form.Field>
							<Form.Field>
                                <Checkbox
                                    label={ I18n.t('practice.comingSoonDisabled') }
                                    onClick={ (e, { checked }) => this.onChangeValue(selectCategory.label, checked) }
                                    checked={ selectCategory.comingSoonDisabled || false }
                                />
							</Form.Field>
							<Button type='submit' primary floated='right'>
								{ I18n.t('actions.save') }
							</Button>
							<Button type='button' onClick={ this.onReset } floated='right'>
								{ I18n.t('actions.cancel') }
							</Button>
							<div style={{ clear: 'both' }}></div>
						</Form>
					</Segment>
				)}
			</React.Fragment>
		);

	}

}

export default connect(null, { openLoader, closeLoader, openModal , closeModal })(SequenceCategory);