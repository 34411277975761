import _ from 'lodash';
import React from 'react';
import { Form, Label, Input, Icon } from 'semantic-ui-react';
import I18n from '../../../../i18n';

// import { hexadecimal, required } from '../formValidations';

class TagList extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            hasError: false,
            label: '',
            type: '',
            tags: [],
            value: ''
        }

    }

    componentDidMount() {

        this.setState({tags: _.get(this.props, 'input.value', []) });

    }

    onChange = (event) => {

        let formErrors = [];
        let errorLabel = [];
        _.each(formErrors, item => errorLabel.push(item));
        this.setState({ hasError: !!errorLabel.length, label: errorLabel, value: event.target.value });

    }

    onAddTag = () => {

        if (!this.state.hasError && this.state.value !== '') {
            
            this.setState({ tags: [...this.state.tags, this.state.value] }, () => {

                this.props.input.onChange(this.state.tags);
                this.setState({ value: '' });

            });

        }

    }

    onRemoveTag = index => this.setState({ tags: [ ..._.slice(this.state.tags, 0, index), ..._.slice(this.state.tags, index + 1) ] }, () => this.props.input.onChange(this.state.tags));

    render() {

        return (
            <Form.Field className={ this.props.fieldClasses } error={ this.state.hasError }>
                <label>{ this.props.label }</label>
                <Input
                    icon='tags'
                    iconPosition='left'
                    onChange={ this.onChange }
                    value={ this.state.value }
                    label={{ tag: true, content: I18n.t('practice.addTag'), onClick: () => this.onAddTag() }}
                    labelPosition='right'
                    placeholder={ this.props.placeholder } />
                { this.props.meta.touched && this.props.meta.invalid && <Label basic color={ this.props.colorLabel || 'red' } pointing>{ this.props.meta.error }</Label> }
                { (this.props.input.value || []).map((tag, index) => (
                    <Label className="tagForm" as='a' color='teal' tag key={ index }>{ tag } <Icon className="pointer" name='delete' onClick={ () => this.onRemoveTag(index) } /></Label>
                )) }
                
            </Form.Field>
        );

    }

}

export default TagList;