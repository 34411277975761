import _ from 'lodash';

import i18n from "../../i18n";

/**************************************** Validations ****************************************/

export const required = value => value || value === 0 ? undefined : i18n.t('validations.required');
export const fileRequired = value => _.get(value, 'fileName', '') !== '' ? undefined : i18n.t('validations.required');
export const hexadecimal = value => ( /^#[0-9A-F]{6}$/i).test(value) ? undefined : i18n.t('validations.hexadecimal');
export const minValue = min => value => min <= value ? undefined : i18n.t('validations.minValue', { min });
export const maxValue = max => value => value <= max ? undefined : i18n.t('validations.maxValue', { max });
export const minLength = min => value => !value || min < value.length ? undefined : i18n.t('validations.minLength', { min });
export const maxLength = max => value => !value || value.length < max ? undefined : i18n.t('validations.maxLength', { max });
export const requiredArray = value => !_.isEmpty(value) ? undefined : i18n.t('validations.required');

/**************************************** Restrictions ***************************************/

export const convertToNumeric = (value, canBeFloat = false) => {

    if (value) {

        let result = canBeFloat ? value.replace(/[^0-9.-]/g, '') : value.replace(/[^0-9-]/g, '');

        if (result.split('.').length > 2 || result.split('-').length > 2) {

            result = result.substring(0, result.length - 1);

        }

        return result;
        
    }

    return "";

};