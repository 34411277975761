const defaultProgram = 

    {
        title: {
            "ES": ""
        },
        description:  {
            "ES": ""
        },
        tags: [],
        ranking: 5,
        types: ['none', 'none', 'none', 'none'],
        level: "01",
        targets: [],
        imageUrl: '',
        videoUrl: '',
        thumbUrl: '',
        categories: [],
        public: 1
    }

export {
    defaultProgram
}