// Third party libraries
import _ from 'lodash';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Segment, Form, Input, Select, Menu, Pagination, Table} from 'semantic-ui-react';
import Moment from 'moment';

// Models
import PaymentModel from '../../../../data/models/payment/payment';

// Redux
import { openLoader, closeLoader } from '../../../../redux-store/loader';

// Locales
import I18n from '../../../../i18n';

class StellarList extends Component {

    constructor(props) {

        super(props);

        this.state = {
            search: '',
            statusValue: 'all', //1:initial, 2:todos
            investments: [],
            filteredInvestments: [],
            pageNumber: 1,
            pageSize: 0,
            recordsPerPage: 20,
            column: 'createdAt',
            direction: 'descending',
            loaded: false,
            statusOptions: [{
                key: 0,
                text: 'Todos',
                value: 'all'
            }, {
                key: 1,
                text: 'Inicial',
                value: 'initial'
            }]
        };

    }

    componentDidMount() {

        this.getData();

    }

    getData = async () => {

        const { openLoader, closeLoader } = this.props;
        const { column, recordsPerPage } = this.state;

        try {

            openLoader();

            const investments = await PaymentModel.getInvestements();

            this.setState({
                investments: investments.data,
                filteredInvestments: _.orderBy(investments.data, [column], ['desc']),
                pageSize: Math.ceil(investments.data.length / recordsPerPage)
            });

        } catch (error) {

            console.error(error);

        } finally {

            closeLoader();
            this.setState({ loaded: true });

        }

    }

    onFilter = () => {

        let filteredInvestments = this.state.investments;

        const { statusValue, recordsPerPage, search } = this.state;

        filteredInvestments = filteredInvestments.filter(user => {

            if ((!!search.length && (!_.get(user, 'name', '').toLowerCase().includes(search.toLowerCase()) && !_.get(user, 'email', '').toLowerCase().includes(search.toLowerCase()) && !_.get(user, 'email', '').toLowerCase().includes(search.toLowerCase())))
                || (statusValue && statusValue !== 'all' && !_.get(user, 'status', []).includes(statusValue))
            ) {

                return false;

            }

            return true;

        });

        this.setState({ filteredInvestments, pageSize: Math.ceil(filteredInvestments.length / recordsPerPage), pageNumber: 1 });

    }

    onFilterListByCompleteNameOrEmail = search => this.setState({ search }, this.onFilter);

    onFilterByStatus = statusValue => this.setState({statusValue}, this.onFilter);

    paginate = () => this.state.filteredInvestments.slice((this.state.pageNumber - 1) * this.state.recordsPerPage, this.state.pageNumber * this.state.recordsPerPage);

    handleSort = clickedColumn => () => {

        const { column, filteredInvestments, direction } = this.state;

        if (column !== clickedColumn) {

            this.setState({
                column: clickedColumn,
                filteredInvestments: clickedColumn === 'createdAt' ? filteredInvestments.sort((a, b) => Moment(a[clickedColumn]).diff(b[clickedColumn])) : _.sortBy(filteredInvestments, [clickedColumn]),
                direction: 'ascending',
            })

            return;

        }

        this.setState({
            filteredInvestments: filteredInvestments.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending'
        });

    }

    onPageChange = (e, pageInfo) => this.setState({ pageNumber: pageInfo.activePage });

    onRenderTable = () => {

        const { column, direction, filteredInvestments, recordsPerPage } = this.state;

        return (
            <Table celled selectable sortable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell onClick={ this.handleSort('name') } sorted={ column === 'name' ? direction : null }>{ I18n.t('user.list.name') }</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('surname') } sorted={ column === 'surname' ? direction : null }>{ I18n.t('user.list.surname') }</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('email') } sorted={ column === 'email' ? direction : null }>{ I18n.t('user.list.email') }</Table.HeaderCell>
                        <Table.HeaderCell>Clave Pública Stellar</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('amount') } sorted={ column === 'amount' ? direction : null }>Aportación</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('createdAt') } sorted={ column === 'createdAt' ? direction : null }>Fecha aportación</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('status') } sorted={ column === 'status' ? direction : null }>Fase</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    { this.paginate().map(user => (
                        <Table.Row key={ user._id }>
                            <Table.Cell>{ user.name || I18n.t('user.info.noDefined') }</Table.Cell>
                            <Table.Cell>{ user.surname || I18n.t('user.info.noDefined') }</Table.Cell>
                            <Table.Cell>{ user.email || I18n.t('user.info.noDefined') }</Table.Cell>
                            <Table.Cell>{ user.stellarPublicKey }</Table.Cell>
                            <Table.Cell>{ `${user.amount}€`}</Table.Cell>
                            <Table.Cell>{ Moment(user.createdAt).format('DD/MM/YYYY HH:mm:ss') }</Table.Cell>
                            <Table.Cell>{ user.status }</Table.Cell>

                        </Table.Row>
                    )) }
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan={ 7 }>
                            <Menu floated='right'>
                                <Pagination
                                    onPageChange={ this.onPageChange }
                                    boundaryRange={ 1 }
                                    defaultActivePage={ 1 }
                                    prevItem={ null }
                                    nextItem={ null }
                                    siblingRange={ 2 }
                                    totalPages={ Math.ceil(filteredInvestments.length / recordsPerPage) }
                                />
                            </Menu>
                            <div style={{ clear: "both" }}></div>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        );

    }

    render() {

        const { statusValue, search, statusOptions, loaded } = this.state;

        return (
            <Segment>
            <div>
                <Form>
                    <Form.Group inline>
                        <Form.Field>
                            <label>Nombre / Apellidos / Email</label>
                            <Input value={ search } onChange={ e => this.onFilterListByCompleteNameOrEmail(e.target.value) } placeholder="Buscar por nombre o apellido o email"/>
                        </Form.Field>
                        <Form.Field>
                            <label>Fase</label>
                            <Select value={ statusValue } onChange={ (e, { value }) => this.onFilterByStatus(value) } options={ statusOptions } />
                        </Form.Field>
                    </Form.Group>
                </Form>
            </div>
            { loaded && this.onRenderTable() }
        </Segment>
        )
    }
}

export default connect(null, { openLoader, closeLoader })(StellarList);
