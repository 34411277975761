import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import SessionModel from '../../../../../../../../data/models/sessions';
import { openLoader, closeLoader } from '../../../../../../../../redux-store/loader'

export default function withData(WrappedComponent) {
    
    class HOC extends React.Component {
        constructor(props) {
          super(props);

          this.state = {
            categories: [],
            sequences: [],
            programs: [],
            loaded: false,
            userAssignments: []
          };
          
        }
    
        componentDidMount() {

            this.getData();
         
        }
        
        getData = async () => {

            const { openLoader, closeLoader, userid } = this.props;

            try {

                openLoader();
                const sequences = await SessionModel.getSequences();
                const categories = await SessionModel.getSequenceCategories();
                const programs = await SessionModel.getPrograms();
                const userAssignments = await SessionModel.getUserAssignments(userid);
                
                this.setState({
                    sequences: _.filter(sequences.data, sequence => sequence.public !== 1) || [],
                    categories: categories.data || [],
                    programs: _.filter(programs.data, program => program.public !== 1) || [],
                    userAssignments: userAssignments.data || []
                });

            } catch (error) {

                console.error(error);
                
            } finally {

                closeLoader();
                this.setState({loaded: true});

            }

        }

        onGetAssignments = async () => {

            const { openLoader, closeLoader, userid } = this.props;
            const userAssignments = await SessionModel.getUserAssignments(userid);

            try {

                openLoader();

                this.setState({userAssignments: userAssignments.data || []});
                
            } catch (error) {
                
                console.error(error);

            } finally {

                closeLoader();

            }

        }

        onHandleAssignment = (type, id, action) => {

            if (action === 'delete') {
                
                this.onDeleteAssignment(id);

            } else {

                this.onCreateAssignment(type, id);

            }

        }

        onCreateAssignment = async (type, sourceId) => {

            const { openLoader, closeLoader, userid } = this.props;


            try {
                
                openLoader();
                await SessionModel.createUserAssignment(userid, { type, sourceId });

            } catch (error) {

                console.error('onCreateAssignment:error:', error);

            } finally {

                closeLoader();
                this.onGetAssignments();

            }

        }

        onDeleteAssignment = async assignmentId => {

            const { openLoader, closeLoader, userid } = this.props;

            try {
                
                openLoader();
                await SessionModel.deleteUserAssignment(userid, assignmentId);

            } catch (error) {

                console.error('onDeleteAssignment:error:', error);

            } finally {

                closeLoader();
                this.onGetAssignments();

            }

        }

        render() {

            const { sequences, categories, programs, userAssignments, loaded } = this.state;
          
            return loaded && 
                <WrappedComponent 
                    sequences={ sequences}
                    categories={ categories}
                    programs={ programs}
                    userAssignments={userAssignments}
                    onHandleAssignment={this.onHandleAssignment}
                    {...this.props} 
                />;

        }

      };

      return connect(null, { openLoader, closeLoader })(HOC);

}