import React, { Component } from 'react';
import _ from 'lodash';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

class LineGraphView extends Component {

    constructor(props) {

        super(props)

        this.state = {
            id: `linegraph#${Math.floor(Math.random() * 100)}`
        }

    }

    componentWillUnmount() {

        if (this.chart) {

            this.chart.dispose();

        }

    }

    componentDidUpdate(oldProps) {

        if (!_.isEqual(oldProps.data, this.props.data)) {

            this.chart.data = this.props.data;

        }

    }

    componentDidMount() {

        const { id } = this.state;

        const { data = [] } = this.props;

        let chart = am4core.create(id, am4charts.XYChart);

        chart.data = data;

        // Create axes
        const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.minGridDistance = 50;

        chart.yAxes.push(new am4charts.ValueAxis());

        // Create series
        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = "value";
        series.dataFields.dateX = "date";
        series.tooltipText = "{value}"
        series.strokeWidth = 2;
        series.minBulletDistance = 10;
        series.tooltipText = "{valueY}";
        series.tooltip.pointerOrientation = "vertical";
        series.name = "Series #1";

        // Add scrollbar
        chart.scrollbarX = new am4core.Scrollbar();

        // Add cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = dateAxis;

        this.chart = chart;

    }

    render() {

        const { id } = this.state;

        return <div id={ id } style={{ width: '100%', height: '500px' }}></div>;

    }

}

export default LineGraphView;