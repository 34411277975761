import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'semantic-ui-react';

import { closeSequencePreviewModal } from '../../../redux-store/sequencePreviewModal';

import I18n from '../../../i18n';

import './modal.scss';

class SequencePreviewModal extends React.Component {

    videoPlayersCounter = 5;
    videoWidthSufix = Math.max(window.innerHeight, window.innerWidth) > 1000 ? '' : '_720';
    videoplayers = [];

    constructor(props) {

        super(props);

        this.state = {
            currentPlayer: 0,
            currentVideo: 0,
            videoPlayerSources: [],
            loaded: false,
            maxWidth: 0,
            videos: []
        };

    }

    componentDidUpdate() {

        if (!this.props.open && this.state.loaded) {

            this.setState({
                currentPlayer: 0,
                currentVideo: 0,
                videoPlayerSources: [],
                loaded: false,
                maxWidth: 0,
                videos: []
            });

        } else if (this.props.open && !_.isEmpty(this.props.data) && !this.state.loaded) {

            this.preloadVideos();

        }

    }

    preloadVideos = () => {

        const videos = [];
        _.each(this.props.data.routine, asana => {

            videos.push({
                mov: asana.video.substring(0, asana.video.indexOf('.mov')) + this.videoWidthSufix + '.mov',
                mp4: asana.video.substring(0, asana.video.indexOf('.mov')) + this.videoWidthSufix + '.mp4',
                webm: asana.video.substring(0, asana.video.indexOf('.mov')) + this.videoWidthSufix + '.webm',
                duration: asana.duration,
                permanence: true,
                videoCode: asana.video.substring(asana.video.lastIndexOf('/') + 1, asana.video.lastIndexOf('.'))
            });

            _.each(asana.transitionVideos, transitionVideo => {

                videos.push({
                    mov: transitionVideo.substring(0, transitionVideo.indexOf('.mov')) + this.videoWidthSufix + '.mov',
                    mp4: transitionVideo.substring(0, transitionVideo.indexOf('.mov')) + this.videoWidthSufix + '.mp4',
                    webm: transitionVideo.substring(0, transitionVideo.indexOf('.mov')) + this.videoWidthSufix + '.webm',
                    permanencia: false,
                    videoCode: transitionVideo.substring(transitionVideo.lastIndexOf('/') + 1, transitionVideo.lastIndexOf('.'))
                });
    
            });

        });

        const videoPlayerSources = [];
        for (let i = 0; i < this.videoPlayersCounter; i++) {

            videoPlayerSources.push(videos[i]);

        }

        const maxWidth = document.getElementById('videoWrapper').clientHeight * 16 / 9;

        this.setState({ loaded: true, videoPlayerSources, maxWidth, videos }, () => {

            for (let i = 0; i < this.videoPlayersCounter; i++) {
    
                this.videoplayers.push(document.getElementById('video_' + i));
                this.videoplayers[i].addEventListener('ended', this.onVideoEnded);
    
            }

        });

    }

    onVideoEnded = () => {

        if (this.state.currentVideo < this.state.videos.length - 1) {

            const currentVideo = this.state.currentVideo + 1;
            let endedPlayer = this.state.currentPlayer;
            let playerToGo = endedPlayer === this.videoPlayersCounter - 1 ? 0 : endedPlayer + 1;

            let nextVideoToLoad = currentVideo + this.videoPlayersCounter - 1 < this.state.videos.length ? currentVideo + this.videoPlayersCounter - 1 : 0;
            const videoPlayerSources = _.cloneDeep(this.state.videoPlayerSources);
            videoPlayerSources[endedPlayer] = this.state.videos[nextVideoToLoad];

            this.setState({
                currentPlayer: playerToGo,
                currentVideo,
                videoPlayerSources
            }, () => this.videoplayers[endedPlayer].load());
            
            this.videoplayers[playerToGo].play();

        }

    }

    handleClose = () => this.props.closeSequencePreviewModal();

    handlePlay = () => this.videoplayers[this.state.currentPlayer].play();

    renderVideoPlayers = () => {

        const playersRenderization = [];
        for (let i = 0; i < this.videoPlayersCounter; i++) {

            playersRenderization.push(i);

        }

        return !_.isEmpty(this.state.videoPlayerSources) && playersRenderization.map(i => 

            <video key={ 'video_' + i } style={{ display: this.state.currentPlayer === i ? 'block' : 'none', width: '100%' }} controls muted preload="auto" id={ 'video_' + i }>
                <source src={ this.state.videoPlayerSources[i].webm } type="video/webm" />
                <source src={ this.state.videoPlayerSources[i].mp4 } type="video/mp4" />
                <source src={ this.state.videoPlayerSources[i].mov } />
                Your browser does not support the video tag.
            </video>

        );

    }

    renderCurrentVideoInfo = () => !_.isEmpty(this.state.videoPlayerSources) && <div style={{ paddingTop: '20px' }}>{ this.state.videos[this.state.currentVideo].videoCode + ' (' + (this.state.videos[this.state.currentVideo].permanence ? 'Permanencia: ' + this.state.videos[this.state.currentVideo].duration + ' segundos' : 'Transición') + ') - Video: ' + (this.state.currentVideo + 1) + '/' + this.state.videos.length }</div>;

    render() {

        return this.props.open && (
            <Modal open={ this.props.open } closeOnEscape closeOnDimmerClick closeIcon onClose={ this.handleClose } className="home-modal" size="large">
                <Modal.Header style={{ color: '#fff' }}>{ I18n.t('practice.previewModalTitle') + ': ' + this.props.data.title.ES }</Modal.Header>
                <Modal.Content id='videoWrapper'>
                    <div style={{ maxWidth: this.maxWidth }}>{ this.renderVideoPlayers() }</div>
                    { this.renderCurrentVideoInfo() }
                </Modal.Content>
            </Modal>
        );

    }

}

const mapStateToProps = state => _.cloneDeep(state.sequencePreviewModal);

export default connect(mapStateToProps, { closeSequencePreviewModal })(SequencePreviewModal);