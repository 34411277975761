import SecurityModel from '../data/models/security/security';
import history from '../history';

//actions
const LOGIN_USER = 'LOGIN_USER';
const CLOSE_USER_SESSION = 'CLOSE_USER_SESSION';

export const loginUser = credentials => async dispatch => {

    try {
        
        const response = await SecurityModel.login(credentials);

        if (response.status === 200) {

            localStorage.setItem('token', response.data.jwt);
            dispatch ({ type: LOGIN_USER, payload: response.data.user });

        }
        

    } catch (errorCode) {

        dispatch ({ type: LOGIN_USER, payload: {} });
        throw errorCode;

    }

};

export const closeSession = () => {

    if(localStorage.getItem('token')) {

        localStorage.removeItem('token');

    }
    
    history.push('/');
    
    return { type: CLOSE_USER_SESSION, payload: {} } 

}

const INITIAL_STATE = {
    
    userSession: {}

};

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case LOGIN_USER:
        case CLOSE_USER_SESSION:
            return { ...state, userSession: action.payload };   
        default:
            return state;
            
    }

};

export default reducer;