// Third party libraries
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Components
import SequenceForm from './form';

// Redux
import { openLoader, closeLoader } from "../../../../redux-store/loader";
import { openModal , closeModal } from '../../../../redux-store/modal';
import { openSequencePreviewModal, closeSequencePreviewModal } from '../../../../redux-store/sequencePreviewModal';
import { getSequences } from '../../../../redux-store/sequence';

// Models
import SessionModel from '../../../../data/models/sessions';

const defaultSequence = {
    title: {
        "ES": ""
    },
    description:  {
        "ES": ""
    },
    tags: [],
    ranking: 5,
    types: ['none', 'none', 'none', 'none'],
    level: "01",
    routine: [],
    targets: [],
    imageUrl: '',
    videoUrl: '',
    thumbUrl: '',
    duration: -1,
    categories: [],
    main: false,
    public: 1,
    startDate: null,
    endDate: null,
    programId: 'none',
    onlyProgram: false,
    order: 0
}

class SequenceEdit extends Component {

    constructor(props) {

        super(props);

        this.state = {
            routineImages: [],
            routineNames: [],
            durationSum: 0,
            categories: [],
            advancedAsanas: [],
            beginnerAsanas: [],
            withSupportAsanas: [],
            programs: []
        }

    }

    componentDidMount() {

        this.onGetData();
        this.props.getSequences();

    }

    onGetData = async () => {

        try {

            this.props.openLoader();

            const categories = await SessionModel.getSequenceCategories();
            const asanas = await SessionModel.getAsanas();
            const programs = await SessionModel.getPrograms();

			if (categories.status === 200 && asanas.status === 200) {

                const beginnerAsanas = [];
                const advancedAsanas = [];
                const withSupportAsanas = [];
                const routineImages = [];
                const routineNames = [];

                _.each(asanas.data || [], asana => {

                    if (asana.isBeginner) {

                        beginnerAsanas.push({
                            key: asana._id,
                            text: asana.name['ES'] + ':' + asana.code,
                            value: asana.code,
                            image: { avatar: true, src: asana.image },
                        });

                    }

                    if (asana.isAdvanced) {

                        advancedAsanas.push({
                            key: asana._id,
                            text: asana.name['ES'] + ':' + asana.code,
                            value: asana.code,
                            image: { avatar: true, src: asana.image },
                        });

                    }

                    if (asana.isWithSupport) {

                        withSupportAsanas.push({
                            key: asana._id,
                            text: asana.name['ES'] + ':' + asana.code,
                            value: asana.code,
                            image: { avatar: true, src: asana.image },
                        });

                    }

                    routineNames[asana.code] = asana.name['ES'];
                    routineImages[asana.code] = asana.image;

                });

                let _programs = [{
                    key: 0,
                    text: 'Ninguno',
                    value: 'none'
                }];

                /***
                 * * Si seleciona un programa y es onlyProgram, la secuencia no aparecerá en la categoría que se ha seleccionado..
                */
                if (programs.data) {

                    _.each(programs.data, ({ _id, title }) => _programs.push({
                        key: _id,
                        text: title['ES'],
                        value: _id
                    }));

                }

				this.setState({ routineNames, routineImages, asanas: asanas.data, categories: categories.data, beginnerAsanas, advancedAsanas, programs: _programs, withSupportAsanas });

            }

		} catch (error) {

            console.error("Error onGetCategories", error);

		} finally {

            this.props.closeLoader();

		}

    }

    render() {

        const sequence = this.props.match.params.sequenceId === 'new' ? _.cloneDeep(defaultSequence) : _.cloneDeep(_.find(this.props.sequences, ({ _id }) => _id === this.props.match.params.sequenceId))

        const { routineNames, routineImages, asanas, beginnerAsanas, advancedAsanas, categories, programs, withSupportAsanas } = this.state;

        return !_.isEmpty(sequence) && (
            <SequenceForm
                initialValues={ sequence }
                routineNames={ routineNames }
                routineImages={ routineImages }
                asanas={ asanas }
                beginnerAsanas={ beginnerAsanas }
                advancedAsanas={ advancedAsanas }
                withSupportAsanas={ withSupportAsanas }
                categories={ categories }
                programs={ programs }
                history={ this.props.history }
                { ...this.props }
            />
        );

    }

}

const mapStateToProps = state => _.cloneDeep(state.sequence);

export default connect(mapStateToProps, { closeLoader, closeModal, closeSequencePreviewModal, getSequences, openModal, openLoader, openSequencePreviewModal })(SequenceEdit);