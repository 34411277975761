import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment, Header, Form, Input, Button, Select } from 'semantic-ui-react';
import I18n from '../../../../../i18n';
import SessionModel from '../../../../../data/models/sessions';
import { openSequencePreviewModal } from '../../../../../redux-store/sequencePreviewModal';
import { openModal, closeModal } from '../../../../../redux-store/modal';
import { openLoader, closeLoader } from '../../../../../redux-store/loader';

class SequencePreview extends Component {
    constructor(props) {
        super(props)

        this.state = {
            durationSequence: '',
            bioMetricMatrix: '',
            levelSequence: '',
            durationOptions: [

                {
                    key: 0,
                    value: '-1',
                    text: 'Corta'
                },
                {
                    key: 1,
                    value: '0',
                    text: 'Media'
                },
                {
                    key: 1,
                    value: '1',
                    text: 'Larga'
                }
            ],
            levelOptions: [

                {
                    key: 0,
                    value: '01',
                    text: 'Principiante'
                },
                {
                    key: 1,
                    value: '00',
                    text: 'Avanzado'
                }
            ] 
        }
    }

    onChangeValue = (value, path) => this.setState({ [path]: value });

    onView = async () => {

        const { durationSequence, bioMetricMatrix, levelSequence } = this.state;
        let sequenceToFind = durationSequence + bioMetricMatrix + '_' + levelSequence;

        let message = {
            headerTitle : "Error",
            title : I18n.t('messages.modal.incorrectSequence'),
            buttons : [
                
                {
                    text : I18n.t('actions.accept'),
                    callback : () => this.props.closeModal()
                
                }

            ]
        };

        try {
            
            this.props.openLoader();
            const sequenceInfo = await SessionModel.getSequence(sequenceToFind, 'SES');

            if (sequenceInfo.data) {

                sequenceInfo.data.title = {
                    ES: 'SECUENCIA PERSONALIZADA: ' + sequenceInfo.data.code
                };

            }

            this.props.openSequencePreviewModal(sequenceInfo.data);

        } catch (error) {

            this.props.openModal(message);

        } finally {

            this.props.closeLoader();

        }

    }

    render() {

        const { sequence, durationOptions, levelOptions } = this.state;

        return (
            <Segment style={{minHeight: '100vh'}}>
                <Header as="h3">{I18n.t("practice.previewCustomSequence")}</Header>
                <Form onSubmit={ this.onView }>
                    <Form.Group inline>
                        <Form.Field>
                            <label>{ I18n.t('practice.sequence') }</label>
                            <Select placeholder={ I18n.t('practice.duration') } options={durationOptions} value={ sequence } onChange={ (e, { value }) => this.onChangeValue(value, 'durationSequence') } />
                        </Form.Field>
                        <Form.Field>
                            <Input placeholder={ I18n.t('practice.indicators') } value={ sequence } onChange={ (e, { value }) => this.onChangeValue(value, 'bioMetricMatrix') } />
                        </Form.Field>
                        <Form.Field>
                            <Select placeholder={ I18n.t('practice.level') } options={levelOptions} value={ sequence } onChange={ (e, { value }) => this.onChangeValue(value, 'levelSequence') } />
                        </Form.Field>
                        <Form.Field>
                            <Button type='submit' floated='right' primary>{I18n.t('actions.seek')}</Button>
                        </Form.Field>
                    </Form.Group>
                    
                </Form>
            </Segment>
        )
    }
}

export default connect(null, { openSequencePreviewModal, openModal, closeModal, openLoader, closeLoader })(SequencePreview)
