import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import auth from './auth';
import loader from './loader';
import modal from './modal';
import sequence from './sequence';
import sequencePreviewModal from './sequencePreviewModal';
import discountStudioModal from './discountModal';

export default combineReducers({
    form,
    auth,
    loader,
    modal,
    sequence,
    sequencePreviewModal,
    discountStudioModal
});