import React, { Component } from 'react'
import _ from 'lodash';
import { Icon, Segment, Table } from 'semantic-ui-react'
import I18n from '../../../../../../../../i18n';

const STATUS = ['premium', 'publica', 'privado'];

export default class SequenceView extends Component {
   
    render() {

        const { data, assignments, onHandleAssignment, assignmentFilter } = this.props;
        const items = _.filter(data, item => assignmentFilter === 1 ? !assignments[item._id] : assignments[item._id]);

        return (
            <>
                <Segment>
                    <p>Solo se podrán asignar/desasignar las secuencias que sean privadas o premium</p>
                    <Table celled selectable sortable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{ I18n.t('studio.image') }</Table.HeaderCell>
                                <Table.HeaderCell>{ I18n.t('faq.title') }</Table.HeaderCell>
                                <Table.HeaderCell>{ I18n.t('status.status') }</Table.HeaderCell>
                                <Table.HeaderCell>{ I18n.t('actions.assignmentUnassignment') }</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            { (items || []).map(item => (
                                <Table.Row key={item._id}>
                                    <Table.Cell collapsing>
                                        <img src={item.thumbUrl} alt="" style={{width: '150px'}} />
                                    </Table.Cell>
                                    <Table.Cell>{item.title.ES}</Table.Cell>
                                    <Table.Cell>{STATUS[item.public] ?? 'Sin definir'}</Table.Cell>
                                    <Table.Cell textAlign='center' collapsing>
                                        {assignments[item._id] && <Icon onClick={()=> onHandleAssignment(assignments[item._id].type, assignments[item._id].assignmentId, 'delete')} style={{width: '150px'}} className={ "pointer" } name="linkify" size="large"/>}
                                        {!assignments[item._id] && <Icon onClick={()=> onHandleAssignment('sequence', item._id, 'create')} style={{width: '150px'}} className={ "pointer" } name="unlinkify" size="large"/>}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Segment> 
            </>
        )
    }
}
