import _ from 'lodash';
import React, { Component } from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

 class MapGraphView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: `mapgraph#${Math.floor(Math.random() * 100)}`
        }

    }

    componentWillUnmount() {

        if (this.chart) {
            
            this.chart.dispose();

        }

    }
    
    componentDidUpdate(oldProps) {

        if (!_.isEqual(oldProps.data, this.props.data)) {

            if(this.chart._super) {

                this.chart.dispose();

            }

            this.createMap();

        }

    }

    createMap = () => {

        const { id } = this.state;

        const { data = [], legend } = this.props;

        // Create map instance
        let chart = am4core.create(id, am4maps.MapChart);

        let title = chart.titles.create();
        title.text = `[bold font-size: 20]${legend}[/]`;
        title.textAlign = "middle";

        // Set map definition
        chart.geodata = am4geodata_worldLow;

        // Set projection
        chart.projection = new am4maps.projections.Miller();

        // Create map polygon series
        let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
        polygonSeries.exclude = ["AQ"];
        polygonSeries.useGeodata = true;
        polygonSeries.nonScalingStroke = true;
        polygonSeries.strokeWidth = 0.5;
        polygonSeries.calculateVisualCenter = true;

        let imageSeries = chart.series.push(new am4maps.MapImageSeries());
        imageSeries.data = data;
        imageSeries.dataFields.value = "value";

        let imageTemplate = imageSeries.mapImages.template;
        imageTemplate.nonScaling = true

        let circle = imageTemplate.createChild(am4core.Circle);
        circle.fillOpacity = 0.7;
        circle.propertyFields.fill = "color";
        circle.tooltipText = "{name}: [bold]{value}[/]";

        imageSeries.heatRules.push({
            "target": circle,
            "property": "radius",
            "min": 4,
            "max": 30,
            "dataField": "value"
        })

        imageTemplate.adapter.add("latitude", function(latitude, target) {
        let polygon = polygonSeries.getPolygonById(target.dataItem.dataContext.id);
        if(polygon){
            return polygon.visualLatitude;
        }
        return latitude;
        })

        imageTemplate.adapter.add("longitude", function(longitude, target) {
        let polygon = polygonSeries.getPolygonById(target.dataItem.dataContext.id);
        if(polygon){
            return polygon.visualLongitude;
        }
        return longitude;
        })

        this.chart = chart;

    }

    componentDidMount() {

        this.createMap();
    }

    render() {
        const { id } = this.state;

        return (
            <>
                <div id={id} style={{width: '100%', height: '500px'}}></div>
            </>
        )
    }
}

export default MapGraphView;