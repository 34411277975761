import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { openLoader, closeLoader } from '../../../../../../redux-store/loader'
import { closeModal, openModal } from '../../../../../../redux-store/modal'
import PaymentModel from '../../../../../../data/models/payment/payment'
import I18n from '../../../../../../i18n'
import { Segment, Header, Table, Form, Button } from 'semantic-ui-react'
import moment from 'moment'
import { required } from '../../../../../../config/validations';
import CustomInput from '../../../../../components/form/input'

class UserLicences extends Component {
    constructor(props) {
        super(props)

        this.state = {
            licenses: [],
            column: 'startDate',
            direction: 'descending',
            originalData: []
        }
    }

    componentDidMount() {

        this.getUserLicences();

    }

    getUserLicences = async () => {

        let message = {
            headerTitle : "Error",
            title : I18n.t('messages.modal.commonError'),
            buttons : [
                
                {
                    text : I18n.t('actions.accept'),
                    callback : () => this.props.closeModal()
                
                }

            ]
        };

        try {

            this.props.openLoader();
            const response = await PaymentModel.getUserLicenses(this.props.userid);
            if (response.data) {

                this.setState({ licenses: _.orderBy(response.data, ['startDate'], ['desc'] ), originalData: response.data });

            }
            
        } catch (error) {
            
            this.props.closeLoader();
            this.props.openModal(message);

        } finally {

            this.props.closeLoader();

        }

    }

    handleSort = (clickedColumn) => () => {
        
        const { column, direction, originalData } = this.state;
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            licenses: _.orderBy(originalData, [clickedColumn], ['asc']),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
            licenses: _.orderBy(originalData, [clickedColumn], [direction === 'ascending' ? 'desc' : 'asc']),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        });

      }

      handleSubmit = async ({ days } ) => {

        let messageError = {
            headerTitle : "Error",
            title : I18n.t('messages.modal.commonError'),
            buttons : [
                
                {
                    text : I18n.t('actions.accept'),
                    callback : () => this.props.closeModal()
                
                }

            ]
        };

        let messageCorrect = {
            title : I18n.t('messages.modal.licenseExtended', { days }),
            buttons : [
                
                {
                    text : I18n.t('actions.accept'),
                    callback : () => this.props.closeModal()
                
                }

            ]
        };


        try {

            this.props.openLoader();
            await PaymentModel.extendUserLicense(this.props.userid, { days: parseInt(days) });
            this.props.openModal(messageCorrect);
            this.getUserLicences();
            
        } catch (error) {
        
            this.props.closeLoader();
            this.props.openModal(messageError);

        } finally {

            this.props.closeLoader();

        }

      }

    render() {

        const  { licenses, column, direction } = this.state;
        return (
            <React.Fragment>
                <Segment>
                    <Header as="h3">{I18n.t('user.info.licenseList')}</Header>
                    <Table celled selectable sortable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell onClick={this.handleSort('startDate')} sorted={column === 'startDate' ? direction : null}>{ I18n.t('user.info.startDate') }</Table.HeaderCell>
                                    <Table.HeaderCell onClick={this.handleSort('endDate')} sorted={column === 'endDate' ? direction : null}>{ I18n.t('user.info.endDate') }</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                            { licenses.map(license => (
                                <Table.Row key={ license._id } >
                                    <Table.Cell>{ moment(license.startDate).format('DD/MM/YYYY') }</Table.Cell>
                                    <Table.Cell>{ moment(license.endDate).format('DD/MM/YYYY') } </Table.Cell>
                                </Table.Row>
                            ))}
                            </Table.Body>
                        </Table>
                </Segment>
                <Segment>
                    <Header as="h3">{I18n.t('user.info.extendLicensePremium')}</Header>
                    <Form name="licenseForm" onSubmit={this.props.handleSubmit(this.handleSubmit)}>
                    <Field
                        component={ CustomInput }
                        placeholder={ '' }
                        name="days"
                        inline={true}
                        validate={ [required] }
                        restrictions={ [{ numeric: true }] } 
                        />
                        <Form.Field>
                            <Button floated="left" content={I18n.t('user.info.extend')} icon='plus' labelPosition='right' size="mini" />
                            <div style={{ clear: "both" }}></div>
                        </Form.Field>
                    </Form>
                </Segment>
            </React.Fragment>
        )
    }
}

export default reduxForm({
    form: 'licenseForm',
    touchOnBlur: true,
    touchOnChange: false,
    initialValues: {
        days: 0
    }
})(connect(null, { openLoader, closeLoader, closeModal, openModal })(UserLicences));
