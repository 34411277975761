import _ from 'lodash';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Checkbox, Form, Header, Menu, Pagination, Segment, Select, Table } from 'semantic-ui-react';
import PaymentModel from '../../../../data/models/payment/payment';
import SecurityModel from '../../../../data/models/security/security';
import I18n from '../../../../i18n';
import { openLoader, closeLoader } from '../../../../redux-store/loader';
import Moment from 'moment';

const types = {

    "advisor": "Advisor",
    "affiliation": "Afiliación",
    "affiliation-as-pretor": "Afiliación como pretor",
    "affiliation-as-pretorian": "Afiliación como pretoriano"
}

const concepts = {

    "tutorize": "Tutorizados",
    "fix-answer": "Correción respuesta",
    "answer": "Respuesta",
    "monthly-subscription": "Suscripción mensual",
    "annual-subscription": "Suscripcion anual"

}

class Commissions extends Component {
    constructor(props) {
        super(props)

        this.state = {
            commissions: [],
            selectedType: "all",
            selectedConcept: "all",
            selectedPay: "notPaid",
            users: {},
            indexedCommissions: {},
            filteredCommissions: [],
            pageNumber: 1,
            pageSize: 0,
            recordsPerPage: 20,
            column: 'createdAt',
            direction: 'descending',
            typeOptions: [
                {
                    key:1,
                    text: "Todos",
                    value: "all"
                },
                {
                    key:2,
                    text: types.advisor,
                    value: "advisor"
                },
                {
                    key:3,
                    text: types.affiliation,
                    value: "affiliation"
                },
                {
                    key:4,
                    text: types['affiliation-as-pretor'],
                    value: "affiliation-as-pretor"
                },
                {
                    key:5,
                    text: types['affiliation-as-pretorian'],
                    value: "affiliation-as-pretorian"
                }
            ],
            conceptOptions: [
                {
                    key:1,
                    text: "Todos",
                    value: "all"
                },
                {
                    key:2,
                    text: concepts.tutorize,
                    value: "tutorize"
                },
                {
                    key:3,
                    text: concepts['fix-answer'],
                    value: "fix-answer"
                },
                {
                    key:4,
                    text: concepts.answer,
                    value: "answer"
                },
                {
                    key:5,
                    text: concepts['monthly-subscription'],
                    value: "monthly-subscription"
                },
                {
                    key:6,
                    text: concepts['annual-subscription'],
                    value: "annual-subscription"
                }
            ],
            payOptions: [
                {
                    key:1,
                    text: "Todos",
                    value: "all"
                },
                {
                    key:2,
                    text: 'Pagado',
                    value: "paid"
                },
                {
                    key:3,
                    text: "No pagado",
                    value: "notPaid"
                }
            ]
        }

    }

    
    componentDidMount() {

        this.onGetData();
        this.unmounted = false;
        
    }

    componentWillUnmount() {

        this.unmounted = true;

    }

    onGetData = async () => {

        const { openLoader, closeLoader } = this.props;
        const { recordsPerPage, column } = this.state;

        let commissions = [];
        let indexedCommissions = {};
        let users = {};
        let commissionUserIds = [];
        let filteredCommissions = [];

        try {

            openLoader();

            const commissionsAdvisor = await PaymentModel.getCommissions('advisor');
            const commissionsAffiliation = await PaymentModel.getCommissions('affiliation');
            const commissionsAffiliationAsPretor = await PaymentModel.getCommissions('affiliation-as-pretor');
            const commissionsAffiliationAsPretorian = await PaymentModel.getCommissions('affiliation-as-pretorian');

            //concatenar todos los tipos de comisiones
            commissions = [...commissions, ...commissionsAdvisor.data, ...commissionsAffiliation.data, ...commissionsAffiliationAsPretor.data, ...commissionsAffiliationAsPretorian.data];
            
            //por defecto se filtra por comisiones no pagadas
            filteredCommissions = _.filter(commissions, commission => !commission.paid);

            //indexar commisiones para saber cual está seleccionada o no
            indexedCommissions = _.reduce(commissions, (acc, el) => ({...acc, [el._id] : { _id: el._id, selected: false, amount: el.amount, currency: el.currency, paid: el.paid }}), {})
            
            //sacar arrays de userIds sin duplicados
            commissionUserIds = [...new Set(_.map(commissions, commission => commission.userId))];
            
            //obtener usuarios asociados a las comisiones
            const usersFetch = await SecurityModel.getUsersByIds(commissionUserIds);

            //indexar usuarios para dado un hash de userId obtener su nombre, email
            users = _.reduce(usersFetch.data, (acc, el) => ({...acc, [el._id]: { fullname: [el?.suraname || 'Sin apellido', el?.name || 'Sin nombre'].join(", "), _id: el._id, email: el.email}}), {}); 

            //avoid setState on umounted component
            if (!this.unmounted) {

                this.setState({
                    commissions,
                    users,
                    filteredCommissions: _.orderBy(filteredCommissions, [column], ['desc']),
                    indexedCommissions,
                    pageSize: Math.ceil(filteredCommissions.length / recordsPerPage)
                });

            }

        } catch (error) {
            

        } finally {

            closeLoader();

        }

    }

    onFilter = (selectedType, name) => this.setState({[name]:selectedType}, () => this.onFilterCompose([...this.state.commissions]));

    onFilterCompose = commisions => {

        const { selectedType, selectedConcept, selectedPay, recordsPerPage } = this.state;

        const filterType = data => _.filter(data, commission => selectedType !== 'all' ? commission.type === selectedType : commission);

        const filterConcept = data => _.filter(data, commission => selectedConcept !== 'all' ? commission.concept === selectedConcept : commission);

        const filterPayed = data => _.filter(data, commission => selectedPay !== 'all' ? selectedPay === 'paid' ? commission.paid : !commission.paid : commission);

        //Ejecutamos los funciones de filtros de derecha izquierda para tener filtros correlativos , el resultado de un filtro es filtrado por otro ..(composition pattern)
        let filteredData = _.reduceRight([filterPayed, filterConcept, filterType], (c, fn) => fn(c), commisions);

        this.setState({filteredCommissions: filteredData, pageSize: Math.ceil(filteredData.length / recordsPerPage), pageNumber: 1});

    }

    paginate = () => this.state.filteredCommissions.slice((this.state.pageNumber - 1) * this.state.recordsPerPage, this.state.pageNumber * this.state.recordsPerPage);

    onSelectCommission = (id, checked) => {

        this.setState((prev) => ({
            
            indexedCommissions : {
                ...prev.indexedCommissions,
                [id]: {...prev.indexedCommissions[id], selected: checked }
            }

        }));

    }

    onPageChange = (e, pageInfo) => this.setState({ pageNumber: pageInfo.activePage });

    handleSort = clickedColumn => () => {

        const { column, filteredCommissions, direction } = this.state;

        if (column !== clickedColumn) {

            this.setState({
                column: clickedColumn,
                filteredCommissions: clickedColumn === 'createdAt' ? filteredCommissions.sort((a, b) => Moment(a[clickedColumn]).diff(b[clickedColumn])) : _.sortBy(filteredCommissions, [clickedColumn]),
                direction: 'ascending',
            })

            return;

        }

        this.setState({
            filteredCommissions: filteredCommissions.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending'
        });

    }


    onRenderTable = () => {

        const { filteredCommissions, users, indexedCommissions, column, direction, recordsPerPage} = this.state;

        return (
            <Table celled selectable sortable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Seleccionar</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('createdAt') } sorted={ column === 'createdAt' ? direction : null }>Fecha</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('type') } sorted={ column === 'type' ? direction : null }>Tipo</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('concept') } sorted={ column === 'concept' ? direction : null }>Concepto</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('currency') } sorted={ column === 'currency' ? direction : null }>Moneda</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('amount') } sorted={ column === 'amount' ? direction : null }>Cantidad</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('paid') } sorted={ column === 'paid' ? direction : null }>Pagado</Table.HeaderCell>
                        <Table.HeaderCell>Beneficiario</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    { (this.paginate() || []).map(commission => (
                        <Table.Row key={ commission._id }>
                           <Table.Cell collapsing>
                                <Checkbox
                                    onClick={ (e, v) => { this.onSelectCommission(commission._id, v.checked) } }
                                    checked={ indexedCommissions[commission._id].selected } />
                            </Table.Cell>
                            <Table.Cell >{ commission.createdAt ? Moment(commission.createdAt).format('DD/MM/YYYY HH:mm:ss') : I18n.t('user.info.noCreatedAt') }</Table.Cell>
                            <Table.Cell >{ types[commission.type] }</Table.Cell>
                            <Table.Cell >{ concepts[commission.concept] }</Table.Cell>
                            <Table.Cell >{ commission.currency }</Table.Cell>
                            <Table.Cell >{ commission.amount }</Table.Cell>
                            <Table.Cell >{ commission.paid ? 'Pagado' : 'No pagado' }</Table.Cell>
                            <Table.Cell>{ users[commission.userId]?.fullname }</Table.Cell>
                        </Table.Row>
                    )) }
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan={ 8 }>
                            <Menu floated='right'>
                                <Pagination
                                    onPageChange={ this.onPageChange }
                                    boundaryRange={ 1 }
                                    defaultActivePage={ 1 }
                                    prevItem={ null }
                                    nextItem={ null }
                                    siblingRange={ 2 }
                                    totalPages={ Math.ceil(filteredCommissions.length / recordsPerPage) }
                                />
                            </Menu>
                            <div style={{ clear: "both" }}></div>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        );

    }

    render() {

        const { selectedType, selectedConcept, selectedPay, typeOptions, conceptOptions, payOptions, indexedCommissions } = this.state;

        const totalEur = _.reduce(indexedCommissions, (acc, el) => el.selected && el.currency === 'EUR' ? acc + el.amount : acc, 0);
        const totalUsd = _.reduce(indexedCommissions, (acc, el) => el.selected && el.currency === 'USD' ? acc + el.amount : acc, 0);
    
        const totalRemainingEur = _.reduce(indexedCommissions, (acc, el) => !el.paid && el.currency === 'EUR' ? acc + el.amount : acc, 0);
        const totalRemainingUsd = _.reduce(indexedCommissions, (acc, el) => !el.paid && el.currency === 'USD' ? acc + el.amount : acc, 0);

        const totalPaidEur = _.reduce(indexedCommissions, (acc, el) => el.paid && el.currency === 'EUR' ? acc + el.amount : acc, 0);
        const totalPaidUsd = _.reduce(indexedCommissions, (acc, el) => el.paid && el.currency === 'USD' ? acc + el.amount : acc, 0);

        return (
            <Segment style={{minHeight:'100vh'}}>
                <Header as='h3'>Comisiones</Header>
                <div style={{display:'flex', justifyContent: "space-between", alignItems: "center"}}>
                    <Form>
                        <Form.Group inline>
                            <Form.Field>
                                <label>Tipos</label>
                                <Select value={selectedType} name="selectedType" onChange={(e, { value, name }) => this.onFilter(value, name) } options={typeOptions} />
                            </Form.Field>
                            <Form.Field>
                                <label>Concepto</label>
                                <Select value={selectedConcept} name="selectedConcept" onChange={(e, { value, name }) => this.onFilter(value, name) } options={conceptOptions} />
                            </Form.Field>
                            <Form.Field>
                                <label>Pagados</label>
                                <Select value={selectedPay} name="selectedPay" onChange={(e, { value, name }) => this.onFilter(value, name) } options={payOptions} />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                    <div>
                        <p><strong>TOTAL PENDIENTE : {totalRemainingEur.toFixed(2)}€, {totalRemainingUsd.toFixed(2)}$</strong></p>
                        <p><strong>TOTAL SELECCIONADO : {totalEur.toFixed(2)}€, {totalUsd.toFixed(2)}$</strong></p>
                        <p><strong>TOTAL PAGADO : {totalPaidEur.toFixed(2)}€, {totalPaidUsd.toFixed(2)}$</strong></p>
                    </div>
                </div>
                { this.onRenderTable() }
            </Segment>
        )
    }
}

export default connect(null, {openLoader, closeLoader})(Commissions)