import _ from 'lodash';

import { AxiosService } from '../../../utils/AxiosService';
import Config from '../../../config';


const getFaqs = async () => await AxiosService.get(Config.server.api.questionApi  + 'faq', {}, !!window.localStorage.getItem('token'));
const createFaq = async payload => await AxiosService.post(Config.server.api.questionApi  + `faq`, {...payload}, !!window.localStorage.getItem('token'));
const updateFaq = async payload => await AxiosService.put(Config.server.api.questionApi  + `faq/${payload._id}`, _.omit(payload, ['_id','created', 'updated']), !!window.localStorage.getItem('token'));
const deleteFaq = async faqId => await AxiosService.deleting(Config.server.api.questionApi  + `faq/${faqId}`, !!window.localStorage.getItem('token'));

export default {
    getFaqs,
    deleteFaq,
    updateFaq,
    createFaq
}