import _ from 'lodash';
import { connect } from 'react-redux';
import React from 'react';
import { Segment, Header, Form, Input, Button, List} from 'semantic-ui-react';
import './category.scss';
import QuestionModel from '../../../data/models/question/question';
import { openLoader, closeLoader } from '../../../redux-store/loader';
import I18n from '../../../i18n';

class Categories extends React.Component {

    constructor(props) {
        super(props);
        this.formFields = {}
        this.state = {
            categories: [],
            loaded: false,
            activeForm: false,
            label: ''
        }

    }

     componentDidMount() {

        this.getData();

    }

    getData = async () => {

        try {

            this.props.openLoader()
            const categories = await QuestionModel.getCategories();
            this.setState({ categories: categories.data || []});

        } catch (error) {

            console.error('error', error);

        } finally {

            this.props.closeLoader();
            this.setState({ loaded: true });

        }

    }

    handleSubmit = async e => {

        e.preventDefault();

        await QuestionModel.createCategory({label: this.state.label});

        try {

            this.props.openLoader();

        } catch (error) {

            console.error('error', error)

        } finally {

            this.props.closeLoader();
            this.onReset();
            this.getData();

        }

    }

    onAddCategory = () => {

        this.setState({ activeForm: true });
    }

    onChange = e => {

        this.setState({ label: e.target.value });

    }

    onReset = () => {

        this.setState({ label: '', activeForm: false });

    }

    render() {

        return (
            <>
                <Segment>
                    <Header as='h3'>{I18n.t('categories.categoryList')}</Header>
                    <List>
                        {_.sortBy(this.state.categories, ['label']).map(category => (
                            <List.Item key={category._id}>{category.label}</List.Item>
                        ))}
                    </List>
                    <Button type='button' floated='right' primary onClick={this.onAddCategory}>{I18n.t('categories.addCategory')}</Button>
                    <div style={{clear:"both"}}></div>
                </Segment>
                {this.state.activeForm &&
                    <Segment>
                        <Form noValidate onSubmit={this.handleSubmit}>
                            <Form.Field>
                                <label>{I18n.t('categories.category')}</label>
                                <Input name="label" placeholder={I18n.t('categories.category')} value={this.state.label} onChange={this.onChange}/>
                            </Form.Field>
                            <Button type='button' onClick={this.onReset}>{I18n.t('actions.cancel')}</Button>
                            <Button type='submit' disabled={!this.state.label} primary>{I18n.t('actions.save')}</Button>
                        </Form>
                    </Segment>
                }
            </>
        );

    }

}

export default connect(null, { openLoader, closeLoader })(Categories);