import React, { Component } from 'react'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Form, Label } from 'semantic-ui-react';

class Wysiwyg extends Component {

    render() {
        
        return (
            <React.Fragment>
                <Form.Field>
                    <label>{ this.props.label }</label>
                    <CKEditor
                        editor={ClassicEditor}
                        data={this.props.input.value || ''}
                        onChange={ (event, editor) => this.props.input.onChange(editor.getData()) }
                        config={ {
                            toolbar: [
                                'bold', 'italic', 'undo', 'redo'
                            ]
                        }}
                        />
                    { this.props.meta.touched && this.props.meta.invalid && <Label basic color={ this.props.colorLabel || 'red' } pointing>{ this.props.meta.error }</Label> }
                </Form.Field>
            </React.Fragment>
        )
    }
}

export default Wysiwyg 
