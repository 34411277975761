const defaultStudioAsana = 

    {
        title: "",
        subtitle: "",
        imageUrl: "",
        videoUrl: "",
        flexoextension: "",
        lateralization: "",
        abduction: "",
        muscleTrain: "",
        relatedProducts: [],
        duration: "0",
        published: false,
        type: "asanas",
        prices: {
            premium: {
                EUR: 0,
                USD: 0
            },
            lite: {
                EUR: 0,
                USD: 0
            }
        },
        hasDiscount: false,
        discount: '5',
        discountExpiration: null
        
    }


export  {
    defaultStudioAsana
}