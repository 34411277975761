import { AxiosService } from '../../../utils/AxiosService';
import Config from '../../../config';

const getProducts = async () => await AxiosService.get(Config.server.api.schoolApi + 'product', {}, true);
const getProduct = async productId => await AxiosService.get(Config.server.api.schoolApi + `admin/product/${productId}`, {}, true);
const getUserProducts = async userId => await AxiosService.get(Config.server.api.schoolApi + `admin/user/${userId}/product`, {}, true);
const linkUserProduct = async (userId, payload) => await AxiosService.post(Config.server.api.schoolApi + `admin/user/${userId}/product`, payload, true);

const createProduct = async payload => await AxiosService.post(Config.server.api.schoolApi + 'admin/product', payload, true);
const editProduct = async (productId, payload) => await AxiosService.put(Config.server.api.schoolApi + `admin/product/${productId}`, payload, true);
const deleteProduct = async productId => await AxiosService.deleting(Config.server.api.schoolApi + `admin/product/${productId}`, true);
const publishProduct = async productId => await AxiosService.put(Config.server.api.schoolApi + `admin/product/${productId}/publish`, {}, true);

const applyGlobalDiscountToProducts = async payload => await AxiosService.put(Config.server.api.schoolApi + `admin/product`, payload, true);

export default {
    createProduct,
    deleteProduct,
    editProduct,
    getProducts,
    getProduct,
    getUserProducts,
    publishProduct,
    linkUserProduct,
    applyGlobalDiscountToProducts
}