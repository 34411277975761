import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Segment, Header, Table } from 'semantic-ui-react'
import ProgressModel from '../../../../../../data/models/progress/progress'
import { openLoader, closeLoader } from '../../../../../../redux-store/loader'
import { closeModal, openModal } from '../../../../../../redux-store/modal'
import I18n from '../../../../../../i18n'
import moment from 'moment'

class UserDiagnosis extends Component {
    constructor(props) {
        super(props)

        this.state = {
            challenges: [],
            maxLengthExercises:0
        }
    }

    componentDidMount() {

        this.getUserProgress();

    }
    
    getUserProgress = async () => {

        let messageError = {
            headerTitle : "Error",
            title : I18n.t('messages.modal.commonError'),
            buttons : [
                
                {
                    text : I18n.t('actions.accept'),
                    callback : () => this.props.closeModal()
                
                }

            ]
        };

        try {

            const response = await ProgressModel.getUserProgress(this.props.userid);

            if (response.data) {
                
                let maxLengthExercises = [];

                _.each(response.data.challenges, ({exercises}) => {

                    maxLengthExercises.push(exercises.length)
                    
                });

                this.setState({ challenges: response.data.challenges, maxLengthExercises: Math.max(...maxLengthExercises) });

            }
            
        } catch (error) {
            
            this.props.closeLoader();
            this.props.openModal(messageError);

        } finally {

            this.props.closeLoader();

        }

    }
    
    render() {

        const { challenges, maxLengthExercises } = this.state;

        return challenges && (
            <React.Fragment>
                <Segment>
                    <Header as="h3">{I18n.t('user.info.challengeList')}</Header>
                    <Table celled structured>
                        <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell rowSpan='2'>{I18n.t('user.info.challenges')}</Table.HeaderCell>
                            <Table.HeaderCell colSpan={maxLengthExercises}>{I18n.t('user.info.exercises')}</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            { _.times(maxLengthExercises).map( (index) => (
                                <Table.HeaderCell key={index}> { index + 1 }</Table.HeaderCell>
                            ))}
                        </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            { (challenges).map(({ id, exercises }, indexChallenge) => (
                                <Table.Row key={id}>
                                    <Table.Cell>{I18n.t('user.info.challenge', { challenge: indexChallenge + 1})}</Table.Cell>
                                    { _.times(maxLengthExercises).map( (indexExercise) => (
                                        exercises[indexExercise] ? <Table.Cell key={indexExercise}> <span style={{textAlign: 'left'}}>{exercises[indexExercise].completedAt ? moment(exercises[indexExercise].completedAt).format('DD/MM/YYYY HH:mm:ss') : 'Sin completar'}</span><b className="cbrand" style={{float: 'right'}}>{(exercises[indexExercise].value)}</b></Table.Cell> : <Table.Cell key={indexExercise} warning></Table.Cell>
                                    ))}
                                
                            </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Segment>
            </React.Fragment>
        )
    }
}

export default connect(null, { closeLoader, openLoader, closeModal, openModal })(UserDiagnosis)
