import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dimmer } from 'semantic-ui-react';
import loaderImage from '../../assets/img/loader.svg';

class Yloader extends React.Component {

    render() {

        return this.props.open ? (
            <Dimmer active={true} >
                <img src={loaderImage} alt="loader" />
            </Dimmer>
        ) : '';

    }

}

const mapStateToProps = state => _.cloneDeep(state.loader);

export default connect(mapStateToProps)(Yloader);