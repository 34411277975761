import React, { Component } from 'react';
import { Form, Label } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';

class CustomDatePicker extends Component {

    render() {

        return (
            <Form.Field inline={ this.props.inline || false } className={ this.props.fieldClasses } error={ this.props.meta.touched && this.props.meta.invalid }>
                { this.props.label && <label style={{ opacity: (this.props.disabled ?  '0.3': '1') }}>{ this.props.label }</label> }
                    <SemanticDatepicker
                        onChange={ (e, data) => this.props.input.onChange(data.value) }
                        value={ this.props.input.value ? new Date(this.props.input.value) : this.props.input.value }
                        format={ this.props.formatDate }
                    />
                { this.props.meta.touched && this.props.meta.invalid && <Label basic color={ this.props.colorLabel || 'red' } pointing='left'>{ this.props.meta.error }</Label> }
            </Form.Field>
        );
    }
}

export default CustomDatePicker;