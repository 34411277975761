import _ from 'lodash'
import React, { Component } from 'react'
import LineGraphView from './view'
import Moment from 'moment';

class LineGraphContainer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            transformedData: []
        }
    }

    componentDidMount() {

        this.transformData();

    }

    componentDidUpdate(oldProps) {

        if (!_.isEqual(oldProps.data, this.props.data)) {

            this.transformData();

        }

    }

    transformData = () => {

        const { data } = this.props;

        let transformedData = [];

        transformedData = _.chain(data)
        .map(item => {
            return {
                ...item,
                momentDate: Moment(item.createdAt).startOf('day')
            }

        })
        .orderBy(['momentDate'],['asc'])
        .groupBy('momentDate')
        .map((value, key) => {
            return {
                "date": new Date(key),
                "value": value.length,
              }

        })
        .value();

        this.setState({transformedData});

    }

    render() {

        const { transformedData } = this.state;

        return (
            <LineGraphView data={transformedData} />
        )
    }
}
export default LineGraphContainer;