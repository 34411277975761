import { AxiosService } from '../../../utils/AxiosService';
import Config from '../../../config';

const getUsers = async () => await AxiosService.get(Config.server.api.securityApi + 'admin/user', {}, true);
const getUsersByIds = async ids => await AxiosService.post(Config.server.api.securityApi + 'admin/user', ids, true);
const getUsersWithNotificationTokens = async ids => await AxiosService.get(Config.server.api.securityApi + 'admin/user/notification/list', true);
const getUserAccess = async userId => await AxiosService.get(Config.server.api.securityApi + `admin/user/${userId}/access`, {}, true);
const getUserInfo = async userId => await AxiosService.get(Config.server.api.securityApi + `admin/user/${userId}`, {}, true);
const login = async payload => await AxiosService.post(Config.server.api.securityApi + 'admin/login', payload, false);
const removeUser = async userId => await AxiosService.deleting(Config.server.api.securityApi + `admin/user/${userId}/removeaccount`, true);
const sendNotifications = async payload => await AxiosService.post(Config.server.api.securityApi + 'admin/user/notification/send', payload, true);
const sendUserMessage = async (userId, payload) => await AxiosService.post(Config.server.api.securityApi + `admin/user/${userId}/email`, payload, true);
const updateRoles = async (userId, payload) => await AxiosService.put(Config.server.api.securityApi  + `admin/user/${userId}/role`, payload, true);

export default {
    getUsers,
    getUserAccess,
    getUserInfo,
    getUsersByIds,
    getUsersWithNotificationTokens,
    login,
    removeUser,
    sendUserMessage,
    sendNotifications,
    updateRoles
}