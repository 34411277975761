// Third party libraries
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { autofill, change, Field, reduxForm } from 'redux-form';
import { Button, Form, Header, Icon, Label, Segment, Select, Table } from 'semantic-ui-react';

// Components
import CustomInput from '../../../../../../../components/form/input';
import CustomTextArea from '../../../../../../../components/form/textarea';
import DefaultCheckBox from '../../../../../../../components/form/defaultCheckbox';

// Validations
import { required } from '../../../../../../../../config/validations';

// Models
import SessionModel from '../../../../../../../../data/models/sessions';

// Locales
import I18n from '../../../../../../../../i18n';

class AsanaForm extends Component {

    constructor(props) {

        super(props);

        this.state = {
            selectedAsanaValue: ''
        };

    }

    onSubmit = async submittedValues => {

        const { closeModal, openModal, openLoader,  closeLoader, match: { params: { asanaId }}} = this.props;

        let message = {
            headerTitle: "Error",
            title: I18n.t('messages.modal.commonError'),
            buttons: [{
                text: I18n.t('actions.accept'),
                callback: closeModal
            }]
        };

        try {

            openLoader();
            await SessionModel.editAsana(asanaId, submittedValues);
            this.props.history.push('/home/sequence/asanas');

        } catch (error) {

            closeLoader();
            openModal(message);

        } finally {

            closeLoader();

        }

    }

    onAsanaChangeValue = value => {

        this.setState({ selectedAsanaValue: value });

    }

    onAddAsana = () => {

        const { selectedAsana, dispatch } = this.props;
        const { selectedAsanaValue } = this.state;

        let tempSelectedAsana = _.cloneDeep(selectedAsana);
        tempSelectedAsana.compatibleAsanas.push(selectedAsanaValue);

        dispatch(autofill('asanaForm', 'compatibleAsanas', tempSelectedAsana.compatibleAsanas));

        //clear
        this.setState({ selectedAsanaValue: '' });

    }

    onRemoveAsana = idx => {

        let tempState = { ...this.props.selectedAsana };
        tempState.compatibleAsanas.splice(idx, 1);
        this.props.dispatch(change('asanaForm', 'compatibleAsanas', tempState.compatibleAsanas));

    }

    render() {

        const { selectedAsana, asanas, indexedAsanas } = this.props;
        const { selectedAsanaValue } = this.state;

        const asanaOptions = _.chain(asanas).filter(
            ({ _id, isBeginner, isAdvanced, isWithSupport }) =>
                selectedAsana._id !== _id &&
                selectedAsana.compatibleAsanas.includes(_id) &&
                    ((selectedAsana.isBeginner && isBeginner) ||
                    (selectedAsana.isAdvanced && isAdvanced) ||
                    (selectedAsana.isWithSupport && isWithSupport))
        ).map(item => {

            return {
                key: item._id,
                text: `[${ item.code }] ${ item.name.ES }`,
                value: item._id,
                image: {
                    avatar: true,
                    src: item.image,
                }
            };

        }).value();

        return (
            <Form noValidate onSubmit={ this.props.handleSubmit(this.onSubmit) } name="asanaForm">
                <Segment id="general">
                    <Header as='h3'>{ I18n.t('practice.general') }</Header>
                    <Form.Group widths='equal'>
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('practice.name') }
                            name="name.ES"
                            label={ I18n.t('practice.name') }
                            validate={ [required] }
                        />
                        <Field
                            component={ CustomTextArea }
                            placeholder={ I18n.t('practice.description') }
                            name="description.ES"
                            label={ I18n.t('practice.description') }
                            validate={ [required] }
                        />
                    </Form.Group>
                </Segment>
                <Segment>
                    <Header as='h3'>{ I18n.t('practice.resources') }</Header>
                    <Form.Group widths='equal'>
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('practice.urlImage') }
                            name="image"
                            label={ I18n.t('practice.urlImage') }
                            validate={ [required] }
                        />
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('practice.urlVideo') }
                            name="video"
                            label={ I18n.t('practice.urlVideo') }
                            validate={ [required] }
                        />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('practice.audioNameSES') }
                            name="nameAudio.SES"
                            label={ I18n.t('practice.audioNameSES') }
                            validate={ [required] }
                        />
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('practice.audioNameNES') }
                            name="nameAudio.NES"
                            label={ I18n.t('practice.audioNameNES') }
                            validate={ [required] }
                        />
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('practice.technicalAudio') }
                            name="technicalAudio.ES"
                            label={ I18n.t('practice.technicalAudio') }
                            validate={ [required] }
                        />
                    </Form.Group>
                </Segment>
                <Segment>
                    <Header as='h3'>{ I18n.t('practice.levelSingle') }</Header>
                    <Form.Field>
                        <Field
                            component={ DefaultCheckBox }
                            name="isBeginner"
                            label={ I18n.t('practice.isBeginner') }
                            toggle
                        />
                    </Form.Field>
                    <Form.Field>
                        <Field
                            component={ DefaultCheckBox }
                            name="isAdvanced"
                            label={ I18n.t('practice.isAdvanced') }
                            toggle
                        />
                    </Form.Field>
                    <Form.Field>
                        <Field
                            component={ DefaultCheckBox }
                            name="isWithSupport"
                            label={ I18n.t('practice.isWithSupport') }
                            toggle
                        />
                    </Form.Field>
                    { (!selectedAsana.isAdvanced && !selectedAsana.isBeginner && !selectedAsana.isWithSupport) && <Label basic color={'red' } pointing>{ I18n.t('validations.isAdvancedIsBeginnerIsWithSupportDiabled') }</Label> }
                </Segment>
                <Segment>
                    <Header as='h3'>{ I18n.t('practice.compatibleAsanas') }</Header>
                    <Form.Field>
                        <Select search options={ asanaOptions } value={ selectedAsanaValue } onChange={ (e, { value })=> this.onAsanaChangeValue(value) } />
                    </Form.Field>
                    <Button floated="right" type="button" size="tiny" default onClick={ this.onAddAsana }>{ I18n.t('actions.addAsana') }</Button>
                    <div style={{ clear: "both" }}></div>
                    { selectedAsana.compatibleAsanas.length > 0 && <Segment>
                    <Table celled selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell collapsing>
                                    { I18n.t('practice.thumb') }
                                </Table.HeaderCell>
                                <Table.HeaderCell>{ I18n.t('practice.code') }</Table.HeaderCell>
                                <Table.HeaderCell>
                                    { I18n.t("practice.name") }
                                </Table.HeaderCell>
                                <Table.HeaderCell>{ I18n.t("actions.remove") }</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            { (selectedAsana.compatibleAsanas || []).map((asanaId, idx) => (
                                <Table.Row key={asanaId}>
                                    <Table.Cell collapsing><img style={{ maxWidth: '140px', width: '100%' }} src={ indexedAsanas[asanaId].image } alt="" /></Table.Cell>
                                    <Table.Cell>{ indexedAsanas[asanaId].code }</Table.Cell>
                                    <Table.Cell>{ indexedAsanas[asanaId].name.ES }</Table.Cell>
                                    <Table.Cell style={{ textAlign: "center" }}>
                                        <Icon name="trash" size="large" onClick={ () => this.onRemoveAsana(idx) }></Icon>
                                    </Table.Cell>
                                </Table.Row>
                            )) }
                        </Table.Body>
                        <Table.Footer>
                    </Table.Footer>
                    </Table>
                </Segment> }
                </Segment>
                <Button disabled={ !selectedAsana.isAdvanced && !selectedAsana.isBeginner && !selectedAsana.isWithSupport } floated="right" type='submit' primary>{ I18n.t('actions.save') }</Button>
                <div style={{ clear: "both" }}></div>
            </Form>
        );

    }

}

AsanaForm = connect(state => {

    const selectedAsana = _.cloneDeep(_.get(state, 'form.asanaForm.values', {}));

    return {
        selectedAsana
    };

})(AsanaForm);

export default reduxForm({
    form: 'asanaForm',
    touchOnBlur: true,
    touchOnChange: true,
    enableReinitialize: true
})(connect(null, {})(AsanaForm));