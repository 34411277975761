import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment, Header, Table, Icon } from 'semantic-ui-react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import PaymentModel from '../../../data/models/payment/payment';
import SecurityModel from '../../../data/models/security/security';

import { openLoader, closeLoader } from '../../../redux-store/loader';
import { closeModal, openModal } from '../../../redux-store/modal';

import I18n from '../../../i18n';

import YGBIcon from '../../assets/img/ygb_icon.png';

class Payments extends Component {

    constructor(props) {

        super(props);

        this.state = {
            payments: [],
            originalData: [],
            column: 'createdAt',
            direction: 'descending',
            userNames: {},
            amountDollars: 0,
            amountEuros: 0
        };


    }

    componentDidMount() {

        this.onGetPaymentsAndUsers();

    }

    onGetPaymentsAndUsers = async () => {

        let message = {
            headerTitle: "Error",
            title: I18n.t('messages.modal.commonError'),
            buttons: [{
                text : I18n.t('actions.accept'),
                callback : () => this.props.closeModal()
            }]
        };

        try {

            this.props.openLoader();
            const payments = await PaymentModel.getPayments();
            const users = await SecurityModel.getUsers();
            if (payments.data && users.data) {

                let userNames = {};
                _.each(users.data, ({_id , username, surname }) => {

                    userNames[_id] = `${surname}, ${username}`

                });

                let amountDollars = 0;
                let amountEuros = 0;
                _.each(payments.data, ({ amount, currency })=> {

                    if (currency === 'USD') {

                        amountDollars+= amount;

                    } else {

                        amountEuros+= amount;

                    }

                });

                this.setState({ payments: _.orderBy(payments.data, ['createdAt'], ['desc']), originalData: payments.data, userNames, amountDollars, amountEuros });

            }

        } catch (error) {

            this.props.closeLoader();
            this.props.openModal(message);

        } finally {

            this.props.closeLoader();

        }

    }

    handleSort = clickedColumn => {

        const { column, direction, originalData } = this.state;

        if (column !== clickedColumn) {

            this.setState({
                column: clickedColumn,
                payments: _.orderBy(originalData, [clickedColumn], ['asc']),
                direction: 'ascending',
            });
            return;

        }

        this.setState({
            payments: _.orderBy(originalData, [clickedColumn], [direction === 'ascending' ? 'desc' : 'asc']),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        });

    }

    setPaymentMethod = (platform, last4 = null, brand = null) => {

        if (platform === 'stripe') {

            switch (brand) {

                case 'amex':
                case 'mastercard':
                case 'visa':

                    return <React.Fragment><span className={ `f-icon-cc-${ brand } icon-wallet` }></span><span>•••• •••• •••• { last4 }</span></React.Fragment>;

                default:

                    return <React.Fragment><span className="f-icon-credit-card icon-wallet"></span><span>•••• •••• •••• { last4 }</span></React.Fragment>;

            }

        } else if (platform === 'yogabot') {

            const styles = { marginLeft:'8px', display:'inline-block', position: 'relative', 'top': '-4px' };
            return <React.Fragment><img src={ YGBIcon } alt="ygb token" style={{ width: '20px' }} /><span style={ styles }>YGB</span></React.Fragment>

        } else if (platform === 'iOS') {

            return <Icon disabled name='apple' />

        } else if (platform === 'Android') {

            return <Icon disabled name='google play' />

        }

        return <span className="f-icon-cc-paypal icon-wallet"></span>;

    }


    setCurrencyAndAmount = (amount, currency) => {

        switch(currency) {

            case 'USD':

                return `$${amount}`;

            case 'EUR':

                return `${amount}€`;

            default:

                return '';

        }

    }

    downloadInvoice = (userId, _id) => {

        this.props.openLoader();
        PaymentModel.getUserPaymentInvoice(userId, _id).then(response => {

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Recibo.pdf');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            this.props.closeLoader();

        }).catch(error => {

            let message = {
                headerTitle: "Error",
                title: I18n.t('messages.modal.commonError'),
                buttons: [{
                    text: I18n.t('actions.accept'),
                    callback: () => this.props.closeModal()
                }]
            };
            this.props.closeLoader(message);
            console.error(error);

        });

    }

    render() {

        const  { payments, column, direction, userNames, amountEuros, amountDollars } = this.state;

        return payments && (
            <Segment>
                <Header as="h3">{I18n.t('payments.paymentList')}</Header>
                <p>{amountEuros.toFixed(2)}€ - ${amountDollars.toFixed(2)}</p>
                <Table celled selectable sortable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell onClick={ () => this.handleSort('receiptNumber') } sorted={column === 'receiptNumber' ? direction : null}>{I18n.t('user.info.receiptNumber')}</Table.HeaderCell>
                            <Table.HeaderCell onClick={ () => this.handleSort('orderId') } sorted={column === 'orderId' ? direction : null}>{ I18n.t('user.info.orderId') }</Table.HeaderCell>
                            <Table.HeaderCell>{ I18n.t('payments.userName') }</Table.HeaderCell>
                            <Table.HeaderCell onClick={ () => this.handleSort('createdAt') } sorted={column === 'createdAt' ? direction : null}>{ I18n.t('user.info.paymentDate') }</Table.HeaderCell>
                            <Table.HeaderCell onClick={ () => this.handleSort('description') } sorted={column === 'description' ? direction : null}>{ I18n.t('user.info.description') }</Table.HeaderCell>
                            <Table.HeaderCell>{ I18n.t('user.info.rangeService') }</Table.HeaderCell>
                            <Table.HeaderCell>{ I18n.t('user.info.paymentMethod') }</Table.HeaderCell>
                            <Table.HeaderCell onClick={ () => this.handleSort('amount') } sorted={column === 'amount' ? direction : null}>{ I18n.t('user.info.amount') }</Table.HeaderCell>
                            <Table.HeaderCell >{I18n.t('user.info.invoice')}</Table.HeaderCell>
                            <Table.HeaderCell >{ I18n.t('user.list.moreInfo') }</Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    { payments.map(({ userId, _id, orderId, createdAt, platform, amount, currency, last4, brand, startDate, endDate, description, receiptNumber }) => (
                        <Table.Row key={ _id } negative={amount<0}>
                            <Table.Cell>{ receiptNumber }</Table.Cell>
                            <Table.Cell>{ orderId }</Table.Cell>
                            <Table.Cell>{ userNames[userId] || ''}</Table.Cell>
                            <Table.Cell>{ moment(createdAt).format('DD/MM/YYYY HH:mm:ss') }</Table.Cell>
                            <Table.Cell>{ description }</Table.Cell>
                            <Table.Cell>{ moment(startDate).format('DD/MM/YYYY') + ' - ' + moment(endDate).format('DD/MM/YYYY')}</Table.Cell>
                            <Table.Cell>{ this.setPaymentMethod(platform, last4 , brand) }</Table.Cell>
                            <Table.Cell><span className="cbrand poppins-bold">{ this.setCurrencyAndAmount(amount, currency) }</span></Table.Cell>
                            <Table.Cell style={{textAlign: 'center'}}><span onClick={ () => this.downloadInvoice(userId, _id) }><i className="f-icon-descarga"></i></span></Table.Cell>
                            <Table.Cell style={{ textAlign: 'center' }}>
                                <Link to={{ pathname: `/home/user/info/${userId}` }} target="blank" ><Icon name="info"></Icon></Link>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table>
            </Segment>
        );

    }

}

export default connect(null, { openLoader, closeLoader, closeModal, openModal })(Payments);