import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Modal} from 'semantic-ui-react';
import CustomButton from '../../components/form/button';
import {closeModal} from '../../../redux-store/modal';
import './modal.scss';

class YModal extends React.Component {

    render() {

        return this.props.open && (
            <Modal open={ this.props.open } className="home-modal" size="tiny">
                <Modal.Header style={{color:'#fff'}}>{this.props.message.headerTitle || ''}</Modal.Header>
                <Modal.Content>
                    <div>{this.props.message.title}</div>
                </Modal.Content>
                <Modal.Actions>
                    {this.props.message.buttons && _.map(this.props.message.buttons || [], (button, index) => (
                        <CustomButton key={index} classes="default small no-shadow upper invert" text={ button.text } onClick={ button.callback } />
                    ))}
                </Modal.Actions>
            </Modal>
        );

    }

}

const mapStateToProps = state => _.cloneDeep(state.modal);

export default connect(mapStateToProps, {closeModal})(YModal);