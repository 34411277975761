import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Header, Segment, Image, Table, Icon, Button } from 'semantic-ui-react'
import { openLoader, closeLoader } from '../../../../../../redux-store/loader'
import { closeModal, openModal } from '../../../../../../redux-store/modal'
import SecurityModel from '../../../../../../data/models/security/security'
import I18n from '../../../../../../i18n'
import moment from 'moment'
import { countries } from '../../../list/countries';


const langs = {
    NES: I18n.t('user.info.nes'),
    SES: I18n.t('user.info.ses')
}

const practiceLevel = {
    '01': I18n.t('user.info.rookie'),
    '00': I18n.t('user.info.advanced')
}

const days = {

    0: I18n.t('user.info.sunday'),
    1: I18n.t('user.info.monday'),
    2: I18n.t('user.info.tuesday'),
    3: I18n.t('user.info.wednesday'),
    4: I18n.t('user.info.thursday'),
    5: I18n.t('user.info.friday'),
    6: I18n.t('user.info.saturday'),

}

const practicePresentationType = {

    video: I18n.t('user.info.video'),
    image: I18n.t('user.info.image')

}

class UserGeneralInfo extends Component {
    constructor(props) {
        super(props)

        this.state = {
            userInfo: {}
        }

    }

    componentDidMount(){

        this.onGetUserInfo();

    }

    componentWillUnmount() {

        this.props.closeLoader();
        this.props.closeModal();

    }

    onGetUserInfo = async () => {

        let message = {
            headerTitle : "Error",
            title : I18n.t('messages.modal.commonError'),
            buttons : [

                {
                    text : I18n.t('actions.accept'),
                    callback : () => this.props.closeModal()

                }

            ]
        };

        try {

            this.props.openLoader();
            const responseInfo = await SecurityModel.getUserInfo(this.props.userid);

            if (responseInfo.data) {

                this.setState({ userInfo: responseInfo.data});

            }

        } catch (error) {

            this.props.closeLoader();
            this.props.openModal(message);

        } finally {

            this.props.closeLoader();

        }

    }

    onRemoveUser = () => {

        const { openModal, closeModal } = this.props;
        const { userInfo } = this.state;

        let message = {
            headerTitle : "Error",
            title : I18n.t('messages.modal.removeUser', { username: userInfo?.name || ''}),
            buttons : [

                {
                    text : I18n.t('actions.cancel'),
                    callback : () => closeModal()

                },

                {
                    text : I18n.t('actions.remove'),
                    callback : () => {

                        closeModal();
                        this.removeUser();

                    }

                }

            ]
        };

        openModal(message);

    }


    removeUser = async () => {

        const { openLoader, closeLoader, closeModal, openModal, userid, history } = this.props;

        let message = {
            headerTitle : "Error",
            title : I18n.t('messages.modal.commonError'),
            buttons : [

                {
                    text : I18n.t('actions.accept'),
                    callback : () => closeModal()

                }

            ]
        };

        try {

            openLoader();
            await SecurityModel.removeUser(userid);
            history.push('/home/user/list');

        } catch (error) {

            closeLoader();
            openModal(message);

        } finally {

            closeLoader();

        }

    }

    render() {

        const  { userInfo, userInfo: { config, about, name, surname, pictureUrl, city, email, nickname, createdAt, role, gender, country, emailVerified, attributes, timetable, ssmm } } = this.state;
        return !_.isEmpty(userInfo) && (
            <React.Fragment>
                <Segment>
                    <Header as="h3">{ pictureUrl && <Image inline circular src={pictureUrl} size='mini' />}{surname}, {name}</Header>
                    <p>{email}</p>
                    {city && <p>{I18n.t('user.info.city')}: {city}</p>}
                    <p>{I18n.t('user.info.nickname')}: {nickname } </p>
                    <p>{I18n.t('user.info.registerDate')}: {moment(createdAt).format('DD/MM/YYYY HH:mm:ss')}</p>
                    <p>{I18n.t('user.info.roles')}:  { role && role.join(', ') }</p>
                    <p>{I18n.t('user.info.gender')}: { gender === 1 ? 'Masculino' : 'Femenino' }</p>
                    <p>{I18n.t('user.info.country')}: { countries[country] || 'País sin definir' }</p>
                    <p>{I18n.t('user.info.verifiedEmail')}: {emailVerified ? 'SI' : 'NO' }</p>
                    <p>{I18n.t('user.info.googleLinked')}: { _.get(attributes, 'google', false) ? 'SI' : 'NO' }</p>
                    <p>{I18n.t('user.info.facebookLinked')}: { _.get(attributes, 'facebook', false) ? 'SI' : 'NO' }</p>
                    <p><Icon name="facebook" />{I18n.t('user.info.facebookUrl')}: { _.get(ssmm,'facebook', '') }</p>
                    <p><Icon name="instagram" /> {I18n.t('user.info.instagramUrl')}: { _.get(ssmm,'instagram','') }</p>
                    {ssmm?.web && <p><Icon name="globe" /> {I18n.t('user.info.web')}: { _.get(ssmm,'web', '') }</p>}
                    <p>{I18n.t('user.info.aboutMe')}: {about}</p>
                    <div>
                        <Button floated="right" content={I18n.t('actions.removeUser')} icon='remove' labelPosition='right' size="mini" type="button" onClick={this.onRemoveUser} />
                        <div style={{ clear: "both" }}></div>
                    </div>
                </Segment>
                <Segment>
                    <Header as="h3">{I18n.t('user.info.userConfig')}</Header>
                    <p>{I18n.t('user.info.coinPayment')}: {config.currency}</p>
                    <p>{I18n.t('user.info.practiceLevel')}: {practiceLevel[config.practiceLevel]}</p>
                    <p>{I18n.t('user.info.practicePresentationType')}: {practicePresentationType[config.practicePresentationType]}</p>
                    <p>{I18n.t('user.info.practiceAudioLanguage')}: {langs[config.practiceAudioLanguage]}</p>
                </Segment>
                <Segment>
                    <Header as="h3">{I18n.t('user.info.schedule')}</Header>
                    <Table celled selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{ I18n.t('user.info.day') }</Table.HeaderCell>
                            <Table.HeaderCell>{ I18n.t('user.info.ini') }</Table.HeaderCell>
                            <Table.HeaderCell>{ I18n.t('user.info.end') }</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        { (timetable || []).map((time, index ) => (
                            <Table.Row key={index}>
                                <Table.Cell >
                                    {days[time.dayOfWeek]}
                                </Table.Cell>
                                <Table.Cell>{moment(time.ini).format('HH:mm')}</Table.Cell>
                                <Table.Cell>{moment(time.end).format('HH:mm')}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
                </Segment>
            </React.Fragment>
        )
    }
}

export default connect(null, {openLoader, closeLoader, closeModal, openModal })(UserGeneralInfo);