import React, { Component } from 'react'
import _ from 'lodash';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

 class PieGraphView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: `piegraph#${Math.floor(Math.random() * 100)}`
        }

    }

    componentWillUnmount() {

        if (this.chart) {
            
            this.chart.dispose();

        }

    }
    
    componentDidUpdate(oldProps) {

        if (!_.isEqual(oldProps.data,this.props.data)) {

            this.chart.data = this.props.data;

        }

    }

    componentDidMount() {

        // Create chart instance
        const { id } = this.state;

        const { data = [] } = this.props;

        let chart = am4core.create(id , am4charts.PieChart);

        // Add data
        chart.data = data;
        chart.radius = am4core.percent(50);

        // Add and configure Series
        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "category";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 1;

        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;

        pieSeries.labels.template.maxWidth = 100;
        pieSeries.labels.template.wrap = true;
        pieSeries.alignLabels = false;

        this.chart = chart;

    }

    render() {

        const { id } = this.state;

        return (
            <>
                <div id={id} style={{width: '100%', height: '500px'}}></div>
            </>
        )
    }
}

export default PieGraphView;