import React, { Component } from 'react'
import StudioForm from './components/form'
import _ from 'lodash'
import { defaultStudioAsana } from '../../../../config/constants/studioAsana';
import { Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { openLoader, closeLoader } from '../../../../redux-store/loader'
import { closeModal, openModal } from '../../../../redux-store/modal'
import ProductModel from '../../../../data/models/product'
import I18n from '../../../../i18n';


class StudioEdit extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
            selectedAsana: {},
            relatedProductsOptions: [],
            asanas: []
        }

        this.errorMessage = {
            headerTitle: "Error",
            title: I18n.t('messages.modal.commonError'),
            buttons: [{
                text : I18n.t('actions.accept'),
                callback : () => this.props.closeModal()
            }]
        };

    }

    componentDidMount() {

        this.getAsanaStudio();

    }

    getAsanaStudio = async () => {

        try {

            this.props.openLoader();
            const products = await ProductModel.getProducts();

            if (this.props.match.params.asanaId) {

                const studioAsana = await ProductModel.getProduct(this.props.match.params.asanaId);
                this.setState({ asanas: products.data, selectedAsana: studioAsana.data, relatedProductsOptions: products.data.filter(({_id})=> _id !== this.props.match.params.asanaId).map((item)=> {
                    return {
                        label: item.title, 
                        _id: item._id
                    }
                } )});

            } else {

                this.setState({ asanas: products.data, selectedAsana: _.cloneDeep(defaultStudioAsana), relatedProductsOptions: products.data.map((item)=> {
                    return {
                        label: item.title,
                        _id: item._id
                    }
                })});

            }
            
        } catch (error) {
            
            this.props.openModal(this.errorMessage);

        } finally {

            this.props.closeLoader();

        }
    }

    render() {

        const { selectedAsana, relatedProductsOptions, asanas } = this.state;

        return (
            <Segment>
                <StudioForm 
                    initialValues={selectedAsana}
                    relatedProductsOptions={relatedProductsOptions}
                    published={selectedAsana.published}
                    asanas={asanas}
                    {...this.props}
                />
            </Segment>
        )
    }
}

export default connect(null, {openLoader, closeLoader, closeModal, openModal} )(StudioEdit)
