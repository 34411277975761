import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Header, Segment, Table, Select, Checkbox, Button } from 'semantic-ui-react'
import { openLoader, closeLoader } from '../../../../../../redux-store/loader'
import { closeModal, openModal } from '../../../../../../redux-store/modal'
import ProductsModel from '../../../../../../data/models/product'
import I18n from '../../../../../../i18n'

class UserProducts extends Component {
    constructor(props) {
        super(props)

        this.state = {
            userProducts: [],
            products:[],
            filter: 'linked',
            checkedProducts: [],
            allSelected: false,
            options: [
                {
                    key: 1,
                    text: 'Vinculados',
                    value: 'linked'
                },
                {
                    key: 2,
                    text: 'No vinculados',
                    value: 'notLinked'
                }
            ]
        }

        this.errorMessage = {
            headerTitle : "Error",
            title : I18n.t('messages.modal.commonError'),
            buttons : [
                
                {
                    text : I18n.t('actions.accept'),
                    callback : () => this.props.closeModal()
                
                }

            ]
        };

    }

    componentDidMount() {

        this.onGetData();

    }

    onGetData = async () => {

        try {
           
            this.props.openLoader();
            const userProducts = await ProductsModel.getUserProducts(this.props.userid);
            const products = await ProductsModel.getProducts();
            this.setState({ userProducts: userProducts.data, products: products.data });

        } catch (error) {
            
            this.props.closeLoader();
            this.props.openModal(this.errorMessage);

        } finally {

            this.props.closeLoader();

        }

    }

    linkProductsToUser = async productIds => {

        try {
           
            this.props.openLoader();
            await ProductsModel.linkUserProduct(this.props.userid, productIds);
            this.setState({checkedProducts: []});

        } catch (error) {
            
            this.props.closeLoader();
            this.props.openModal(this.errorMessage);

        } finally {

            this.props.closeLoader();
            this.onGetData();

        }
        
    }

    onFilterChange = filter => this.setState({filter});

    onLink = (id, checked) => {

        let checkedProducts = _.cloneDeep(this.state.checkedProducts);
        let selectedProductIndex = _.findIndex(checkedProducts, item => item === id);
        checked ? checkedProducts.push(id) : checkedProducts.splice(selectedProductIndex, 1)
        this.setState({checkedProducts});

    }

    onToggleSelect = checked => {

        const { checkedProducts, userProducts, products } = this.state;
        const notLinkedProducts = _.chain(products).filter(({_id}) => _.findIndex(userProducts, ({productId})=> productId === _id ) === -1 ).map(product => product._id).value();
        let tempCheckedProducts = [];

        if (checked) {

            tempCheckedProducts = _.uniq([...notLinkedProducts, ...checkedProducts]);

        } 

        this.setState(prevState => (
            {
                 ...prevState,
                 allSelected : !prevState.allSelected,
                 checkedProducts: tempCheckedProducts
            }

        ))

    }

    render() {

        const  { userProducts, products, filter, options, checkedProducts, allSelected } = this.state;
        const productsMap = _.filter(products, ({_id}) => filter === 'linked' ? _.findIndex(userProducts, ({productId})=> productId === _id )>-1 :  _.findIndex(userProducts, ({productId})=> productId === _id ) ===-1)
        
        return (
            <React.Fragment>
                <Segment>
                    <Header as="h3">{I18n.t('studio.productList')}</Header>
                    <Select options={options} value={filter} onChange={(e, {value})=> this.onFilterChange(value)} />
                    {(checkedProducts.length > 0 && filter === 'notLinked') && <Button type='button' floated='right' primary onClick={()=> this.linkProductsToUser(checkedProducts)}>{I18n.t('studio.linkProductsSelected')}</Button>}
                    <div style={{ clear: "both" }}></div>
                </Segment>
                <Segment>
                    <Table celled selectable sortable>
                    <Table.Header>
                        <Table.Row>
                            {filter === 'notLinked' && <Table.HeaderCell>
                                    <Checkbox
                                        label="Todos" 
                                        onClick={ (e, v) => { this.onToggleSelect(v.checked) } }
                                        checked={ allSelected } />
                                </Table.HeaderCell>}
                            <Table.HeaderCell>{ I18n.t('studio.image') }</Table.HeaderCell>
                            <Table.HeaderCell>{ I18n.t('studio.title') }</Table.HeaderCell>
                            <Table.HeaderCell>{ I18n.t('studio.subtitle') }</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        { (productsMap || []).map(product => (
                            <Table.Row key={product._id}>
                                {filter === 'notLinked' && <Table.Cell>
                                    <Checkbox 
                                        onClick={ (e, v) => { this.onLink(product._id, v.checked) } }
                                        checked={ _.findIndex(checkedProducts, item => item === product._id ) > -1 } />
                                </Table.Cell>}
                                <Table.Cell collapsing>
                                    <img src={product.imageUrl} alt="" style={{width: '150px'}}/>
                                </Table.Cell>
                                <Table.Cell>{product.title}</Table.Cell>
                                <Table.Cell>{product.subtitle}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
                </Segment>
            </React.Fragment>
        )
    }
}

export default connect(null, {openLoader, closeLoader, closeModal, openModal })(UserProducts);