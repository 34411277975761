import _ from 'lodash';
import { connect } from 'react-redux';
import React from 'react';
import { Segment, Icon, Header, Form, Button, TextArea, Table, Select} from 'semantic-ui-react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { openLoader, closeLoader } from '../../../redux-store/loader';
import FaqModel from '../../../data/models/faq/faq';
import CategoryModel from '../../../data/models/question/question';
import I18n from '../../../i18n';
import { openModal , closeModal } from '../../../redux-store/modal';
import './faq.scss';

class Faq extends React.Component {

    constructor(props) {
        super(props);
        this.formFields = {

        };
        this.state = {
            formFaqVisible: false,
            isEdit : false,
            categories: [],
            indexedCategories : {},
            column: null,
            direction: null,
            formFields : {

                category: '',
                title : '',
                text: ''

            },
            active: -1,
            faqs: []
        }

    }

    componentDidMount() {

        this.getData();

    }

    getData = async () => {

        try {

            this.props.openLoader()
            const faqs = await FaqModel.getFaqs();
            const categories = await CategoryModel.getCategories();
            const indexedCategories = await this.indexCategories(categories.data);

            _.each(faqs.data, (faq, index) => {

                faqs.data[index].categoryName = indexedCategories[faq.category] || 'Categoría sin definir'

            });

            let mappedToOptions = _.map(categories.data || [], category => {

                return {
                    key : category._id,
                    text: category.label,
                    value: category._id
                }

            });

            this.setState({ faqs: faqs.data, categories: mappedToOptions, indexedCategories });

        } catch (error) {


        } finally {

            this.props.closeLoader();

        }

    }

    onSelectItem = index => {

        this.setState({ active : index === this.state.active ? -1 : index} );

    }

    onAddFaq = () => {

        let tempField = {
            category: this.state.categories[0].value,
            title : '',
            text: ''
        }

        this.formFields = _.clone(tempField);
        this.setState({formFaqVisible : true, formFields: {...tempField}});

    }

    onEditFaq = faqId => {

       let selectedFaq =  _.find(this.state.faqs, faq => (faq._id === faqId));
       this.formFields = _.clone(selectedFaq);

       this.setState({isEdit: true, formFaqVisible : true, formFields: {...selectedFaq} });

    }

    onRemoveFaq = faqId => {

        let self = this;

        let message = {

            title : I18n.t('messages.modal.removeFaq'),
            buttons : [

                {
                    text : I18n.t('actions.accept'),
                    callback : async () => self.removeFaq(faqId)

                },
                {
                    text : I18n.t('actions.cancel'),
                    callback : () => self.props.closeModal()

                }

            ]
        }

        this.props.openModal(message);

    }

    removeFaq = async faqId => {

        try {

            await FaqModel.deleteFaq(faqId);

        } catch (error) {

            console.error('faq error', error);

        } finally {

            this.getData();
            this.props.closeModal();

        }

    }

    onChange = e => {

        this.formFields[e.target.name] = e.target.value;

        this.setState({formFields: {...this.formFields} });

    }

    onChangeCategory = (value, name) => {

        this.formFields[name] = value;

        this.setState({formFields: {...this.formFields} });

    }

    onchangeText = (value, name) => {

        this.formFields[name] = value;
        this.setState({formFields: {...this.formFields} });

    }

    onReset = () => {

        let defaultForm  = {

            category: '',
            title : '',
            text: ''

        }

        this.formFields = _.clone(defaultForm);

        this.setState({ formFaqVisible: false, formFields: defaultForm, isEdit: false });

    }

    handleSubmit = async e => {

        e.preventDefault();

        if (this.state.isEdit) {

            try {

                await FaqModel.updateFaq(this.state.formFields);

            } catch (error) {

                console.error('faq error', error);

            } finally {

                this.onReset();
                this.getData();

            }

        } else {

            try {

                await FaqModel.createFaq(this.state.formFields);

            } catch (error) {

                console.error('faq error', error)

            } finally {

                this.onReset();
                this.getData();

            }

        }

    }

    handleSort = (clickedColumn) => () => {

        const { column, faqs, direction } = this.state

        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            faqs: _.sortBy(faqs, [clickedColumn]),
            direction: 'ascending',
          })

          return
        }

        this.setState({
          faqs: faqs.reverse(),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        });

      }

    indexCategories = async categories => {

        let ob = {};
        _.each(categories || [], category => {
            ob[category._id] = category.label
        });

        return ob;
    }

    onRenderTable = () => {

        const { column, direction } = this.state;

        return (

            <Table celled selectable sortable>
            <Table.Header>
            <Table.Row>
                <Table.HeaderCell onClick={this.handleSort('categoryName')} sorted={column === 'categoryName' ? direction : null}>{I18n.t('faq.category')}</Table.HeaderCell>
                <Table.HeaderCell onClick={this.handleSort('title')} sorted={column === 'title' ? direction : null}>{I18n.t('faq.title')}</Table.HeaderCell>
                <Table.HeaderCell>{I18n.t('actions.edit')}</Table.HeaderCell>
                <Table.HeaderCell>{I18n.t('actions.remove')}</Table.HeaderCell>
            </Table.Row>
            </Table.Header>
            <Table.Body>
            { this.state.faqs.map( faq => (
                <Table.Row key={faq._id}>
                    <Table.Cell>{faq.categoryName}</Table.Cell>
                    <Table.Cell>{faq.title}</Table.Cell>
                    <Table.Cell style={{textAlign: 'center'}}><Icon name="edit" size="large" onClick={()=> this.onEditFaq(faq._id)}></Icon></Table.Cell>
                    <Table.Cell style={{textAlign: 'center'}}><Icon name="eraser" size="large" onClick={()=> this.onRemoveFaq(faq._id)}></Icon></Table.Cell>
              </Table.Row>
            ))}
            </Table.Body>
            <Table.Footer fullWidth>
                <Table.Row>
                    <Table.HeaderCell colSpan='5'>
                        <Button type='button' floated='right' primary onClick={this.onAddFaq}>{I18n.t('faq.addFaq')}</Button>
                        <div style={{clear:"both"}}></div>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
        )
    }

    render() {

        return (
            <>
                {this.state.formFaqVisible && <Segment>
                    {this.state.isEdit ? <Header as='h3'>{I18n.t('faq.editFaq')}</Header> : <Header as='h3'>{I18n.t('faq.addFaq')}</Header>}
                    <Form noValidate onSubmit={this.handleSubmit}>
                        <Form.Field>
                            <label>{I18n.t('faq.title')}</label>
                            <TextArea name="title" placeholder={I18n.t('faq.title')} value={this.state.formFields.title} onChange={this.onChange}/>
                        </Form.Field>
                        <Form.Field>
                            <Select placeholder={I18n.t('faq.selectCategory')} value={this.state.formFields.category} name="category" options={this.state.categories} onChange={(e, { value, name }) => this.onChangeCategory(value, name)} />
                        </Form.Field>
                        <Form.Field>
                            <label>{I18n.t('faq.text')}</label>
                            <CKEditor
                                editor={ ClassicEditor }
                                data={ this.state.formFields.text || '' }
                                onChange={ (event, editor) => this.onchangeText(editor.getData(), 'text') }
                                config={{
                                    toolbar: [
                                        'heading', 'bold', 'italic','link','undo', 'redo'
                                    ]
                                }}
                            />
                        </Form.Field>
                        <Button type='button' onClick={this.onReset}>{I18n.t('actions.cancel')}</Button>
                        <Button type='submit' primary>{I18n.t('actions.save')}</Button>
                    </Form>
                </Segment>}
                <Segment>
                    {this.onRenderTable()}
                </Segment>
            </>
        );

    }

}

export default connect(null, { openLoader, closeLoader, openModal, closeModal })(Faq);