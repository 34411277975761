import { AxiosService } from '../../../utils/AxiosService';
import Config from '../../../config';

const createSequenceCategory = async payload => await AxiosService.post(Config.server.api.sessionApi + 'admin/sequenceCategories', payload, true);
const getSequenceCategories = async () => await AxiosService.get(Config.server.api.sessionApi + 'sequenceCategories', {}, true);
const editSequenceCategory = async (categoryId, payload) => await AxiosService.put(Config.server.api.sessionApi + `admin/sequenceCategories/${categoryId}`, payload, true);
const deleteSequenceCategory = async categoryId => await AxiosService.deleting(Config.server.api.sessionApi  + `admin/sequenceCategories/${categoryId}`, true);

const getAsanas = async () => await AxiosService.get(Config.server.api.sessionApi + 'admin/asana', {}, true);
const getAsana = async asanaId => await AxiosService.get(Config.server.api.sessionApi + `admin/asana/${asanaId}`, {}, true);
const editAsana = async (asanaId, payload) => await AxiosService.put(Config.server.api.sessionApi + `admin/asana/${asanaId}`, payload, true);

const getUserSessions = async userId => await AxiosService.get(Config.server.api.sessionApi + `admin/user/${userId}`, {}, true);

const getSequences = async () => await AxiosService.get(Config.server.api.sessionApi + 'admin/sequence', {}, true);
const getSequence = async (code, language) => await AxiosService.get(Config.server.api.sessionApi + `sequence/${ code }/${ language }`, {}, true);
const createSequence = async payload => await AxiosService.post(Config.server.api.sessionApi + 'admin/sequence', payload, true);
const editSequence = async (sequenceId, payload) => await AxiosService.put(Config.server.api.sessionApi + `admin/sequence/${sequenceId}`, payload, true);
const deleteSequence = async sequenceId => await AxiosService.deleting(Config.server.api.sessionApi + `admin/sequence/${sequenceId}`, true);

const setMainSequence = async sequenceId => await AxiosService.put(Config.server.api.sessionApi + `admin/sequence/${sequenceId}/main`, {}, true);
const cloneSequence = async sequenceId => await AxiosService.post(Config.server.api.sessionApi + `admin/sequence/${sequenceId}/cloneToOtherLevel`, {}, true);
const publishSequence = async sequenceId => await AxiosService.put(Config.server.api.sessionApi + `admin/sequence/${sequenceId}/publish`, {}, true);

const getPrograms = async () => await AxiosService.get(Config.server.api.sessionApi + 'admin/program', {}, true);
const getProgram = async programId => await AxiosService.get(Config.server.api.sessionApi + `program/${programId}`, {}, true);
const createProgram = async payload => await AxiosService.post(Config.server.api.sessionApi + 'admin/program', payload, true);
const editProgram = async (programId, payload) => await AxiosService.put(Config.server.api.sessionApi + `admin/program/${programId}`, payload, true);
const deleteProgram = async programId => await AxiosService.deleting(Config.server.api.sessionApi + `admin/program/${programId}`, true);
const publishProgram = async programId => await AxiosService.put(Config.server.api.sessionApi + `admin/program/${programId}/publish`, {}, true);
const getProgramSequences = async programId => await AxiosService.get(Config.server.api.sessionApi + `program/${programId}/sequence`, {}, true);
const orderingProgramSequences = async (programId, payload) => await AxiosService.put(Config.server.api.sessionApi + `admin/program/${programId}/sequence`, payload, true);

const getUserAssignments = async userId => await AxiosService.get(Config.server.api.sessionApi + `admin/user/${userId}/assignment`, {}, true);
const createUserAssignment = async (userId, payload) => await AxiosService.post(Config.server.api.sessionApi + `admin/user/${userId}/assignment`, payload, true);
const deleteUserAssignment = async (userId, assignmentId) => await AxiosService.deleting(Config.server.api.sessionApi + `admin/user/${userId}/assignment/${assignmentId}`, true);


export default {
    getUserAssignments,
    createUserAssignment,
    deleteUserAssignment,
    cloneSequence,
    createSequence,
    createSequenceCategory,
    deleteSequence,
    deleteSequenceCategory,
    editSequence,
    editSequenceCategory,
    editAsana,
    getAsanas,
    getAsana,
    getSequence,
    getSequences,
    getSequenceCategories,
    getUserSessions,
    publishSequence,
    setMainSequence,
    getPrograms,
    getProgram,
    createProgram,
    editProgram,
    deleteProgram,
    publishProgram,
    getProgramSequences,
    orderingProgramSequences
}


// https://sessions-dev.yogabot.app/session/admin/program (GET) --> Lista de programa desde admin*
// https://sessions-dev.yogabot.app/session/program (GET) --> Lista de programas desde web
// https://sessions-dev.yogabot.app/session/admin/program (POST) --> Crear programa*
// https://sessions-dev.yogabot.app/session/admin/program/{programId} (PUT) --> Actualizar programa*
// https://sessions-dev.yogabot.app/session/admin/program/{programId} (DELETE) --> Borrar programa*
// https://sessions-dev.yogabot.app/session/admin/program/{programId}/publish (PUT) --> Publicar/despublicar programa*
// https://sessions-dev.yogabot.app/session/program/{programId}/sequence (GET) --> Lista de secuencias de un programa*
// https://sessions-dev.yogabot.app/session/admin/program/{programId} (PUT) --> con el body con un array de ids de secuencias como lo siguiente: