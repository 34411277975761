import React from 'react';
import { Form, Dropdown } from 'semantic-ui-react';

class Select extends React.Component {

    render() {
        
        return (
            <Form.Field className={ this.props.fieldClasses }>
                <label>{ this.props.label }</label>
                <Dropdown
                    { ...this.props.input }
                    placeholder={ this.props.placeholder }
                    selection
                    disabled={this.props.disabled || false}
                    options={ this.props.options }
                    className="fluid"
                    onChange={ (e, { value }) => this.props.input.onChange(value) } />
            </Form.Field>
        );

    }

};

export default Select;