import React, { Component } from 'react'
import { connect } from 'react-redux';
import _ from 'lodash';
import { reduxForm, Field } from 'redux-form';
import { Form, Button, Segment, Header, Label} from 'semantic-ui-react';
import I18n from '../../../../../../i18n';
import CustomInput from '../../../../../components/form/input';
import { required } from '../../../../../../config/validations';
import Select from '../../../../../components/form/select';
import CheckboxList from '../../../../../components/form/checkBoxList';
import ProductModel from '../../../../../../data/models/product'
import DefaultCheckBox from '../../../../../components/form/defaultCheckbox';
import CustomDatePicker from '../../../../../components/form/datepicker';

class StudioForm extends Component {

    constructor(props){
        super(props);
        this.state = {

            flexoextensionOptions: [
                {
                    key:1,
                    text: 'Extensión',
                    value: 'extension'
                },
                {
                    key:2,
                    text: 'Flexión',
                    value: 'flexion'
                },
                {
                    key:3,
                    text: 'No aplica',
                    value: 'noImplies'
                }
            ],
            lateralizationOptions: [
                {
                    key:1,
                    text: 'Aplica',
                    value: 'implies'
                },
                {
                    key:2,
                    text: 'No aplica',
                    value: 'noImplies'
                }
            ],
            abductionOptions: [
                {
                    key:1,
                    text: 'Abducción',
                    value: 'abduction'
                },
                {
                    key:2,
                    text: 'Aducción',
                    value: 'aduction'
                },
                {
                    key:3,
                    text: 'No aplica',
                    value: 'noImplies'
                }
            ],
            muscleTrainOptions: [
                {
                    key:1,
                    text: 'Superior',
                    value: 'higher'
                },
                {
                    key:2,
                    text: 'Inferior',
                    value: 'lower'
                },
                {
                    key:3,
                    text: 'En equilibrio',
                    value: 'balanced'
                },
                {
                    key:4,
                    text: 'No aplica',
                    value: 'noImplies'
                }
            ]

        }

        this.errorMessage = {
            headerTitle: "Error",
            title: I18n.t('messages.modal.commonError'),
            buttons: [{
                text : I18n.t('actions.accept'),
                callback : () => this.props.closeModal()
            }]
        };
    }
    
    onSubmit = async payload => {

        let hasError = false;

        try {

            this.props.openLoader();

            if (this.props.match.params.asanaId) { 

                await ProductModel.editProduct(this.props.match.params.asanaId, payload)
    
            } else {

                await ProductModel.createProduct(payload);

            }

        } catch (error) {
            
            this.props.openModal(this.errorMessage);
            hasError = true;

        } finally {

            this.props.closeLoader();
            !hasError && this.props.history.push('/home/studio/list');

        }

    }

    render() {

        const { relatedProductsOptions, reduxFormState } = this.props;
        const { flexoextensionOptions, lateralizationOptions, abductionOptions, muscleTrainOptions } = this.state;

        return (
            <Form onSubmit={this.props.handleSubmit(this.onSubmit)} name="studioForm">
                <Label as='a' color='blue' ribbon>
                    {this.props.published ? 'Publicado' : 'No publicado'}
                </Label>
                <Segment id="titles">
                    <Header as="h3">{I18n.t('studio.titles')}</Header>
                    <Form.Group widths='equal'>
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('studio.title') }
                            name="title"
                            label={ I18n.t('studio.title') }
                            validate={ [required] }
                            />
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('studio.subtitle') }
                            name="subtitle"
                            label={ I18n.t('studio.subtitle') }
                            validate={ [required] }
                        />
                    </Form.Group>
                </Segment>
                <Segment id="resources">
                    <Header as="h3">{I18n.t('studio.features')}</Header>
                    <Form.Group widths='equal'>
                        <Field
                            component={ Select }
                            placeholder={ I18n.t('studio.flexoextension') }
                            name="flexoextension"
                            label={ I18n.t('studio.flexoextension') }
                            options={flexoextensionOptions}
                            />
                        <Field
                            component={ Select }
                            placeholder={ I18n.t('studio.lateralization') }
                            name="lateralization"
                            label={ I18n.t('studio.lateralization') }
                            options={lateralizationOptions}
                        />
                        <Field
                            component={ Select }
                            placeholder={ I18n.t('studio.abduction') }
                            name="abduction"
                            label={ I18n.t('studio.abduction') }
                            options={abductionOptions}
                            />
                        <Field
                            component={ Select }
                            placeholder={ I18n.t('studio.muscleTrain') }
                            name="muscleTrain"
                            label={ I18n.t('studio.muscleTrain') }
                            options={muscleTrainOptions}
                        />
                    </Form.Group>
                </Segment>
                <Segment id="resources">
                    <Header as="h3">{I18n.t('studio.resourcePaths')}</Header>
                    <Form.Group widths='equal'>
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('studio.imageUrl') }
                            name="imageUrl"
                            label={ I18n.t('studio.imageUrl') }
                            validate={ [required] }
                            />
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('studio.videoUrl') }
                            name="videoUrl"
                            label={ I18n.t('studio.videoUrl') }
                            validate={ [required] }
                        />
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('studio.duration') }
                            name="duration"
                            label={ I18n.t('studio.duration') }
                            validate={ [required] }
                            restrictions={ [{ numeric: true }] } 
                        />
                    </Form.Group>
                </Segment>
                <Segment id="prices">
                    <Header as="h3">{I18n.t('studio.prices')}</Header>
                    <Form.Group widths='equal'>
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('studio.basicPriceEuro') }
                            name="prices.lite.EUR"
                            label={ I18n.t('studio.basicPriceEuro') }
                            restrictions={ [{ canBeFloat: true }] } 
                            />
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('studio.basicPriceDollar') }
                            name="prices.lite.USD"
                            label={ I18n.t('studio.basicPriceDollar') }
                            restrictions={ [{ canBeFloat: true }] } 
                        />
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('studio.premiumPriceEuro') }
                            name="prices.premium.EUR"
                            label={ I18n.t('studio.premiumPriceEuro') }
                            restrictions={ [{ canBeFloat: true }] } 
                        />
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('studio.premiumPriceDollar') }
                            name="prices.premium.USD"
                            label={ I18n.t('studio.premiumPriceDollar') }
                            restrictions={ [{ canBeFloat: true }] } 
                        />
                    </Form.Group>
                    <Form.Field>
                        <Field
                            component={ DefaultCheckBox }
                            name="hasDiscount"
                            label={ I18n.t('actions.applyDiscount') }
                            />
                    </Form.Field>
                    <Form.Field>
                        {
                                (reduxFormState.hasDiscount) && (
                                <Field
                                    component={ CustomInput }
                                    placeholder={ I18n.t('practice.discount') }
                                    name="discount"
                                    label={ I18n.t('practice.discount') }
                                    validate={ [required] }
                                    restrictions={ [{ numeric: true, canBeFloat: false }] } 
                                />
                            
                            )
                        }
                    </Form.Field>
                    <Form.Field>
                        {
                                (reduxFormState.hasDiscount) && (
                                <Field
                                    component={ CustomDatePicker }
                                    name="discountExpiration"
                                    label={ I18n.t('practice.expirationDiscount') }
                                    formatDate={'DD/MM/YYYY'}
                                />
                                
                            )
                        }
                    </Form.Field>
                </Segment>
                <Segment id="relatedProductsOptions">
                    <Header as="h3">{I18n.t('studio.relatedProducts')}</Header>
                    {!relatedProductsOptions.length && <p>No se han encontrado productos relacionados</p>}
                    <Field
                        component={ CheckboxList }
                        options={ relatedProductsOptions }
                        name="relatedProducts"
                    />
                </Segment>
                <Button floated="right" type='submit' primary>{ I18n.t('actions.save') }</Button>
                <Button floated="right" type='button' onClick={ () => this.props.history.push('/home/studio/list') }>{ I18n.t('actions.cancel') }</Button>
                <div style={{ clear: "both" }}></div>
            </Form>
        )
    }
}

StudioForm = connect(state => {

    const reduxFormState = _.cloneDeep(_.get(state, 'form.studioForm.values', {}));

    return {
        reduxFormState
    };

})(StudioForm);

export default reduxForm({
    form: 'studioForm',
    touchOnBlur: true,
    touchOnChange: true,
    enableReinitialize: true
})(connect(null, {  })(StudioForm));
