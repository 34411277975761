// Third party libraries
import _ from 'lodash';
import Moment from 'moment';
import { connect } from 'react-redux';
import React from 'react';
import { Segment, Table, Pagination, Menu, Input, Form, Checkbox, Icon, Select, Header, Button } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';

// Models
import SecurityModel from '../../../data/models/security/security';

// Redux
import { openLoader, closeLoader } from '../../../redux-store/loader';

// Const
import { countries } from './countries';

// Locales
import I18n from '../../../i18n';

class UserList extends React.Component {

    constructor(props) {

        super(props);

        this.genderValues = {
            1: 'Masculino',
            2: 'Femenino'
        };

        this.state = {
            loaded: false,
            users: [],
            pageNumber: 1,
            pageSize: 0,
            recordsPerPage: 10,
            column: 'createdAt',
            direction: null,
            search: '',
            filteredUsers: [],
            genderFilterOptions: [{
                key: 0,
                text: 'Todos',
                value: 0
            }, {
                key: 2,
                text: 'Femenino',
                value: 2
            }, {
                key: 1,
                text: 'Masculino',
                value: 1
            }],
            genderFilterValue: 0,
            allSelected: false,
            anySelected: false,
            Web: true,
            Android: true,
            iOS: true,
            title: '',
            body: '',
            image: '',
            selectedUsersCount: 0,
            subscriptionTypeFilterValue: 0,
            subscriptionTypeFilterOptions: [{
                key: 0,
                text: 'Todos',
                value: 0
            }, {
                key: 2,
                text: 'Premium',
                value: true
            }, {
                key: 1,
                text: 'Lite',
                value: false
            }],
            startDate: null,
            endDate: null
        };

    }

    componentDidMount() {

        this.getUsers();

    }

    isPremium = roles => roles.includes('premium');

    getUsers = async () => {

        try {

            this.props.openLoader()
            const users = await SecurityModel.getUsersWithNotificationTokens();

            _.each(users.data, user => {

                user.countryName = countries[user.country] || 'País sin definir';
                user.genderName = this.genderValues[user.gender] || 'Género sin definir';
                user.isPremium = this.isPremium(user.role || []);
                user.selected = false;
                user.Web = !!_.find(user.notificationTokens, nt => nt.platform === 'Web');
                user.Android = !!_.find(user.notificationTokens, nt => nt.platform === 'Android');
                user.iOS = !!_.find(user.notificationTokens, nt => nt.platform === 'iOS');

            });

            this.setState({
                filteredUsers: _.sortBy(users.data, [this.state.column]).reverse(),
                users: users.data,
                pageSize: Math.ceil(users.data.length / this.state.recordsPerPage)
            });

        } catch (error) {

            console.error('error', error);

        } finally {

            this.props.closeLoader();
            this.setState({ loaded: true });

        }

    }

    onPageChange = (e, pageInfo) => {

        this.setState({ pageNumber: pageInfo.activePage });

    }

    paginate = () => this.state.filteredUsers.slice((this.state.pageNumber - 1) * this.state.recordsPerPage, this.state.pageNumber * this.state.recordsPerPage);

    userHasPlatform = user => {

        const { Web, iOS, Android } = this.state;

        return !!_.find(user.notificationTokens, notificationToken =>
            (Web && notificationToken.platform === 'Web') ||
            (iOS && notificationToken.platform === 'iOS') ||
            (Android && notificationToken.platform === 'Android'));

    }

    onFilter = () => {

        let filteredUsers = this.state.users;

        const { recordsPerPage, search, genderFilterValue, subscriptionTypeFilterValue, startDate, endDate } = this.state;

        filteredUsers = filteredUsers.filter(user => {

            if ((!!search.length && !_.get(user, 'name', '').toLowerCase().includes(search.toLowerCase()) && !_.get(user, 'email', '').toLowerCase().includes(search.toLowerCase()))
                || (genderFilterValue !== 0 && user.gender !== genderFilterValue)
                || !this.userHasPlatform(user)
                || (startDate && Moment(user.createdAt).startOf('day').isBefore(Moment(startDate)))
                || (endDate && Moment(user.createdAt).startOf('day').isAfter(Moment(endDate)))
                || (subscriptionTypeFilterValue !== 0 && user.isPremium !== subscriptionTypeFilterValue)) {

                return false;

            }

            return true;

        });

        this.setState({ filteredUsers, pageSize: Math.ceil(filteredUsers.length / recordsPerPage), pageNumber: 1 });

    }

    onFilterListByGender = genderFilterValue => this.setState({ genderFilterValue }, () => this.onFilter());
    onFilterListBySubscriptionType = subscriptionTypeFilterValue => this.setState({ subscriptionTypeFilterValue }, () => this.onFilter());
    onFilterListByName = search => this.setState({ search }, () => this.onFilter());
    onFilterListByPlatform = (type, value) => this.setState({ [type]: value }, () => this.onFilter());
    onFilterAtStartDate = startDate => this.setState({ startDate }, () => this.onFilter());
    onFilterAtEndDate = endDate => this.setState({ endDate }, () => this.onFilter());

    handleSort = clickedColumn => () => {

        const { column, filteredUsers, direction } = this.state;

        if (column !== clickedColumn) {

            this.setState({
                column: clickedColumn,
                filteredUsers: clickedColumn === 'createdAt' ? filteredUsers.sort((a, b) => Moment(a[clickedColumn]).diff(b[clickedColumn])) : _.sortBy(filteredUsers, [clickedColumn]),
                direction: 'ascending',
            })

            return;

        }

        this.setState({
            filteredUsers: filteredUsers.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending'
        });

    }

    onSelect = (userId, selected) => {

        const { users, filteredUsers } = this.state;
        _.each(users, user => {

            if (user._id === userId) {

                user.selected = selected;

            }

        });

        let selectedUsersCount = 0;
        _.each(filteredUsers, user => {

            if (user._id === userId) {

                user.selected = selected;

            }

            if (user.selected) {

                selectedUsersCount++;

            }

        });

        const allSelected = selectedUsersCount === _.size(filteredUsers);
        const anySelected = selectedUsersCount > 0;

        this.setState({ users, filteredUsers, allSelected, anySelected, selectedUsersCount });

    }

    onSelectAll = selected => {

        const { users, filteredUsers } = this.state;
        _.each(users, user => {

            if (_.find(filteredUsers, filteredUser => filteredUser._id === user._id)) {

                user.selected = selected;

            }

        });

        let selectedUsersCount = 0;
        _.each(filteredUsers, user => {

            user.selected = selected;

            if (user.selected) {

                selectedUsersCount++;

            }

        });

        const anySelected = selectedUsersCount > 0;

        this.setState({ users, filteredUsers, allSelected: selected, anySelected, selectedUsersCount });

    }

    handleOnSubmit = () => {

        const { title, body, image, filteredUsers, Web, iOS, Android } = this.state;
        const users = [];
        const platforms = [];

        _.each(filteredUsers, user => users.push(user._id));

        if (Web) {

            platforms.push('Web');

        }

        if (Android) {

            platforms.push('Android');

        }

        if (iOS) {

            platforms.push('iOS');

        }

        SecurityModel.sendNotifications({ title, body, image, users, platforms });

    }

    onRenderTable = () => {

        const { column, direction, allSelected, anySelected } = this.state;

        return (
            <Table celled selectable sortable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell><Checkbox checked={ allSelected } defaultIndeterminate={ !allSelected && anySelected } onChange={ (e, data) => this.onSelectAll(data.checked) } /></Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('email') } sorted={ column === 'email' ? direction : null }>{ I18n.t('user.list.email') }</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('countryName') } sorted={ column === 'countryName' ? direction : null }>{ I18n.t('user.list.country') }</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('genderName') } sorted={ column === 'genderName' ? direction : null }>{ I18n.t('user.list.gender') }</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('name') } sorted={ column === 'name' ? direction : null }>{ I18n.t('user.list.name') }</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('surname') } sorted={ column === 'surname' ? direction : null }>{ I18n.t('user.list.surname') }</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('isPremium') } sorted={ column === 'isPremium' ? direction : null }>{ I18n.t('user.list.isPremium') }</Table.HeaderCell>
                        <Table.HeaderCell onClick={ this.handleSort('createdAt') } sorted={ column === 'createdAt' ? direction : null }>{ I18n.t('user.list.registerDate') }</Table.HeaderCell>
                        <Table.HeaderCell>{ I18n.t('notifications.platforms') }</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    { this.paginate().map(user => (
                        <Table.Row key={ user._id }>
                            <Table.Cell collapsing><Checkbox checked={ user.selected } onChange={ (e, data) => this.onSelect(user._id, data.checked) } /></Table.Cell>
                            <Table.Cell>{ user.email || I18n.t('user.info.noDefined') }</Table.Cell>
                            <Table.Cell>{ user.countryName || I18n.t('user.info.noDefined') }</Table.Cell>
                            <Table.Cell>{ user.genderName || I18n.t('user.info.noDefined') }</Table.Cell>
                            <Table.Cell>{ user.name || I18n.t('user.info.noDefined') }</Table.Cell>
                            <Table.Cell>{ user.surname || I18n.t('user.info.noDefined') }</Table.Cell>
                            <Table.Cell textAlign='center'>{ user.isPremium ? <i className="checkmark sign icon"></i> : '' }</Table.Cell>
                            <Table.Cell>{ user.createdAt ? Moment(user.createdAt).format('DD/MM/YYYY HH:mm:ss') : I18n.t('user.info.noCreatedAt') }</Table.Cell>
                            <Table.Cell textAlign='center'>{ user.Web ? <Icon name='desktop' /> : '' }&nbsp;{ user.Android ? <Icon name='android' /> : '' }&nbsp;{ user.iOS ? <Icon name='apple' /> : '' }</Table.Cell>
                        </Table.Row>
                    )) }
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan={ 9 }>
                            <Menu floated='right'>
                                <Pagination
                                    onPageChange={ this.onPageChange }
                                    boundaryRange={ 1 }
                                    defaultActivePage={ 1 }
                                    prevItem={ null }
                                    nextItem={ null }
                                    siblingRange={ 2 }
                                    totalPages={ Math.ceil(this.state.filteredUsers.length / this.state.recordsPerPage) }
                                />
                            </Menu>
                            <div style={{ clear: "both" }}></div>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        );

    }

    render() {

        const { genderFilterOptions, genderFilterValue, Web, Android, iOS, selectedUsersCount, title, body, image, subscriptionTypeFilterValue, subscriptionTypeFilterOptions, startDate, endDate } = this.state;

        return (
            <>
                <Segment color='teal'>
                    <Header as='h3'>{ I18n.t('notifications.filters') }</Header>
                    <div>
                        <Form>
                            <Form.Group inline>
                                <Form.Field>
                                    <label>{ I18n.t('notifications.nameOrEmail') }</label>
                                    <Input value={ this.state.search } onChange={ e => this.onFilterListByName(e.target.value) } placeholder={ I18n.t('notifications.placeholderNameOrEmail') } />
                                </Form.Field>
                                <Form.Field>
                                    <label>{ I18n.t('notifications.gender') }</label>
                                    <Select value={ genderFilterValue } onChange={(e, { value }) => this.onFilterListByGender(value) } options={ genderFilterOptions } />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group inline>
                                <Form.Field>
                                    <label>{ I18n.t('notifications.subscriptionType') }</label>
                                    <Select value={ subscriptionTypeFilterValue } onChange={(e, { value }) => this.onFilterListBySubscriptionType(value) } options={ subscriptionTypeFilterOptions } />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group inline>
                                <Form.Field>
                                    <label>{ I18n.t('notifications.initDate') }</label>
                                    <SemanticDatepicker onChange={ (e, data) => this.onFilterAtStartDate(data.value) } value={ startDate ? new Date(startDate) : startDate} format='DD/MM/YYYY' />
                                </Form.Field>
                                <Form.Field>
                                    <label>{ I18n.t('notifications.endDate') }</label>
                                    <SemanticDatepicker onChange={ (e, data) => this.onFilterAtEndDate(data.value) } value={ endDate ? new Date(endDate) : endDate } format='DD/MM/YYYY' />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group inline>
                                <Form.Field>
                                    <label>{ I18n.t('notifications.platforms') }</label>
                                    <Checkbox onChange={ (e, data) => this.onFilterListByPlatform('Web', data.checked) } checked={ Web } label='Web' style={{ paddingLeft: '20px' }} />
                                    <Checkbox onChange={ (e, data) => this.onFilterListByPlatform('Android', data.checked) } checked={ Android } label='Android' style={{ paddingLeft: '20px' }} />
                                    <Checkbox onChange={ (e, data) => this.onFilterListByPlatform('iOS', data.checked) } checked={ iOS } label='iOS' style={{ paddingLeft: '20px' }} />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </div>
                </Segment>
                <Segment color='teal'>
                    <Header as='h3'>{ I18n.t('notifications.selectUsers') + ' : (' + I18n.t('notifications.usersSelected', { selectedUsersCount }) + ')' }</Header>
                    { this.state.loaded && this.onRenderTable() }
                </Segment>
                <Segment color='teal'>
                    <Header as='h3'>{ I18n.t('notifications.notificationData') }</Header>
                    <Form onSubmit={ this.handleOnSubmit }>
                        <Form.Field>
                            <label>{ I18n.t('notifications.title') }</label>
                            <Input value={ title } onChange={ e => this.setState({ title: e.target.value }) } placeholder={ I18n.t('notifications.placeholderTitle') } />
                        </Form.Field>
                        <Form.Field>
                            <label>{ I18n.t('notifications.body') }</label>
                            <Input value={ body } onChange={ e => this.setState({ body: e.target.value }) } placeholder={ I18n.t('notifications.placeholderBody') } />
                        </Form.Field>
                        <Form.Field>
                            <label>{ I18n.t('notifications.image') }</label>
                            <Input value={ image } onChange={ e => this.setState({ image: e.target.value }) } placeholder={ I18n.t('notifications.placeholderImage') } />
                        </Form.Field>
                        <div align='right'>
                            <Button disabled={ selectedUsersCount === 0 || (!Web && !Android && !iOS) || !title || !body || !image } color='teal'>{ I18n.t('notifications.send') }</Button>
                        </div>
                    </Form>
                </Segment>
            </>
        );

    }

}

export default connect(null, { openLoader, closeLoader })(UserList);