import React, { Component } from 'react';
import _ from 'lodash';
import { Form, Segment, Select } from 'semantic-ui-react';
import CategoryView from './components/categories';
import withData from './components/hocHandler'
import ProgramView from './components/programs';
import SequenceView from './components/sequences';

const requiredTypes = ['category', 'sequence', 'program'];

class Assignments extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedView : 1,
            assignmentFilter: 1,
            viewOptions : [
                {
                    key: 1,
                    text: "Secuencias",
                    value: 1
                },
                {
                    key: 2,
                    text: "Programas",
                    value: 2
                },
                {
                    key: 3,
                    text: "Categorias",
                    value: 3
                }
            ],
            assignmentOptions: [
                {
                    key: 1,
                    text: "No asignados",
                    value: 1
                },
                {
                    key: 2,
                    text: "Asignados",
                    value: 2
                }
            ]     
        }
    }

    onChangeView = selectedView => this.setState({selectedView});
    onChangeFilterAssginment = assignmentFilter => this.setState({assignmentFilter});

    filterAssignmentsByType = type => {

        const { userAssignments } = this.props;

        if (requiredTypes.includes(type)) {

            return _.reduce(userAssignments, (acc, el) => {
            
                if (el.type === type) {

                    return {
                        ...acc,
                        [el.sourceId]: {
                            assignmentId: el._id,
                            type: el.type
                        }
                    };
                    
                }

                return {}
                
            }, {});

        }

        return {};

    }

    render() {

        const { viewOptions, selectedView, assignmentOptions, assignmentFilter} = this.state;
        const { sequences, categories, programs, onHandleAssignment } = this.props;

        return (
            <>
                <Segment>
                    <Form>
                        <Form.Group inline>
                        <Form.Field><Select options={viewOptions} value={selectedView} onChange={(e, {value})=> this.onChangeView(value)} /></Form.Field>
                        <Form.Field><Select options={assignmentOptions} value={assignmentFilter} onChange={(e, {value})=> this.onChangeFilterAssginment(value)} /></Form.Field>
                        </Form.Group>
                    </Form>
                </Segment>
                {
                    selectedView === 1 && <SequenceView data={sequences} assignments={this.filterAssignmentsByType('sequence')} onHandleAssignment={onHandleAssignment} assignmentFilter={assignmentFilter} />
                }
                {
                    selectedView === 2 && <ProgramView data={programs} assignments={this.filterAssignmentsByType('program')} onHandleAssignment={onHandleAssignment} assignmentFilter={assignmentFilter} />
                }
                {
                    selectedView === 3 && <CategoryView data={categories} assignments={this.filterAssignmentsByType('category')} onHandleAssignment={onHandleAssignment} assignmentFilter={assignmentFilter} />
                }
            </>
        )
    }
}

const HOCAssignments = withData(Assignments);

export default HOCAssignments;