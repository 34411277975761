import React, { Component } from 'react';
import ProgramForm from './form';
import { connect } from 'react-redux';
import { closeModal, openModal } from '../../../../../../redux-store/modal';
import { openLoader, closeLoader } from '../../../../../../redux-store/loader';
import SessionModel from '../../../../../../data/models/sessions';
import {defaultProgram} from '../../../../../../config/constants/program';
import _ from 'lodash';
import { Segment } from 'semantic-ui-react';
import I18n from '../../../../../../i18n';
import arrayMove from 'array-move';

class ProgramEdit extends Component {

     constructor(props){
         super(props);

         this.state = {

            sequences: [],
            program: {},
            categories: []

         }

         this.errorMessage = {
            headerTitle: "Error",
            title: I18n.t('messages.modal.commonError'),
            buttons: [{
                text : I18n.t('actions.accept'),
                callback : () => this.props.closeModal()
            }]
        };

     }

    async componentDidMount() {

        if (this.props.match.params.programId) {

            this.getData();

        } else {

            try {

                const categories = await SessionModel.getSequenceCategories();
                this.setState({program: _.cloneDeep(defaultProgram), categories : categories.data });

            } catch (error) {
                
                this.props.openModal(this.errorMessage);

            }

            finally {

                this.props.closeLoader();
    
            }
            
        }

    }

    getData = async () => {

        try {

            this.props.openLoader();
            const program = await SessionModel.getProgram(this.props.match.params.programId);
            const sequences = await SessionModel.getProgramSequences(this.props.match.params.programId);
            const categories = await SessionModel.getSequenceCategories();
            this.setState({ program : program.data, sequences: _.orderBy(sequences.data, ['order'], ['asc']), categories: categories.data });

        } catch (error) {

            this.props.openModal(this.errorMessage);
            
        } finally {

            this.props.closeLoader();

        }

    }

    onSave = async data => {

        const { sequences } = this.state;
        const sequencesOrder = _.map(sequences, sequence=> sequence._id );

        try {

            this.props.openLoader();

            if (this.props.match.params.programId) {

                await SessionModel.editProgram(this.props.match.params.programId, _.omit(data, ['_id']));

                if (sequencesOrder.length) {

                    await SessionModel.orderingProgramSequences(this.props.match.params.programId, sequencesOrder);
                }

            } else {

                await SessionModel.createProgram(data);

            }

        } catch (error) {

            this.props.openModal(this.errorMessage);
            
        } finally {

            this.props.closeLoader();
            this.props.history.push('/home/sequence/programs');

        }

    }

    onOrder = (oldIndex, newIndex) => {

        const { sequences } = this.state;
        const reorderedSequences = arrayMove(sequences, oldIndex, newIndex);
        this.setState({sequences: reorderedSequences});

    }

    render() {

        const { program, sequences, categories } = this.state;

        return (
            <Segment>
                {!_.isEmpty(program) && <ProgramForm
                    initialValues={program}
                    sequences={sequences}
                    categories={categories}
                    onSave={this.onSave}
                    onOrder={this.onOrder}
                    {...this.props}
                />}
            </Segment>
        );
    }
}

export default connect(null, { closeModal, openModal, openLoader, closeLoader })(ProgramEdit);