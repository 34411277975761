import _ from 'lodash';
import { connect } from 'react-redux';
import React from 'react';
import { Segment, Header, Table, Icon, Form, TextArea, Button, Select } from 'semantic-ui-react';
import './userQuestions.scss';
import QuestionModel from '../../../../data/models/question/question';
import { openLoader, closeLoader } from '../../../../redux-store/loader';
import { countries } from '../list/countries';
import I18n from '../../../../i18n';
import moment from 'moment';

class UserQuestions extends React.Component {

    constructor(props) {
        super(props);
        this.formFields = {}
        this.state = {
            visibleForm : false,
            questions : [],
            selectedQuestion: '',
            adminAnswer : '',
            categories: [],
            loaded: false,
            column: 'created',
            direction: 'ascending',
            filter: 'Pendiente',//Resuelta y Todas
            originalData : [],
            options : [
                { key: '1', value: 'Resuelta', text: 'Resuelta' },
                { key: '2', value: 'Pendiente', text: 'Pendiente' },
                { key: '3', value: 'Todas', text: 'Todas' },
            ]
        }

    }

     componentDidMount() {

        this.getData();

    }

    getData = async () => {

        try {

            this.props.openLoader()
            const adminQuestions = await QuestionModel.getUserQuestionsToAdmin();
            const categories = await QuestionModel.getCategories();
            let indexedCategories = await this.indexCategories(categories.data);

            _.each(adminQuestions.data, (questions, index) => {

                adminQuestions.data[index].categoryName = indexedCategories[questions.category] || 'Categoría sin definir';
                adminQuestions.data[index].countryName = countries[questions.country] || 'País sin definir';
                adminQuestions.data[index].userEmail = adminQuestions.data[index].email || 'Correo sin definir'
            });

            this.setState({ originalData: adminQuestions.data, questions: _.orderBy(_.filter(adminQuestions.data, item => this.state.filter === 'Todas' || item.status === this.state.filter ), ['created'], ['asc'] ), categories: indexedCategories });

        } catch (error) {

            console.error('error', error);

        } finally {

            this.props.closeLoader();
            this.setState({ loaded: true });

        }

    }

    indexCategories = async categories => {

        let ob = {};
        _.each(categories || [], category => {
            ob[category._id] = category.label
        });

        return ob;
    }

    onAnswer = questionId => {

        let selectedQuestion =  _.find(this.state.questions, question => (question._id === questionId));

        this.setState({visibleForm: true, selectedQuestion});

    }

    onChange = e => {

        this.setState({ adminAnswer: e.target.value })

    }

    onCancel = () => {

        this.setState({ adminAnswer: '', visibleForm: false, selectedQuestion: '' });

    }

    handleSubmit = async e => {

        e.preventDefault();

        try {

            await QuestionModel.answerToUser({_id : this.state.selectedQuestion._id, answer : this.state.adminAnswer});

        } catch (error) {

            console.error('error', error)

        } finally {

            this.onCancel();
            this.getData();

        }

    }

    onStatusFilterChange = value => {

        this.setState({ filter: value, questions: _.orderBy(_.filter(this.state.originalData, item => value === 'Todas' || item.status === value ), [this.state.column], [this.direction === 'ascending' ? 'asc' : 'desc']) });

    }

    handleSort = (clickedColumn) => () => {

        const { column, direction, originalData, filter } = this.state;

        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            questions: _.orderBy(_.filter(originalData, item => filter === 'Todas' || item.status === filter ), [clickedColumn], ['asc']),
            direction: 'ascending',
          })

          return
        }

        this.setState({
          questions: _.orderBy(_.filter(originalData, item => filter === 'Todas' || item.status === filter ), [clickedColumn], [direction === 'ascending' ? 'desc' : 'asc']),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        });

      }

    onRenderTable = () => {

        const { column, direction } = this.state;

        return (
            <Table celled selectable sortable>
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell onClick={this.handleSort('created')} sorted={column === 'created' ? direction : null}>{I18n.t('user.questions.dateQuery')}</Table.HeaderCell>
                    <Table.HeaderCell onClick={this.handleSort('categoryName')} sorted={column === 'categoryName' ? direction : null}>{I18n.t('user.questions.category')}</Table.HeaderCell>
                    <Table.HeaderCell onClick={this.handleSort('userEmail')} sorted={column === 'userEmail' ? direction : null}>{I18n.t('user.questions.email')}</Table.HeaderCell>
                    <Table.HeaderCell onClick={this.handleSort('name')} sorted={column === 'name' ? direction : null}>{I18n.t('user.questions.name')}</Table.HeaderCell>
                    <Table.HeaderCell onClick={this.handleSort('countryName')} sorted={column === 'countryName' ? direction : null}>{I18n.t('user.questions.country')}</Table.HeaderCell>
                    <Table.HeaderCell onClick={this.handleSort('title')} sorted={column === 'title' ? direction : null}>{I18n.t('user.questions.subject')}</Table.HeaderCell>
                    <Table.HeaderCell onClick={this.handleSort('text')} sorted={column === 'text' ? direction : null}>{I18n.t('user.questions.question')}</Table.HeaderCell>
                    <Table.HeaderCell onClick={this.handleSort('status')} sorted={column === 'status' ? direction : null}>{I18n.t('user.questions.status')}</Table.HeaderCell>
                    <Table.HeaderCell >{I18n.t('user.questions.answer')}</Table.HeaderCell>
                </Table.Row>
                </Table.Header>
            <Table.Body>
                { this.state.questions.map( question => (
                <Table.Row key={question._id}>
                    <Table.Cell>{moment(question.created).format('DD/MM/YYYY HH:mm:ss')}</Table.Cell>
                    <Table.Cell>{question.categoryName}</Table.Cell>
                    <Table.Cell>{question.userEmail}</Table.Cell>
                    <Table.Cell>{question.name}</Table.Cell>
                    <Table.Cell>{question.countryName}</Table.Cell>
                    <Table.Cell>{question.title}</Table.Cell>
                    <Table.Cell>{question.text}</Table.Cell>
                    <Table.Cell>{question.status}</Table.Cell>
                    <Table.Cell style={{textAlign: 'center'}}><Icon name="reply" size="large" onClick={()=> this.onAnswer(question._id)}></Icon></Table.Cell>
                </Table.Row>
                ))}
            </Table.Body>
        </Table>
        )
    }

    onRenderForm = () => {

        return (

            <>
                <Segment>
                    <Form noValidate onSubmit={this.handleSubmit}>
                        <Form.Field>
                            <label>{I18n.t('user.questions.dateQuery')}</label>
                            <p>{this.state.selectedQuestion.creted}</p>
                        </Form.Field>
                        <Form.Field>
                            <label>{I18n.t('user.questions.subject')}</label>
                            <p>{this.state.selectedQuestion.title}</p>
                        </Form.Field>
                        <Form.Field>
                            <label>{I18n.t('user.questions.question')}</label>
                            <p>{this.state.selectedQuestion.text}</p>
                        </Form.Field>
                        <Form.Field>
                            <label>{I18n.t('user.questions.answered')}</label>
                            <p>{this.state.selectedQuestion.answer}</p>
                        </Form.Field>
                        <Form.Field>
                            <label>{I18n.t('user.questions.name')}</label>
                            <p>{this.state.selectedQuestion.name}</p>
                        </Form.Field>
                        <Form.Field>
                            <label>{I18n.t('user.questions.email')}</label>
                            <p>{this.state.selectedQuestion.userEmail}</p>
                        </Form.Field>
                        <Form.Field>
                            <label>{I18n.t('user.questions.country')}</label>
                            <p>{this.state.selectedQuestion.countryName}</p>
                        </Form.Field>
                        { this.state.selectedQuestion.status !== 'Resuelta' &&
                            <React.Fragment>
                                <Form.Field>
                                    <label>{I18n.t('user.questions.addQuestion')}</label>
                                    <TextArea placeholder='respuesta' name="answer" value={this.state.adminAnswer} onChange={this.onChange}/>
                                </Form.Field>
                                <Button type='button' onClick={this.onCancel}>{I18n.t('actions.cancel')}</Button>
                                <Button type='submit' primary>{I18n.t('actions.save')}</Button>
                            </React.Fragment>
                        }
                    </Form>
                </Segment>
            </>
        )

    }

    render() {

        const { options, filter } = this.state;

        return (
            <>
                <Segment>
                    <Form>
                        <Form.Field inline>
                            <label>{I18n.t('user.questions.selectStatus')}</label><br />
                            <Select value={filter} onChange={(e, { value }) => this.onStatusFilterChange(value) } options={options} />
                        </Form.Field>
                    </Form>
                    <Header as='h3'>{I18n.t('user.questions.questionList')}</Header>
                    {this.state.loaded && this.onRenderTable()}
                </Segment>
                {this.state.visibleForm && this.onRenderForm()}
            </>
        );

    }

}

export default connect(null, { openLoader, closeLoader })(UserQuestions);