module.exports = {
    debugMode: true,
    server: {
        api: {
            baseUrl: process.env.REACT_APP_API_BASEURL || 'https://api.yogabot.app/api/',
            biometricMatrixApi: process.env.REACT_APP_BIOMETRIC_MATRIX_API || 'https://biometricmatrix-dev.yogabot.app/biometricmatrix/',
            paymentsApi: process.env.REACT_APP_PAYMENTS_API || 'https://payments-dev.yogabot.app/payment/',
            progressApi: process.env.REACT_APP_PROGRESS_API || 'https://progress-dev.yogabot.app/progress/',
            questionApi: process.env.REACT_APP_QUESTION_API || 'https://questions-dev.yogabot.app/question/',
            sessionApi: process.env.REACT_APP_SESSIONS_API || 'https://sessions-dev.yogabot.app/session/',
            securityApi: process.env.REACT_APP_SECURITY_API || 'https://security-dev.yogabot.app/security/',
            schoolApi: process.env.REACT_APP_SCHOOL_API || 'https://school-dev.yogabot.app/school/',
            statsApi: process.env.REACT_APP_STATS_API || 'https://stats-dev.yogabot.app/stat/',
            timeout: 20000,
            headTimeout: 2000
        }
    }
};