// Third party libraries
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Models
import SessionModel from '../../../../../../data/models/sessions';

// Redux
import { closeModal, openModal } from '../../../../../../redux-store/modal';
import { openLoader,  closeLoader } from '../../../../../../redux-store/loader';

// Components
import AsanaForm from './components/form';

// Locales
import I18n from '../../../../../../i18n';

class AsanaEdit extends Component {

    constructor(props) {

        super(props);

        this.state = {
            asana: {},
            asanas: [],
            indexedAsanas: {},
            loaded: false
        };

    }

    componentDidMount() {

        this.getAsanas();

    }

    getAsanas = async () => {

        const { closeModal, openModal, openLoader,  closeLoader } = this.props;

        let message = {
            headerTitle: "Error",
            title: I18n.t('messages.modal.commonError'),
            buttons: [{
                text: I18n.t('actions.accept'),
                callback: closeModal
            }]
        };

        try {

            openLoader();
            const asanas = await SessionModel.getAsanas();
            const asana = await SessionModel.getAsana(this.props.match.params.asanaId);

            if (asana.data && asanas.data) {

                let indexedAsanas = {};
                _.each(asanas.data, item => indexedAsanas[item._id] = item);
                this.setState({ asana: asana.data, asanas: asanas.data, loaded: true, indexedAsanas });

            }

        } catch (error) {

            closeLoader();
            openModal(message);

        } finally {

            closeLoader();

        }

    }

    render() {

        const { asana, asanas, loaded, indexedAsanas } = this.state;
        const initialValues = _.merge({ compatibleAsanas: [] }, asana);

        return loaded && <AsanaForm initialValues={ initialValues } { ...this.props } asanas={ asanas } indexedAsanas={ indexedAsanas } />;

    }

}

export default connect(null, { closeModal, openModal, openLoader, closeLoader })(AsanaEdit);