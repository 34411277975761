// Types
const OPEN_DISCOUNT_MODAL = 'OPEN_DISCOUNT_MODAL';
const CLOSE_DISCOUNT_MODAL = 'CLOSE_DISCOUNT_MODAL';

// Actions
export const openDiscountModal = message => { return { type: OPEN_DISCOUNT_MODAL, payload: { open: true, message: message} } };
export const closeDiscountModal = () => { return { type: CLOSE_DISCOUNT_MODAL, payload: { open: false, message: {} } } };

// Reducer
const INITIAL_STATE = {
    open : false
};

function reducer (state = INITIAL_STATE, action) {

    switch (action.type) {

        case OPEN_DISCOUNT_MODAL:
        case CLOSE_DISCOUNT_MODAL:

            return { ...action.payload };

        default:

            return state;

    }

};

export default reducer; 