// Third party libraries
import React, { Component } from 'react';
import { Form, Header, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Moment from 'moment';
import SemanticDatepicker from 'react-semantic-ui-datepickers';

// Components
import PieGraphContainer from './components/graphs/pie';
import MapGraphContainer from './components/graphs/map';
import LineGraphContainer from './components/graphs/line';

// Models
import StatsModel from '../../../../data/models/stats/stats';

// Redux
import { openLoader, closeLoader } from '../../../../redux-store/loader';

// Styles
import '../styles/styles.scss';

const pieGenderOptions = {
    property: 'gender',
    categories: [{
        name: 'Femenino',
        transform: item => item.gender === 1,
        id: 'a'
    }, {
        name: 'Masculino',
        transform: item => item.gender === 2,
        id: 'b'
    }]
};

const modeOptions = {
    property : 'challenge',
    categories : [{
        name: 'Modo explorador',
        transform: item => item.challenge === 'explorer',
        id: 'a'
    }, {
        name: 'Modo crecimiento',
        transform: item => item.challenge === 'grow',
        id: 'b'
    }]
};

class AdvisorStats extends Component {

    constructor(props) {

        super(props);

        this.state = {
            referredUsers: [],
            referredUsersFiltered: [],
            challengesUsers: [],
            challengesUsersFiltered: [],
            freeConsultationUsers: [],
            freeConsultationUsersFiltered: [],
            startDate: null,
            endDate: null
        }

    }

    componentDidMount() {

        this.getData();

    }

    getData = async () => {

        const { openLoader, closeLoader } = this.props;

        try {

            openLoader();
            const referredUsers = await StatsModel.getReferredUsers();
            const challengesUsers = await StatsModel.getUsersChallenges();
            const freeConsultationUsers = await StatsModel.getFreeConsultationsUsers();

            this.setState({
                referredUsersFiltered: referredUsers?.data || [],
                referredUsers: referredUsers?.data || [],
                challengesUsers: challengesUsers?.data || [],
                challengesUsersFiltered: challengesUsers?.data || [],
                freeConsultationUsers: freeConsultationUsers?.data || [],
                freeConsultationUsersFiltered: freeConsultationUsers?.data || [],
            });


        } catch (error) {

            console.error(error);

        } finally {

            closeLoader();

        }

    }

    onFilterAtStartDate = startDate => this.setState({ startDate }, this.onFilter);

    onFilterAtEndDate = endDate => this.setState({ endDate }, this.onFilter);

    onFilter = () => {

        let referredUsersFiltered = [];
        let challengesUsersFiltered = [];
        let freeConsultationUsersFiltered = [];

        const { referredUsers, challengesUsers, freeConsultationUsers, startDate, endDate } = this.state;

        referredUsersFiltered = referredUsers.filter(({ createdAt }) => {

            if ((startDate && Moment(createdAt).startOf('day').isBefore(Moment(startDate))) || (endDate && Moment(createdAt).startOf('day').isAfter(Moment(endDate)))) {

                return false;

            }

            return true;


        });

        challengesUsersFiltered = challengesUsers.filter(({ createdAt }) => {

            if ((startDate && Moment(createdAt).startOf('day').isBefore(Moment(startDate))) || (endDate && Moment(createdAt).startOf('day').isAfter(Moment(endDate)))) {

                return false;

            }

            return true;


        });

        freeConsultationUsersFiltered = freeConsultationUsers.filter(({ createdAt }) => {

            if ((startDate && Moment(createdAt).startOf('day').isBefore(Moment(startDate))) || (endDate && Moment(createdAt).startOf('day').isAfter(Moment(endDate)))) {

                return false;

            }

            return true;


        });

        this.setState({ referredUsersFiltered, challengesUsersFiltered, freeConsultationUsersFiltered});

    }

    render() {

        const { referredUsersFiltered, challengesUsersFiltered, freeConsultationUsersFiltered } = this.state;

        return (
            <>
                <Form>
                    <Form.Group inline>
                         <Form.Field>
                                <label>Fecha de inicio</label>
                                <SemanticDatepicker onChange={ (e, data) => this.onFilterAtStartDate(data.value) } value={ this.state.startDate ? new Date(this.state.startDate) : this.state.startDate} format='DD/MM/YYYY' />
                            </Form.Field>
                            <Form.Field>
                                <label>Fecha final</label>
                                <SemanticDatepicker onChange={ (e, data) => this.onFilterAtEndDate(data.value) } value={ this.state.endDate ? new Date(this.state.endDate) : this.state.endDate } format='DD/MM/YYYY' />
                            </Form.Field>
                    </Form.Group>
                </Form>
                <Segment raised>
                    <Header as='h3'>Usuarios referidos</Header>
                    <div className="container-stats">
                        <div className="block-stats" >
                            <LineGraphContainer data={referredUsersFiltered} />
                        </div>
                        <div className="block-stats" >
                            <PieGraphContainer data={referredUsersFiltered} options={pieGenderOptions}/>
                        </div>
                        <div className="block-stats" >
                            <MapGraphContainer data={referredUsersFiltered} legend="Usuarios referidos" />
                        </div>
                    </div>
                </Segment>
                <Segment raised>
                    <Header as='h3'>Usuarios en modo reto</Header>
                    <div className="container-stats">
                        <div className="block-stats">
                            <LineGraphContainer data={challengesUsersFiltered} />
                        </div>
                        <div className="block-stats">
                            <PieGraphContainer data={challengesUsersFiltered} options={pieGenderOptions}/>
                        </div>
                        <div className="block-stats">
                            <MapGraphContainer data={challengesUsersFiltered} legend="Usuarios en modo reto"/>
                        </div>
                        <div className="block-stats">
                            <PieGraphContainer data={challengesUsersFiltered} options={modeOptions}/>
                        </div>
                    </div>
                </Segment>
                <Segment raised>
                    <Header as='h3'>Personas con consulta gratis pendientes de cobrar</Header>
                    <div className="container-stats">
                        <div className="block-stats">
                            <LineGraphContainer data={freeConsultationUsersFiltered} />
                        </div>
                        <div className="block-stats">
                            <PieGraphContainer data={freeConsultationUsersFiltered} options={pieGenderOptions}/>
                        </div>
                        <div className="block-stats">
                            <MapGraphContainer data={freeConsultationUsersFiltered} legend="Personas con consulta gratis"/>
                        </div>
                    </div>
                </Segment>
            </>
        );

    }

}

export default connect(null, { openLoader, closeLoader })(AdvisorStats);