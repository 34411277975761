import _ from 'lodash';
import React, { Component } from 'react'
import { Radio } from 'semantic-ui-react';

class CustomCheckBox extends Component {

    render() {

        const { value = false } = this.props;

        return (
            <Radio
                className={ _.get(this.props, 'classes', '') }
                label={ this.props.label }
                value={ value }
                checked={ this.props.input.value == value }
                onChange={ (e, { value }) => { this.props.input.onChange(value) } }
            />
        )
    }
}

export default CustomCheckBox