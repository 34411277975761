import { connect } from "react-redux";
import React from "react";
import { Segment, Header, Table, Icon, Pagination, Menu, Form, Input } from "semantic-ui-react";
import SessionModel from "../../../../../data/models/sessions";
import { openLoader, closeLoader } from "../../../../../redux-store/loader";
import { openModal , closeModal } from '../../../../../redux-store/modal';
import History from '../../../../../history';
import I18n from "../../../../../i18n";
import _ from 'lodash';

class Asanas extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
            asanas: [],
            filteredAsanas: [],
			pageNumber: 1,
            recordsPerPage: 10,
            activePage:1,
            search: '',
            searchCode: '',
            totalPages: 0,
            column: '',
            direction: null,
        };

	}

	componentDidMount() {

        this.getAsanas();

	}

	getAsanas = async () => {

        const { closeLoader, openLoader, openModal, closeModal } = this.props;

        const { recordsPerPage } = this.state;

        let message = {
            headerTitle : "Error",
            title : I18n.t('messages.modal.commonError'),
            buttons : [

                {
                    text : I18n.t('actions.accept'),
                    callback : () => closeModal()

                }

            ]

        };

		try {

			openLoader();
            const asanas = await SessionModel.getAsanas();
			this.setState({ asanas: asanas.data, filteredAsanas: _.orderBy(asanas.data, ['name.ES'], ['asc']), totalPages: Math.ceil(asanas.data.length / recordsPerPage) });

		} catch (error) {

            console.error("error getAsanas", error);
            closeLoader();
            openModal(message);

		} finally {

            closeLoader();

        }

	};

    onEditAsana = asanaId => History.push(`/home/asanas/edit/${asanaId}`);

    onPageChange = (e, pageInfo) => this.setState({activePage: pageInfo.activePage });

    paginate = () => this.state.filteredAsanas.slice((this.state.activePage - 1) * this.state.recordsPerPage, this.state.activePage * this.state.recordsPerPage);

    onFilterListByAsanaName = search => this.setState({ search }, () => this.onFilter());

    onFilterListByAsanaCode = searchCode => this.setState({ searchCode }, () => this.onFilter());

    onFilter = () => {

        let filter = this.state.asanas;

        const { recordsPerPage, search, searchCode } = this.state;

        if (search.length) {

            filter = filter.filter(asana => {

                if (_.get(asana,'name.ES', false)) {

                    const name = asana.name.ES.toLowerCase();
                    return name.indexOf(search.toLowerCase()) > -1

                }

                return false;

            });

        }

        if (searchCode.length) {

            filter = filter.filter(asana => {

                if (_.get(asana,'code', false)) {

                    const code = asana.code.toLowerCase();
                    return code.indexOf(searchCode.toLowerCase()) > -1

                }

                return false;

            });

        }

        this.setState({ filteredAsanas: filter, totalPages: Math.ceil(filter.length / recordsPerPage), activePage: 1 });

    }

    handleSort = clickedColumn => () => {

        const { column, filteredAsanas, direction } = this.state;

        if (column !== clickedColumn) {

            this.setState({
                column: clickedColumn,
                filteredAsanas: _.sortBy(filteredAsanas, [clickedColumn]),
                direction: 'ascending',
            })

            return;

        }

        this.setState({
            filteredAsanas: filteredAsanas.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending'
        });

    }

	renderList = () => {

		const { activePage, search, searchCode, totalPages, column, direction } = this.state;

		return (
            <React.Fragment>
                <Segment>
                    <div>
                        <Form>
                            <Form.Group inline>
                                <Form.Field>
                                    <label>Nombre de ásana</label>
                                    <Input value={ search } onChange={ e => this.onFilterListByAsanaName(e.target.value) } placeholder="Buscar por nombre de ásana"/>
                                </Form.Field>
                                <Form.Field>
                                    <label>Código de ásana</label>
                                    <Input value={ searchCode } onChange={ e => this.onFilterListByAsanaCode(e.target.value) } placeholder="Buscar por código de ásana"/>
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </div>
                </Segment>
                <Segment>
                    <Table celled selectable sortable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell collapsing>
                                    { I18n.t('practice.thumb') }
                                </Table.HeaderCell>
                                <Table.HeaderCell onClick={ this.handleSort('name.ES') } sorted={ column === 'name.ES' ? direction : null }>{ I18n.t('practice.code') }</Table.HeaderCell>
                                <Table.HeaderCell onClick={ this.handleSort('code') } sorted={ column === 'code' ? direction : null }>
                                    {I18n.t("practice.name")}
                                </Table.HeaderCell>
                                <Table.HeaderCell>{I18n.t("actions.edit")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.paginate().map(asana => (
                                <Table.Row key={asana._id} onClick={() => this.onEditAsana(asana._id)}>
                                    <Table.Cell collapsing><img style={{ maxWidth: '140px', width: '100%' }} src={ asana.image } alt="" /></Table.Cell>
                                    <Table.Cell >{asana.code}</Table.Cell>
                                    <Table.Cell >{asana.name.ES}</Table.Cell>
                                    <Table.Cell style={{ textAlign: "center" }}>
                                        <Icon className="c-pointer" name="edit" size="large"></Icon>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan={ 4 }>
                                    <Menu floated='right'>
                                        <Pagination
                                            onPageChange={this.onPageChange}
                                            totalPages={ totalPages }
                                            ellipsisItem={null}
                                            activePage={activePage}
                                            boundaryRange={0}

                                        />
                                    </Menu>
                                    <div style={{clear:"both"}}></div>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </Segment>
            </React.Fragment>
        );

	};

	render() {

		return (
			<React.Fragment>
				<Segment>
					<Header as="h3">{I18n.t("practice.asanaList")}</Header>
					{this.renderList()}
				</Segment>
			</React.Fragment>
		);
	}
}

export default connect(null, { openLoader, closeLoader, openModal , closeModal })(Asanas);