import SessionsModel from '../data/models/sessions';

//actions
const GET_SEQUENCES = 'GET_SEQUENCES';

export const getSequences = () => async dispatch => {

    try {
        
        const response = await SessionsModel.getSequences();
        dispatch ({ type: GET_SEQUENCES, payload: response.data });

    } catch (errorCode) {

        dispatch ({ type: GET_SEQUENCES, payload: {} });
        throw errorCode;

    }

};

const INITIAL_STATE = {
    
    sequences: []

};

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case GET_SEQUENCES:
            return { ...state, sequences: action.payload };   
        default:
            return state;
            
    }

};

export default reducer;