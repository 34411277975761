import _ from 'lodash'
import React, { Component } from 'react'
import PieGraphView from './view';

class PieGraphContainer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            transformedData: []
        }
    }

    componentDidMount() {

        this.transformData();

    }

    componentDidUpdate(oldProps) {

        if (!_.isEqual(oldProps.data, this.props.data)) {

            this.transformData();

        }

    }

    transformData = () => {

        const { data, options } = this.props;

        let transformedData = [];

        let base = {};

        _.each(options.categories, ({id, name}) => {
            base[id] = {
                category: name,
                value: 0
            }
        });

        //example
        //output a : { category : 'Femenino', value: 0 }
        //output b : { category : 'Masculino', value: 0 }

        _.each(data, item => {

            _.each(options.categories, category => {

                if (category.transform(item)) {

                    base[category.id].value++;
    
                }

            });

        });

        //example
        //output a : { category : 'Femenino', value: 50 }
        //output b : { category : 'Masculino', value: 70 }

        _.each(base, item => {

            transformedData.push(item);

        });

        //example
        //output [{ category : 'Femenino', value: 50 },{ category : 'Masculino', value: 0 }]

        this.setState({transformedData});

    }

    render() {

        const { transformedData } = this.state;
        
        return (
            <PieGraphView data={transformedData} />
        )
    }
}
export default PieGraphContainer;