import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { Header, Segment, Form, Button } from 'semantic-ui-react'
import { openLoader, closeLoader } from '../../../../../../redux-store/loader'
import { closeModal, openModal } from '../../../../../../redux-store/modal'
import I18n from '../../../../../../i18n';
import CustomInput from '../../../../../components/form/input'
import CustomTextArea from '../../../../../components/form/textarea'
import SecurityModel from '../../../../../../data/models/security/security'

class UserActions extends Component {
    constructor(props) {
        super(props)

        this.state = {
            text: '',
            subject: ''
        }
    }
    
    onSubmit = async ({ subject, text }) => {

        let payload = {
            template: "messageFromAdmin",
            subject: subject,
            text: text
        };
        
        let messageError = {
            headerTitle : "Error",
            title : I18n.t('messages.modal.commonError'),
            buttons : [
                
                {
                    text : I18n.t('actions.accept'),
                    callback : () => this.props.closeModal()
                
                }

            ]
        };

        let messageCorrect = {
            title : I18n.t('messages.modal.messageSent'),
            buttons : [
                
                {
                    text : I18n.t('actions.accept'),
                    callback : () => this.props.closeModal()
                
                }

            ]
        };

        try {

            this.props.openLoader();
            await SecurityModel.sendUserMessage(this.props.userid, payload);
            this.props.openModal(messageCorrect);

        }  catch (error) {
            
            this.props.closeLoader();
            this.props.openModal(messageError);

        } finally {

            this.props.closeLoader();

        }

    }   

    render() {

        return (
            <React.Fragment>
            <Segment>
                <Header as="h3">Envía un mensaje</Header>
                <Form onSubmit={this.props.handleSubmit(this.onSubmit)} name="sendForm">
                    <Field
                        component={ CustomInput }
                        placeholder={ I18n.t('user.info.subject') }
                        name="subject"
                        
                        label={ I18n.t('user.info.subject') } />
                    <Field
                        component={ CustomTextArea }
                        placeholder={ I18n.t('user.info.text') }
                        name="text"
                        
                        label={ I18n.t('user.info.text') } />
                    <Form.Field>
                        <Button floated='right' primary>{I18n.t('actions.send')}</Button>
                    </Form.Field>
                    <div style={{clear:"both"}}></div>
                </Form>
            </Segment>
            
        </React.Fragment>
        )
    }
}

const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.subject)) {

        errors.email = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.text)) {

        errors.password = I18n.t('validations.required');

    }

    return errors;

};

export default reduxForm({
    form: 'sendForm',
    touchOnBlur: true,
    touchOnChange: false,
    validate,
    initialValues: {
        subject: '',
        text: '',
    }
})(connect(null, { closeLoader, openLoader, closeModal, openModal })(UserActions));
