import Axios from 'axios';

const get = (apiEndpoint, requireToken) => Axios.get(apiEndpoint, getOptions(requireToken)).catch(err => { throw err.response && err.response.status; });
const getBlob = (apiEndpoint, requireToken) => Axios.get(apiEndpoint, getOptions(requireToken, true)).catch(err => { throw err.response && err.response.status; });
const post = (apiEndpoint, payload, requireToken) => Axios.post(apiEndpoint, payload, getOptions(requireToken)).catch(err => { throw err.response && err.response.status; });
const put = (apiEndpoint, payload, requireToken) => Axios.put(apiEndpoint, payload, getOptions(requireToken)).catch(err => { throw err.response && err.response.status; });
const deleting = (apiEndpoint, requireToken) => Axios.delete(apiEndpoint, getOptions(requireToken)).catch(err => { throw err.response });
const getOptions = (requireToken, isBlob) => {

    let options = {};
    if (requireToken && localStorage.getItem('token')) {

        options.headers = { 'x-access-token': localStorage.getItem('token') };

    }

    if (isBlob) {

        options.responseType = 'blob';

    }

    return options;

}

export const AxiosService = {
    get,
    getBlob,
    post,
    put,
    deleting
};