// Third party libraries
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, arrayPush, change } from 'redux-form';
import { Form, Segment, Header, Grid, Button, Table, Icon, Input, Select as SemanticSelect, Label} from 'semantic-ui-react';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

// Components
import CustomInput from '../../../../components/form/input';
import Wysiwyg from '../../../../components/form/Wysiwyg';
import CustomCheckBox from '../../../../components/form/checkbox';
import InputRadio from '../../../../components/form/inputRadio';
import Select from '../../../../components/form/select';
import TagList from '../../../../components/form/tagList';
import CheckboxList from '../../../../components/form/checkBoxList';
import CustomDatePicker from '../../../../components/form/datepicker';
import DefaultCheckBox from '../../../../components/form/defaultCheckbox';

// Validations
import { required, requiredArray } from '../../../../../config/validations';

// Models
import SessionModel from "../../../../../data/models/sessions";

// Locales
import I18n from '../../../../../i18n';

const DragHandle = SortableHandle(() => <Icon name='arrows alternate' />);

const SortableItem = SortableElement(({ value, idx, routineNames, routineImages, handleEditRoutine, handleRemoveRoutine, handleCloneRoutine }) => (
    <Table.Row>
        <Table.Cell textAlign='center'><DragHandle /></Table.Cell>
        <Table.Cell textAlign='center'>{ idx + 1 }</Table.Cell>
        <Table.Cell collapsing><img style={{ maxWidth: '140px', width: '100%' }} src={ routineImages[value.code] } alt="" /></Table.Cell>
        <Table.Cell>{ value.code }</Table.Cell>
        <Table.Cell>{ routineNames[value.code] }</Table.Cell>
        <Table.Cell textAlign='center'>{ value.duration }</Table.Cell>
        <Table.Cell textAlign='center'><Icon className="pointer" name="clone" size="large" onClick={ () => handleCloneRoutine(idx) }></Icon></Table.Cell>
        <Table.Cell textAlign='center'><Icon className="pointer" name="edit" size="large" onClick={ () => handleEditRoutine(idx) }></Icon></Table.Cell>
        <Table.Cell textAlign='center'><Icon className="pointer" name="eraser" size="large" onClick={ () => handleRemoveRoutine(idx) }></Icon></Table.Cell>
    </Table.Row>
));

const SortableList = SortableContainer(({items, routineNames, routineImages, handleEditRoutine, handleRemoveRoutine, handleCloneRoutine }) => (
    <Table celled selectable sortable>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>{ I18n.t('practice.sort') }</Table.HeaderCell>
                <Table.HeaderCell>{ I18n.t('practice.index') }</Table.HeaderCell>
                <Table.HeaderCell collapsing>{ I18n.t('practice.thumb') }</Table.HeaderCell>
                <Table.HeaderCell>{ I18n.t('practice.code') }</Table.HeaderCell>
                <Table.HeaderCell>{ I18n.t('practice.name') }</Table.HeaderCell>
                <Table.HeaderCell>{ I18n.t('practice.duration') }</Table.HeaderCell>
                <Table.HeaderCell>{ I18n.t('actions.cloneAsana') }</Table.HeaderCell>
                <Table.HeaderCell>{ I18n.t('actions.edit') }</Table.HeaderCell>
                <Table.HeaderCell>{ I18n.t('actions.remove') }</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            { items.map((item, index) => <SortableItem key={ `item-${ index }` } index={ index } idx={ index } value={ item } routineImages={ routineImages } routineNames={ routineNames } handleEditRoutine={ handleEditRoutine } handleRemoveRoutine={ handleRemoveRoutine } handleCloneRoutine={ handleCloneRoutine } />)}
        </Table.Body>
    </Table>
));

class SequenceForm extends Component {

    constructor(props) {

        super(props);

        this.state = {
            routineIndexToEdit: -1,
            routineFields : {},
            activeRoutineform: false,
            practiceTypesOptions: {
                a: [{
                    value: 'regular'
                }, {
                    value: 'therapy'
                }, {
                    value: 'restorative'
                }],
                b: [{
                    value: 'ascendent'
                }, {
                    value: 'descendent'
                }],
                c: [{
                    value: 'dynamic'
                }, {
                    value: 'static'
                }],
                d: [{
                    value: 'unabridged'
                }, {
                    value: 'mixed'
                }]
            },
            practiceLevels: [{
                key: 1,
                text: "Principiante",
                value: '01'
            }, {
                key: 2,
                text: "Avanzado",
                value: '00'
            }, {
                key: 3,
                text: "Con soporte",
                value: 'CS'
            }]
        }
    }

    onSubmit = async data => {

        const tempForm = { ...data };
        const self = this;
        const message = {
            headerTitle: "Error",
            title: '',
            buttons: [{
                text: I18n.t('actions.accept'),
                callback: () => self.props.closeModal()
            }]
        };

        const durationSum = _.reduce(_.map(this.props.reduxFormState.routine, ({ duration }) => +duration), (sum, n) => sum + n, 0);
        tempForm.duration = this.setSequenceDuration(durationSum);

        /**
         * ! Api not allow null values only string or undefined
        */
        if (!tempForm.startDate) {

            delete tempForm.startDate;

        }

        if (!tempForm.endDate) {

            delete tempForm.endDate;

        }

        if (typeof tempForm.order !== 'undefined') {

            tempForm.order = parseInt(tempForm.order);

        }

        try {

            this.props.openLoader();

            if (tempForm._id) {

                await SessionModel.editSequence(tempForm._id, _.omit(tempForm, ['_id']));

            } else {

                await SessionModel.createSequence(tempForm);

            }

            this.props.history.push('/home/sequence');

        } catch (error) {

            message.title = I18n.t('practice.anErrorOcurred') + ' ' + _.get(error, 'error', error);
            this.props.openModal(message);

        } finally {

            this.props.closeLoader();

        }

    }

    onRemoveType = index => this.props.change(`types[${ index }]`, 'none');

    onCloneRoutine = idx => this.props.dispatch(arrayPush('sequenceForm', 'routine', { ...this.props.reduxFormState.routine[idx] }));

    onEditRoutine = idx => this.setState({ editingRoutineIndex: idx, activeRoutineform: true, routineFields: { ...this.props.reduxFormState.routine[idx] } });

    onRemoveRoutine = idx => {

        const tempState = { ...this.props.reduxFormState };
        tempState.routine.splice(idx, 1);
        this.props.dispatch(change('sequenceForm', 'routine', tempState.routine ));

    }

    onSortEnd = ({oldIndex, newIndex}) => {

        const tempState = { ...this.props.reduxFormState };
        tempState.routine = arrayMove(tempState.routine, oldIndex, newIndex);
        this.props.dispatch(change('sequenceForm', 'routine', tempState.routine ));

    };

    onShowRoutineForm = () => this.setState({ activeRoutineform: true, routineFields: { duration: 30, code: '' } });

    onChangeRoutineFiledsValues = (value, path) => {

        const routineFields = { ...this.state.routineFields };

        routineFields[path] = path === 'duration' ? +value : value;
        this.setState({ routineFields });

    }

    onSaveRoutineForm = () => {

        if (this.state.editingRoutineIndex > -1) {

            this.props.dispatch(change('sequenceForm',`routine[${this.state.editingRoutineIndex}]`, { ...this.state.routineFields }));

        } else {

            this.props.dispatch(arrayPush('sequenceForm', 'routine', { ...this.state.routineFields } ));

        }

        this.setState({ activeRoutineform: false, editingRoutineIndex: -1 });

    }

    setSequenceDuration = value => {

        if (Math.round(value / 60) > 25 && Math.round(value / 60) <= 45) {

            return 0;

        } else if (Math.round(value / 60) > 45) {

            return 1;

        }

        return -1;

    }

    convertTime = seconds => {

        if (typeof seconds !== 'undefined') {

            seconds = Number(seconds);
            var h = Math.floor(seconds / 3600);
            var m = Math.floor(seconds % 3600 / 60);
            var s = Math.floor(seconds % 3600 % 60);
            return ('0' + h).slice(-2) + ":" + ('0' + m).slice(-2) + ":" + ('0' + s).slice(-2);

        }

        return '';

    }

    onPreview = code => {

        const message = {
            title: I18n.t('practice.previewAlert'),
            buttons: [{
                text: I18n.t('actions.accept'),
                callback : async () => {

                    this.props.closeModal();
                    this.props.openLoader();

                    const sequenceInfo = await SessionModel.getSequence(code, 'SES');

                    this.props.closeLoader();
                    this.props.openSequencePreviewModal(sequenceInfo.data);

                }
            }, {
                text: I18n.t('actions.cancel'),
                callback: this.props.closeModal
            }]
        };

        this.props.openModal(message);

    };


    renderRoutineList = () => {

        const { routineNames, routineImages, reduxFormState } = this.props;

        return (
            <SortableList
                helperClass="dragging-helper-class"
                useDragHandle
                handleCloneRoutine={ idx => this.onCloneRoutine(idx) }
                handleEditRoutine={ idx =>this.onEditRoutine(idx) }
                handleRemoveRoutine={ idx => this.onRemoveRoutine(idx) }
                items={ reduxFormState.routine }
                onSortEnd={ this.onSortEnd }
                routineImages={ routineImages }
                routineNames={ routineNames }
            />
        );

    }

    onChangeProgram = value => {

        if (value === 'none') {

            this.props.dispatch(change('sequenceForm', 'onlyProgram', false));

        }

    }

    render() {

        const { practiceLevels, practiceTypesOptions, routineFields, activeRoutineform } = this.state;
        const { reduxFormState, categories, beginnerAsanas, advancedAsanas, programs, withSupportAsanas } = this.props;
        const durationSum = _.reduce(_.map(reduxFormState.routine, ({ duration }) => +duration), (sum, n) => sum + n, 0);

        return (
            <Form noValidate onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <Segment id="general">
                    <Header as='h3'>{ I18n.t('practice.general') }</Header>
                    <Label as='a' color='blue' ribbon>{ I18n.t('practice.sequence') + ': ' + I18n.t(`practice.#${this.setSequenceDuration(durationSum)}`) } ({ this.convertTime(durationSum) })</Label>
                    <p>&nbsp;</p>
                    <Form.Group inline>
                        <Field
                            component={ CustomCheckBox }
                            name="public"
                            label={ I18n.t('practice.public') }
                            props={{ value: 1 }}
                        />
                        &nbsp;
                        &nbsp;
                        <Field
                            component={ CustomCheckBox }
                            name="public"
                            label={ I18n.t('practice.premium') }
                            props={{ value: 0 }}
                        />
                        &nbsp;
                        &nbsp;
                        <Field
                            component={ CustomCheckBox }
                            name="public"
                            label={ I18n.t('practice.private') }
                            props={{ value: 2 }}
                        />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('practice.title') }
                            name="title.ES"
                            label={ I18n.t('practice.title') }
                            validate={ [required] }
                        />
                        <Field
                            component={ Select }
                            name="level"
                            label={ I18n.t('practice.level') }
                            options={ practiceLevels }
                            disabled={ _.get(reduxFormState, 'routine.length', 0) > 0 }
                        />
                    </Form.Group>
                        <Field
                            component={ Wysiwyg }
                            name="description.ES"
                            label={ I18n.t('practice.description') }
                            validate={ [required] }
                        />
                    <Form.Group widths='equal'>
                        <Field
                            component={ TagList }
                            name="tags"
                            label={ I18n.t('practice.addTags') }
                            placeholder={ I18n.t('practice.addTag') }
                            validate={ [requiredArray] }
                        />
                        <Field
                            component={ TagList }
                            name="targets"
                            label={ I18n.t('practice.addTargets') }
                            placeholder={ I18n.t('practice.addTargets') }
                            validate={ [requiredArray] }
                        />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Field
                                component={ CustomDatePicker }
                                name="startDate"
                                label={ I18n.t('practice.startDate') }
                                formatDate={ 'DD/MM/YYYY' }
                            />
                        </Form.Field>
                        <Form.Field>
                            <Field
                                component={ CustomDatePicker }
                                name="endDate"
                                label={ I18n.t('practice.endDate') }
                                formatDate={ 'DD/MM/YYYY' }
                            />
                        </Form.Field>
                    </Form.Group>
                </Segment>
                <Segment id="programs">
                    <Header as='h3'>{ I18n.t('practice.programs') }</Header>
                        <Form.Field>
                            <Field
                                component={ Select }
                                name="programId"
                                label={ I18n.t('practice.selectProgram') }
                                options={ programs }
                                onChange={this.onChangeProgram}
                            />
                        </Form.Field>
                        <Form.Field>
                            { (reduxFormState.programId && reduxFormState.programId !== 'none') && (
                                <Field
                                    component={ DefaultCheckBox }
                                    name="onlyProgram"
                                    label={ I18n.t('practice.onlyProgram') }
                                />
                            ) }
                        </Form.Field>
                        <Form.Field>
                            { (reduxFormState.programId && reduxFormState.programId !== 'none') && (
                                <Field
                                    component={ CustomInput }
                                    placeholder={ I18n.t('practice.order') }
                                    name="order"
                                    label={ I18n.t('practice.order') }
                                    validate={ [required] }
                                    restrictions={ [{ numeric: true, canBeFloat: false }] }
                                />
                            ) }
                        </Form.Field>
                </Segment>
                <Segment id="types">
                    <Header as='h3'>{ I18n.t('practice.practiceType') }</Header>
                    <Grid columns='equal' stackable>
                        <Grid.Column>
                            <Segment>
                                <Form.Field>
                                    <label>{ I18n.t('practice.groupOne') }</label>
                                    <Field
                                        component={ InputRadio }
                                        options={ practiceTypesOptions['a'] }
                                        name="types[0]"
                                        label={I18n.t('practice.groupOne')}
                                    />
                                </Form.Field>
                                <Button type="button" floated="right" content='borrar' icon='close' labelPosition='right' onClick={ () => this.onRemoveType(0) } size="mini" />
                                <div style={{ clear: "both" }}></div>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment>
                                <Form.Field>
                                    <label>{ I18n.t('practice.groupTwo') }</label>
                                    <Field
                                        component={ InputRadio }
                                        options={ practiceTypesOptions['b'] }
                                        name="types[1]"
                                    />
                                </Form.Field>
                                <Button type="button" floated="right" content='borrar' icon='close' labelPosition='right' onClick={ () => this.onRemoveType(1) } size="mini" />
                                <div style={{ clear: "both" }}></div>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment>
                                <Form.Field>
                                    <label>{ I18n.t('practice.groupThree') }</label>
                                    <Field
                                        component={ InputRadio }
                                        options={ practiceTypesOptions['c'] }
                                        name="types[2]"
                                    />
                                </Form.Field>
                                <Button type="button" floated="right" content='borrar' icon='close' labelPosition='right' onClick={ () => this.onRemoveType(2) } size="mini" />
                                <div style={{ clear: "both" }}></div>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment>
                                <Form.Field>
                                    <label>{ I18n.t('practice.groupFour') }</label>
                                    <Field
                                        component={ InputRadio }
                                        options={ practiceTypesOptions['d'] }
                                        name="types[3]"
                                    />
                                </Form.Field>
                                <Button type="button" floated="right" content='borrar' icon='close' labelPosition='right' onClick={ () => this.onRemoveType(3) } size="mini" />
                                <div style={{ clear: "both" }}></div>
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </Segment>
                <Segment id="categories">
                    <Header as='h3'>{ I18n.t('practice.categories') }</Header>
                    <Field
                        component={ CheckboxList }
                        options={ categories }
                        name="categories"
                        validate={ [requiredArray] }
                    />
                </Segment>
                <Segment id="resources">
                    <Header as='h3'>{ I18n.t('practice.resources') }</Header>
                    <Form.Group widths='equal'>
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('practice.thumbUrl') }
                            name="thumbUrl"
                            label={ I18n.t('practice.thumbUrl') }
                            validate={ [required] }
                        />
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('practice.imageUrl') }
                            name="imageUrl"
                            label={ I18n.t('practice.imageUrl') }
                            validate={ [required] }
                        />
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('practice.videoUrl') }
                            name="videoUrl"
                            label={ I18n.t('practice.videoUrl') }
                            validate={ [required] }
                        />
                    </Form.Group>
                </Segment>
                <Segment id="routines">
                    <Header as='h3'>{ I18n.t('practice.routines') }</Header>
                    { reduxFormState._id && <Label as='a' color='teal' ribbon='right' onClick={ () => this.onPreview(reduxFormState.code) }><Icon name='play' />{ I18n.t('practice.preview') }</Label> }
                    { reduxFormState.routine.length ? this.renderRoutineList() : '' }
                    { activeRoutineform && <Segment id="routines-form">
                        <Form.Field>
                            <label>{ I18n.t('practice.duration') }(segundos)</label>
                            <Input name="duration" placeholder={ I18n.t('practice.duration') } value={ routineFields.duration } onChange={ (e, { value }) => this.onChangeRoutineFiledsValues(value, 'duration') } />
                        </Form.Field>
                        <Form.Field>
                            <label>{ I18n.t('practice.asanas') }</label>
                            <SemanticSelect search placeholder={ I18n.t('practice.asanas') } value={ routineFields.code } name="code" options={ reduxFormState.level === '01' ? beginnerAsanas : reduxFormState.level === '00' ? advancedAsanas : withSupportAsanas } onChange={ (e, { value }) => this.onChangeRoutineFiledsValues(value, 'code') } />
                        </Form.Field>
                        <Button floated='right' type="button" content='Guardar' icon='save' labelPosition='right' onClick={ this.onSaveRoutineForm } size="mini" />
                        <div style={{ clear: "both" }}></div>
                    </Segment>}
                    <Button disabled={activeRoutineform} floated="right" type='button' content='Añadir ásana' icon='plus' labelPosition='right' onClick={ this.onShowRoutineForm } size="mini" />
                    <div style={{ clear: "both" }}></div>
                </Segment>
                <Button floated="right" type='submit' primary>{ I18n.t('actions.save') }</Button>
                <Button floated="right" type='button' onClick={ () => this.props.history.push('/home/sequence') }>{ I18n.t('actions.cancel') }</Button>
                <div style={{ clear: "both" }}></div>
            </Form>
        );

    }

}

const validate = () => {

    return {};

};

SequenceForm = connect(state => {

    const reduxFormState = _.cloneDeep(_.get(state, 'form.sequenceForm.values', {}));

    return {
        reduxFormState
    };

})(SequenceForm);

export default reduxForm({
    form: 'sequenceForm',
    touchOnBlur: true,
    touchOnChange: true,
    validate,
})(connect(null, {})(SequenceForm));
