// Types
const OPEN_SEQUENCE_PREVIEW_MODAL = 'OPEN_SEQUENCE_PREVIEW_MODAL';
const CLOSE_SEQUENCE_PREVIEW_MODAL = 'CLOSE_SEQUENCE_PREVIEW_MODAL';

// Actions
export const openSequencePreviewModal = data => { return { type: OPEN_SEQUENCE_PREVIEW_MODAL, payload: { open: true, data} } };
export const closeSequencePreviewModal = () => { return { type: CLOSE_SEQUENCE_PREVIEW_MODAL, payload: { open: false, data: {} } } };

// Reducer
const INITIAL_STATE = {
    open: false
};

function reducer (state = INITIAL_STATE, action) {

    switch (action.type) {

        case OPEN_SEQUENCE_PREVIEW_MODAL:
        case CLOSE_SEQUENCE_PREVIEW_MODAL:

            return { ...action.payload };

        default:

            return state;

    }

};

export default reducer; 