import React, { Component } from 'react'
import SecurityModel from '../../../../../../data/models/security/security'
import I18n from '../../../../../../i18n'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Header, Segment, Table } from 'semantic-ui-react'
import moment from 'moment'
import { openLoader, closeLoader } from '../../../../../../redux-store/loader'
import { closeModal, openModal } from '../../../../../../redux-store/modal'

class UserAccess extends Component {
    constructor(props) {
        super(props)

        this.state = {
            column: 'created',
            direction: 'descending',
            originalData: [],
            userAccess: []
        }
    }

    componentDidMount(){

        this.onGetUserAccess();

    }

    
    onGetUserAccess = async () => {

        let message = {
            headerTitle : "Error",
            title : I18n.t('messages.modal.commonError'),
            buttons : [
                
                {
                    text : I18n.t('actions.accept'),
                    callback : () => this.props.closeModal()
                
                }

            ]
        };

        try {
           
            this.props.openLoader();
            const responseAccess = await SecurityModel.getUserAccess(this.props.userid);

            if (responseAccess.data) {

                this.setState({ userAccess: _.orderBy(responseAccess.data, ['created'], ['desc'] ), originalData: responseAccess.data});

            }
            
        } catch (error) {
            
            this.props.closeLoader();
            this.props.openModal(message);

        } finally {

            this.props.closeLoader();

        }

    }

    handleSort = (clickedColumn) => () => {
        
        const { column, direction, originalData } = this.state;
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            userAccess: _.orderBy(originalData, [clickedColumn], ['asc']),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
        userAccess: _.orderBy(originalData, [clickedColumn], [direction === 'ascending' ? 'desc' : 'asc']),
          direction: direction === 'ascending' ? 'descending' : 'ascending',
        });

      }


    render() {

        const  { userAccess, column, direction } = this.state;


        return (
            <Segment>
                <Header as="h3">Accesos a la web</Header>
                <Table celled selectable sortable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell onClick={this.handleSort('created')} sorted={column === 'created' ? direction : null}>{ I18n.t('user.info.accessDate') }</Table.HeaderCell>
                            <Table.HeaderCell >{ I18n.t('user.info.action') }</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    { userAccess.map(access => (
                        <Table.Row key={ access._id } >
                            <Table.Cell>{ moment(access.created).format('DD/MM/YYYY HH:mm:ss') }</Table.Cell>
                            <Table.Cell>{ access.action }</Table.Cell>
                        </Table.Row>
                    ))}
                    </Table.Body>
                </Table>
            </Segment>
        )
    }
}

export default connect(null, {openLoader, closeLoader, closeModal, openModal })(UserAccess);