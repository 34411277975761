import { AxiosService } from '../../../utils/AxiosService';
import Config from '../../../config';

const getUserQuestionsToAdmin = async () => await AxiosService.get(Config.server.api.questionApi  + 'admin', {}, !!window.localStorage.getItem('token'));
const getCategories = async () => await AxiosService.get(Config.server.api.questionApi  + 'category', {}, !!window.localStorage.getItem('token'));
const createCategory = async payload => await AxiosService.post(Config.server.api.questionApi  + 'category', {...payload}, !!window.localStorage.getItem('token'));
const answerToUser = async payload => await AxiosService.put(Config.server.api.questionApi  + `answer/${payload._id}`, {...payload}, !!window.localStorage.getItem('token'));

export default {
    answerToUser,
    createCategory,
    getUserQuestionsToAdmin,
    getCategories
}