import React, { Component } from 'react'
import _ from 'lodash'
import { Segment, Header, Table, Icon, Button, Checkbox } from 'semantic-ui-react'
import I18n from '../../../i18n'
import ProductModel from '../../../data/models/product'
import { connect } from 'react-redux'
import { openLoader, closeLoader } from '../../../redux-store/loader'
import { closeModal, openModal } from '../../../redux-store/modal'
import { openDiscountModal, closeDiscountModal } from '../../../redux-store/discountModal';

class StudioList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
            studioAsanas: [],
            indexedAsanas: {},
            allSelected: false
        }
        this.errorMessage = {
            headerTitle: "Error",
            title: I18n.t('messages.modal.commonError'),
            buttons: [{
                text : I18n.t('actions.accept'),
                callback : () => this.props.closeModal()
            }]
        };
    }


    componentDidMount(){

        this.getStudioAsanas();

    }

    getStudioAsanas = async () => {

        let indexedAsanas = {};

        try {

            this.props.openLoader();
            const products = await ProductModel.getProducts();

            indexedAsanas = _.reduce(products.data, (acc, el) => ({
                ...acc,
                [el._id]: {
                    selected: false,
                    _id: el._id
                },
            }), {});

            this.setState({studioAsanas: products.data, indexedAsanas })
            
        } catch (error) {
            
            this.props.openModal(this.errorMessage);

        } finally {

            this.props.closeLoader();

        }

    }

    removeStudioAsanaPrompt = asanaId => {

        let self = this;
        let onRemoveMessage = {
            title : I18n.t('studio.removeStudioAsana'),
            buttons : [{
                text : I18n.t('actions.accept'),
                callback : async () => {
                    
                    self.onRemoveStudioAsana(asanaId); self.props.closeModal();
                
                }
            },
            {
                text : I18n.t('actions.cancel'),
                callback : () => self.props.closeModal()
            }]
        };

        this.props.openModal(onRemoveMessage);

    }

    onRemoveStudioAsana = async asanaId => {

        this.errorMessageOnRemove = {
            headerTitle: "Error",
            title: I18n.t('messages.modal.commonError'),
            buttons: [{
                text : I18n.t('actions.accept'),
                callback : () => this.props.closeModal()
            }]
        };

        try {

            this.props.openLoader();
            await ProductModel.deleteProduct(asanaId);

        } catch (error) {

            if (_.get(error, 'status', '') === 500) {

                if (_.get(error, 'data.error', '').indexOf('related')>-1 ) {
                    this.errorMessageOnRemove.title = I18n.t('messages.modal.errorProductRelated')
                }

                if (_.get(error, 'data.error', '').indexOf('buyed')>-1 ) {
                    this.errorMessageOnRemove.title = I18n.t('messages.modal.errorProductBuyed')
                }
                
            }
            
            this.props.openModal(this.errorMessageOnRemove);

        } finally {

            this.props.closeLoader();
            this.getStudioAsanas();

        }

    }

    onAddStudioAsana = () => this.props.history.push(`/home/studio/edit`);

    onEditStudioAsana = asanaId => this.props.history.push(`/home/studio/edit/${asanaId}`);

    onPublish = async asanaId => {
        
        this.props.openLoader();

        try {

            await ProductModel.publishProduct(asanaId);

        } catch (error) {

            this.props.openModal(this.errorMessage);

        } finally {

            this.props.closeLoader();
            this.getStudioAsanas();

        }

    }

    onSelectAll = checked => {

        let indexedAsanas = _.cloneDeep(this.state.indexedAsanas);
        _.each(indexedAsanas, item => {
            item.selected = checked
        });

        this.setState({allSelected: checked, indexedAsanas});
    
    };

    onSelectAsana = (asanaId, checked) => {

        let indexedAsanas = _.cloneDeep(this.state.indexedAsanas);
        indexedAsanas[asanaId].selected = checked;
        this.setState({indexedAsanas});
    
    }

    onApplyDiscount = () => {

        const { openDiscountModal, closeDiscountModal } = this.props;

        let message = {
            headerTitle: I18n.t('studio.asanaDiscount'),
            submitCallback: data => {

                this.applyDiscount(data);
                closeDiscountModal();

            }
        }

        openDiscountModal(message);

    }

    applyDiscount = async ({discount, discountExpiration }) => {

        const { openLoader, closeLoader, openModal, closeModal } = this.props;
        const { indexedAsanas } = this.state;

        let hasError = false;
        let errorMessageOnApplyDiscount = {
            headerTitle: "Error",
            title: I18n.t('messages.modal.commonError'),
            buttons: [{
                text : I18n.t('actions.accept'),
                callback : () => closeModal()
            }]
        };

        let schoolProductIds = _.reduce(indexedAsanas, (result, item) => {
            
            if (item.selected) {
                result.push(item._id)
            }

            return result;

        }, []);

        try {
            
            openLoader();
            await ProductModel.applyGlobalDiscountToProducts({ schoolProductIds, discount, discountExpiration : discountExpiration === null ? '' : discountExpiration});

        } catch (error) {

            hasError = true;

        } finally {

            closeLoader();
            
            if (hasError) {

                openModal(errorMessageOnApplyDiscount);

            }

            this.onSelectAll(false);

        }

    }

    render() {

        const { studioAsanas, allSelected, indexedAsanas } = this.state;

        const anySelected = _.filter(indexedAsanas, ({selected}) => selected);

        return (
            <Segment>
                <Segment>
                <Header as="h3">{I18n.t('studio.list')}</Header>
                    {anySelected.length > 0 && <Button type='button' floated='right' primary onClick={this.onApplyDiscount}>{I18n.t('studio.linkDiscountProductsSelected')}</Button>}
                <div style={{ clear: "both" }}></div>
                </Segment>
                
                <Table celled selectable sortable >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                <Checkbox
                                    label="Todos" 
                                    onClick={ (e, v) => { this.onSelectAll(v.checked) } }
                                    checked={ allSelected } />
                            </Table.HeaderCell>
                            <Table.HeaderCell>{ I18n.t('studio.image') }</Table.HeaderCell>
                            <Table.HeaderCell>{ I18n.t('studio.title') }</Table.HeaderCell>
                            <Table.HeaderCell>{ I18n.t('studio.subtitle') }</Table.HeaderCell>
                            <Table.HeaderCell>{ I18n.t('practice.publish') }</Table.HeaderCell>
                            <Table.HeaderCell>{ I18n.t('actions.edit') }</Table.HeaderCell>
                            <Table.HeaderCell>{ I18n.t('actions.remove') }</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        { (studioAsanas || []).map(asana => (
                            <Table.Row key={asana._id}>
                                <Table.Cell collapsing>
                                    <Checkbox
                                        onClick={ (e, v) => { this.onSelectAsana(asana._id, v.checked) } }
                                        checked={ indexedAsanas[asana._id].selected } />
                                    </Table.Cell>
                                <Table.Cell >
                                    <img src={asana.imageUrl} alt="" style={{width: '150px'}}/>
                                </Table.Cell>
                                <Table.Cell>{asana.title}</Table.Cell>
                                <Table.Cell>{asana.subtitle}</Table.Cell>
                                <Table.Cell textAlign='center'><Icon className="pointer" name={ asana.published ? 'eye' : 'eye slash' } size="large" onClick={ () => this.onPublish(asana._id) }></Icon></Table.Cell>
                                <Table.Cell textAlign='center'><Icon className="pointer" name="edit" size="large" onClick={ () => this.onEditStudioAsana(asana._id) }></Icon></Table.Cell>
                                <Table.Cell textAlign='center'><Icon className="pointer" name="eraser" size="large" onClick={ () => this.removeStudioAsanaPrompt(asana._id) }></Icon></Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                    <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell colSpan='6'>
                                <Button type='button' floated='right' primary onClick={this.onAddStudioAsana}>{I18n.t('studio.addStudioAsana')}</Button>
                                <div style={{ clear: "both" }}></div>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </Segment>
        )
    }
}

export default connect(null, { openLoader, closeLoader, closeModal, openModal, openDiscountModal, closeDiscountModal })(StudioList)
