import React, { Component } from 'react';
import I18n from '../../../../../i18n';
import { connect } from 'react-redux';
import { closeModal, openModal } from '../../../../../redux-store/modal';
import { openLoader, closeLoader } from '../../../../../redux-store/loader';
import SessionModel from '../../../../../data/models/sessions';
import { Segment, Header, Table, Button, Icon } from 'semantic-ui-react';
import History from '../../../../../history';

class ProgramList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            programs: []
        }
        this.errorMessage = {
            headerTitle: "Error",
            title: I18n.t('messages.modal.commonError'),
            buttons: [{
                text : I18n.t('actions.accept'),
                callback : () => this.props.closeModal()
            }]
        };

    }


    componentDidMount() {

        this.getPrograms();

    }

    getPrograms = async () => {


        try {

            this.props.openLoader();
            const programs = await SessionModel.getPrograms();
            this.setState({ programs : programs.data });
            
        } catch (error) {

            this.props.openModal(this.errorMessage);
            
        } finally {

            this.props.closeLoader();

        }

    }

    onEditProgram = programId => History.push(`/home/sequence/program/edit/${ programId }`);

    onAddProgram = () => History.push(`/home/sequence/program/edit/`);

    onRemoveProgram = programId => {

        let self = this;
        let message = {
            title : I18n.t('program.removeProgram'),
            buttons : [{
                text : I18n.t('actions.accept'),
                callback : async () => {
                    
                    self.removeProgram(programId); self.props.closeModal();
                
                }
            },
            {
                text : I18n.t('actions.cancel'),
                callback : () => self.props.closeModal()
            }]
        };

        this.props.openModal(message);

    }

    removeProgram = async programId => {

        try {

            this.props.openLoader();

            await SessionModel.deleteProgram(programId);
            
        } catch (error) {

            this.props.openModal(this.errorMessage);
            
        } finally {

            this.props.closeLoader();
            this.getPrograms();

        }

    }

    onPublish = async programId => {

        this.props.openLoader();

        try {

            await SessionModel.publishProgram(programId);
            
        } catch (error) {

            this.props.openModal(this.errorMessage);

        } finally {

            this.getPrograms();
            this.props.closeLoader();

        }

    }

    render() {

        const { programs } = this.state;

        return (
            <Segment>
                <Header as='h3'>{ I18n.t('program.programList') }</Header>
                <Table celled selectable sortable >
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell collapsing>{ I18n.t('practice.thumb') }</Table.HeaderCell>
                        <Table.HeaderCell >{ I18n.t('practice.title') }</Table.HeaderCell>
                        <Table.HeaderCell>{ I18n.t('practice.publish') }</Table.HeaderCell>
                        <Table.HeaderCell>{ I18n.t('actions.edit') }</Table.HeaderCell>
                        <Table.HeaderCell>{ I18n.t('actions.remove') }</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    { (programs).map(program => (
                        <Table.Row key={program._id}>
                             <Table.Cell collapsing>
                                <img src={program.thumbUrl} alt="" style={{width: '150px'}}/>
                            </Table.Cell>
                            <Table.Cell>{program.title['ES']}</Table.Cell>
                            <Table.Cell textAlign='center'><Icon className="pointer" name={ program.published ? 'eye' : 'eye slash' } size="large" onClick={ () => this.onPublish(program._id) }></Icon></Table.Cell>
                            <Table.Cell textAlign='center'><Icon className="pointer" name="edit" size="large" onClick={ () => this.onEditProgram(program._id) }></Icon></Table.Cell>
                            <Table.Cell textAlign='center'><Icon className="pointer" name="eraser" size="large" onClick={ () => this.onRemoveProgram(program._id) }></Icon></Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
                <Table.Footer fullWidth>
                    <Table.Row>
                        <Table.HeaderCell colSpan='9'>
                            <Button type='button' floated='right' primary onClick={this.onAddProgram}>{I18n.t('program.addProgram')}</Button>
                            <div style={{ clear: "both" }}></div>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
            </Segment>
        );
    }
}

export default connect(null, { closeModal, openModal, openLoader, closeLoader })(ProgramList);