import _ from 'lodash'
import React, { Component } from 'react'
import MapGraphView from './view';
import {countries as mappedCountries} from '../../../../../user/list/countries'

class MapGraphContainer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            transformedData: []
        }
    }

    componentDidMount() {

        this.transformData();

    }

    componentDidUpdate(oldProps) {

        if (!_.isEqual(oldProps.data, this.props.data)) {

            this.transformData();

        }

    }

    transformData = () => {

        const { data } = this.props;

        let transformedData = [];

        transformedData = _.chain(data)
        .filter(item => item?.country && mappedCountries[item.country])
        .groupBy('country')
        .map((value, key) => {
            return {
                "id": key.toUpperCase(),
                "name": mappedCountries[key],
                "value": value.length,
                "color": "#8aabb0"
              }

        })
        .value();

        this.setState({transformedData});

    }

    render() {

        const { transformedData } = this.state;
        const { legend } = this.props;

        return (
            <MapGraphView data={transformedData} legend={legend} />
        )
    }
}
export default MapGraphContainer;