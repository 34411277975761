import React from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';

import Yloader from '../ui/components/loader';
import YModal from '../ui/components/modal';
import DiscountStudioModal from '../ui/components/discountStudioModal';
import PreviewSequenceModal from '../ui/components/previewSequenceModal';

import Home from '../ui/routes/home';
import Login from '../ui/routes/auth/login';

import I18n from '../i18n';

const PrivateRoute = ({ ...props }) => localStorage.getItem('token') ? <Route { ...props } /> : <Redirect to="/" />;

class App extends React.Component {

    render() {

        return (
            <I18nextProvider i18n={ I18n }>
                <HashRouter>
                    <Switch>
                        <PrivateRoute path="/home" component={ Home } />
                        <Route path="/" exact component={ Login } />
                        <Route path="*" component={ Login } />
                    </Switch>
                </HashRouter>
                <Yloader />
                <YModal />
                <DiscountStudioModal />
                <PreviewSequenceModal />
            </I18nextProvider>
        );

    }

}

export default App;