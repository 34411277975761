import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { openLoader, closeLoader } from '../../../../../../redux-store/loader'
import { closeModal, openModal } from '../../../../../../redux-store/modal'
import SessionsModel from '../../../../../../data/models/sessions';
import { Header, Segment, Table } from 'semantic-ui-react';
import I18n from '../../../../../../i18n'
import moment from 'moment';
import { toHHMMSS } from '../../../../../../utils/formatTime';

class UserSessions extends Component {

    constructor(props) {
        super(props)

        this.state = {
            sessions: [],
            timeElapsedTotal: 0,
            alternativeSequencesNames: [],
            originalData: [],
            column: 'startDate',
            direction: 'descending'
        }
    }

    componentDidMount() {

        this.getUserSessions();

    }

    getUserSessions = async () => {

        let messageError = {
            headerTitle : "Error",
            title : I18n.t('messages.modal.commonError'),
            buttons : [
                
                {
                    text : I18n.t('actions.accept'),
                    callback : () => this.props.closeModal()
                
                }

            ]
        };

        try {

            this.props.openLoader();
            const response = await SessionsModel.getUserSessions(this.props.userid);
            const sequences = await SessionsModel.getSequences();

            if (response.data && sequences.data) {

                
                let timeElapsedTotal = 0;
                _.each(response.data, ({timeElapsed})=> {

                    timeElapsedTotal+=timeElapsed;

                });

                let alternativeSequencesNames = {};

                _.each(sequences.data, ({_id, title })=> {

                    alternativeSequencesNames[_id] = title.ES;

                });

                this.setState({ sessions: _.orderBy(response.data, ['startDate'], ['desc'] ), originalData : response.data, timeElapsedTotal: toHHMMSS(timeElapsedTotal), alternativeSequencesNames });

            }
            
        } catch (error) {
        
            this.props.closeLoader();
            this.props.openModal(messageError);

        } finally {

            this.props.closeLoader();

        }

    }

    handleSort = (clickedColumn) => () => {
        
        const { column, direction, originalData } = this.state;
    
        if (column !== clickedColumn) {
          this.setState({
            column: clickedColumn,
            sessions: _.orderBy(originalData, [clickedColumn], ['asc']),
            direction: 'ascending',
          })
    
          return
        }
    
        this.setState({
            sessions: _.orderBy(originalData, [clickedColumn], [direction === 'ascending' ? 'desc' : 'asc']),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        });

      }

    render() {

        const { sessions, timeElapsedTotal, alternativeSequencesNames, column, direction } = this.state;

        return sessions && (
            
            <React.Fragment>
                <Segment>
                    <Header as="h3">{I18n.t('user.info.sessionsList')}</Header>
                    <p>{I18n.t('user.info.totalTime', { totalTime: timeElapsedTotal })}</p>
                    <Table celled selectable sortable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell >{ I18n.t('user.info.sessionId') }</Table.HeaderCell>
                                <Table.HeaderCell >{ I18n.t('user.info.status') }</Table.HeaderCell>
                                <Table.HeaderCell onClick={this.handleSort('startDate')} sorted={column === 'startDate' ? direction : null}>{ I18n.t('user.info.date') }</Table.HeaderCell>
                                <Table.HeaderCell >{ I18n.t('user.info.startEndDate') }</Table.HeaderCell>
                                <Table.HeaderCell >{ I18n.t('user.info.usedTime') }</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                        { sessions.map( ({ _id, sessionId, startDate, endDate, timeElapsed, status }) => (
                            <Table.Row key={ _id } >
                                <Table.Cell>{ alternativeSequencesNames[sessionId] || I18n.t('user.info.customSequence', {sessionId})  }</Table.Cell>
                                <Table.Cell>{ status }</Table.Cell>
                                <Table.Cell>{ startDate && moment(startDate).format('DD/MM/YYYY HH:mm:ss') } </Table.Cell>
                                <Table.Cell>{ startDate && moment(startDate).format('HH:mm:ss') } - {endDate && moment(endDate).format('HH:mm:ss') } </Table.Cell>
                                <Table.Cell>{ toHHMMSS(timeElapsed) }</Table.Cell>
                            </Table.Row>
                        ))}
                        </Table.Body>
                    </Table>
                </Segment>
            </React.Fragment>

        )
    }
}

export default connect(null, { openLoader, closeLoader, closeModal, openModal })(UserSessions)