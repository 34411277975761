import React from 'react';
import _ from 'lodash';
import { Form, Input as SemanticInput, Label } from 'semantic-ui-react';
import { convertToNumeric } from '../../../../config/validations';

class CustomInput extends React.Component {

    onChange = (success, event) => {

        if (_.get(this.props, 'restrictions[0].canBeFloat', false) || _.get(this.props, 'restrictions[0].numeric', false)) {
            
            event.target.value = convertToNumeric(event.target.value, _.get(this.props, 'restrictions[0].canBeFloat', false));
            
        } 

        success(event);

    }

    render() {

        return (
            <Form.Field inline={ this.props.inline || false } className={ this.props.fieldClasses } error={ this.props.meta.touched && this.props.meta.invalid }>
                {this.props.label && <label style={{opacity: (this.props.disabled ?  '0.3': '1')}}>{ this.props.label }</label>}
                <SemanticInput
                    disabled={this.props.disabled || false}
                    { ...this.props.input }
                    placeholder={ this.props.placeholder }
                    onChange={ e => this.onChange(this.props.input.onChange, e) }
                    type={ this.props.type || 'text'} />
                { this.props.meta.touched && this.props.meta.invalid && <Label basic color={ this.props.colorLabel || 'red' } pointing>{ this.props.meta.error }</Label> }
            </Form.Field>
        );

    }

}

export default CustomInput;